import { MdPersonAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom-v5-compat";

import { useQsFilters } from "@megaron/dash-filters";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { Page, PageHeader } from "@megaron/dash-page";
import { SearchWithPaginationSticky } from "@megaron/dash-search";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useClientManager } from "@megaron/react-clients";
import { Serializers } from "@megaron/serializers";

import { OrderDraftsList } from "./OrderDraftsList";
import { OrderDraftsTable } from "./OrderDraftsTable";

const pageSize = 30;

const filterSerializer = Serializers.object({
  page: Serializers.integer,
  searchText: Serializers.string,
});

export const OrderDraftsPage = () => {
  const { isMobile } = useDeviceType();

  const navigate = useNavigate();

  const { filters, setFilter } = useQsFilters(filterSerializer, {
    page: 0,
    searchText: "",
  });

  const orderDraftsQuery = useClientManager("legacyMegarax")
    .getOrderDrafts()
    .useQuery({
      offset: filters.page * pageSize,
      limit: pageSize,
      searchText: filters.searchText,
    });

  return (
    <Page
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        maxWidth: "100%",
      }}
    >
      <PageHeader
        actions={
          <Button icon={<MdPersonAdd />} onClick={() => navigate("/crm/order-drafts/new")}>
            Dodaj
          </Button>
        }
      >
        Zamówienia robocze
      </PageHeader>

      <SearchWithPaginationSticky
        onSearchChange={setFilter("searchText")}
        searchValue={filters.searchText}
        page={filters.page}
        onPageChange={setFilter("page")}
        pageSize={pageSize}
        itemCount={orderDraftsQuery.data?.count}
      ></SearchWithPaginationSticky>

      <QuerySkeleton query={orderDraftsQuery}>
        {(orderDrafts) =>
          isMobile ? (
            <OrderDraftsList orders={orderDrafts.items} />
          ) : (
            <OrderDraftsTable isLoading={false} orders={orderDrafts.items} />
          )
        }
      </QuerySkeleton>
    </Page>
  );
};
