import { IdTokenPayload } from "@megarax/auth-contracts";
import { ScheduleVisitDto } from "@megarax/crm-contracts";
import { formatDistanceToNow } from "date-fns";
import { pl } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { MdAdd, MdOutlineHistory } from "react-icons/md";
import { useLocation, useParams as useParamsCompat } from "react-router-dom-v5-compat";

import { useDialogRoute } from "@megaron/dash-dialog";
import { Button } from "@megaron/dash-form";
import { EntriesList } from "@megaron/dash-history";
import { useDeviceType } from "@megaron/dash-mq";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { CustomerEventDoc, isDocOfType, UserDoc } from "@megaron/docs-contracts";
import { useProfiles } from "@megaron/megarax-webapps";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { NewVisitProposal } from "../customers/NewVisitProposal";
import { TabHeader } from "../customers/TabHeader";
import { EventTile } from "./EventTile";
import { HistorySectionHeader } from "./HistorySectionHeader";
import { InteractionPage } from "./InteractionPage";
import { InteractionTile } from "./InteractionTile";
import { CustomerInteractionDoc } from "@megaron/crm-contracts";

type Props = {
  upcomingVisits: ScheduleVisitDto[];
  user: IdTokenPayload | undefined;
};

export const InteractionHistory: React.FC<Props> = ({ upcomingVisits, user }) => {
  const { customerId }: { customerId?: Uuid } = useParamsCompat();

  if (!customerId) {
    return <div>Error: Customer ID not provided.</div>;
  }

  return <InteractionHistoryContent customerId={customerId} upcomingVisits={upcomingVisits} user={user} />;
};

const InteractionHistoryContent: React.FC<{
  customerId: Uuid;
  upcomingVisits: any[];
  user: any | undefined;
}> = ({ customerId, upcomingVisits, user }) => {
  const { isMobile } = useDeviceType();
  const customerHistory = useClientManager("docs").customerHistoryQuery().useQuery({ customerUuid: customerId });
  const { profiles } = useProfiles();

  const emailListSet = new Set(
    customerHistory.data?.items
      ?.filter(
        (interaction): interaction is CustomerInteractionDoc => "ownerEmail" in interaction && !!interaction.ownerEmail,
      )
      .map((interaction) => interaction.ownerEmail),
  );

  const emailList = Array.from(emailListSet);
  const profilesData = profiles(emailList);

  const interactionUuids = new Set(
    customerHistory.data?.items.filter(isDocOfType("customer-interaction")).map((i) => i.uuid) ?? [],
  );
  const filteredUpcomingVisits = upcomingVisits.filter((upcomingVisit) => !interactionUuids.has(upcomingVisit.uuid));

  const dialog = useDialogRoute("/add-interaction", ({ onClose }) => (
    <InteractionPage onClose={onClose} header={"Dodaj Interakcje"} queryKey={customerHistory.key} />
  ));

  return (
    <>
      {!isMobile && <TabHeader icon={<MdOutlineHistory size={18} />} text="Historia" />}
      <Button icon={<MdAdd />} variant="outline" onClick={dialog.open} css={{ alignSelf: "flex-end" }}>
        Dodaj
      </Button>
      {filteredUpcomingVisits && filteredUpcomingVisits.length > 0 && (
        <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem", width: "100%" }}>
          {filteredUpcomingVisits.map((visit) => (
            <NewVisitProposal visit={visit} key={`visit-proposal-${visit.uuid}`} user={user} />
          ))}
        </div>
      )}
      <div css={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        <QuerySkeleton query={customerHistory}>
          {(history) => (
            <InteractionList items={history.items} queryKey={customerHistory.key} profiles={profilesData} />
          )}
        </QuerySkeleton>
      </div>
      {dialog.element}
    </>
  );
};

const InteractionList: React.FC<{
  items: (CustomerInteractionDoc | CustomerEventDoc)[];
  queryKey: string | string[];
  profiles: UserDoc[] | undefined;
}> = ({ items, queryKey, profiles }) => {
  const [highlightedInteractionId, setHighlightedInteractionId] = useState<string | null>(null);

  const filteredItems = items.filter((item) => {
    if ("payload" in item && item.payload.type) {
      return !(item.payload.type === "userInvited");
    }
    return true;
  });

  const sortedGroupedInteractions = sortInteractionByDate(filteredItems || []);

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      const element = document.getElementById(location.state.scrollTo);
      if (element) {
        setHighlightedInteractionId(location.state.scrollTo);
        element.scrollIntoView({ behavior: "smooth" });
        return;
      }
      setHighlightedInteractionId(null);
    }
  }, [location]);

  return (
    <EntriesList>
      <>
        {Object.entries(sortedGroupedInteractions).map(([key, value]) => {
          const header = key === "najnowsze" ? undefined : key;
          return (
            <React.Fragment key={key}>
              {header && <HistorySectionHeader header={header} />}
              {value.map((item) =>
                "payload" in item && item.payload.type ? (
                  <EventTile event={item} key={item.docId} />
                ) : "message" in item ? (
                  <InteractionTile
                    isHighlighted={highlightedInteractionId === `interaction-${item.uuid}`}
                    key={item.uuid}
                    interaction={item}
                    profile={profiles?.find((profile) => profile.email === item.ownerEmail)}
                    queryKey={queryKey}
                  />
                ) : null,
              )}
            </React.Fragment>
          );
        })}
      </>
    </EntriesList>
  );
};

const sortInteractionByDate = (items: (CustomerInteractionDoc | CustomerEventDoc)[]) => {
  const sortedItems = items.sort((a, b) => {
    const dateA = "interactionDate" in a ? new Date(a.interactionDate) : new Date(a.payload.time);
    const dateB = "interactionDate" in b ? new Date(b.interactionDate) : new Date(b.payload.time);
    return dateB.getTime() - dateA.getTime();
  });

  return sortedItems.reduce((acc, item) => {
    const dateKey = getDistanceToNow(new Date("interactionDate" in item ? item.interactionDate : item.payload.time));
    if (!acc[dateKey]) acc[dateKey] = [];
    acc[dateKey].push(item);
    return acc;
  }, {} as Record<string, (CustomerInteractionDoc | CustomerEventDoc)[]>);
};

const getDistanceToNow = (date: Date) => {
  const isInFuture = date.getTime() > new Date().getTime();
  const daysDifference = getDaysDifference(date);

  if (isInFuture || daysDifference < 7) {
    return "najnowsze";
  }

  if (daysDifference <= 8) {
    return "tydzień";
  }

  if (daysDifference <= 30) {
    return "poniżej miesiąca";
  }

  const distanceToNow = formatDistanceToNow(date, { locale: pl });

  if (distanceToNow.startsWith("około")) {
    return distanceToNow.slice(6);
  }

  return distanceToNow;
};

const getDaysDifference = (date: Date) => {
  const today = new Date();
  const differenceTime = Math.abs(today.getTime() - date.getTime());
  return Math.ceil(differenceTime / (1000 * 60 * 60 * 24));
};
