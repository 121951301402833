import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const orderDraftLineActions = {
  removeOrderDraftLine: HttpAction({
    method: "delete",
    path: "/sales/orderDrafts/:uuid/lines/:lineUuid",
    paramsSerializer: Serializers.object({ uuid: uuidSerializer, lineUuid: uuidSerializer }),
    errorSerializer: Serializers.stringOneOf("OrderNotFound", "DraftNotOpen", "UserNotOrderOwner", "LineDoesNotExist"),
  }),
  updateOrderDraftLine: HttpAction({
    method: "put",
    path: "/sales/orderDrafts/:uuid/lines/:lineUuid",
    paramsSerializer: Serializers.object({ uuid: uuidSerializer, lineUuid: uuidSerializer }),
    bodySerializer: Serializers.object({
      promoPrice: Serializers.decimal.nullable(),
      quantity: Serializers.integer,
    }),
    errorSerializer: Serializers.stringOneOf(
      "OrderNotFound",
      "DraftNotOpen",
      "UserNotOrderOwner",
      "LineDoesNotExist",
      "InvalidLineQuantity",
    ),
  }),
  addOrderDraftLine: HttpAction({
    method: "post",
    path: "/sales/orderDrafts/:uuid/lines",
    paramsSerializer: Serializers.object({ uuid: uuidSerializer }),
    bodySerializer: Serializers.object({
      lineUuid: uuidSerializer,
      item: Serializers.object({ gtin: Serializers.string }),
      promoPrice: Serializers.decimal.nullable(),
      quantity: Serializers.integer,
    }),
    errorSerializer: Serializers.stringOneOf(
      "OrderNotFound",
      "DraftNotOpen",
      "UserNotOrderOwner",
      "LineAlreadyExists",
      "InvalidLineQuantity",
    ),
  }),
};
