import { useTheme } from "@emotion/react";
import Decimal from "decimal.js";
import tinycolor from "tinycolor2";

import { GroupSummary } from "@megaron/crm-contracts";

type Props = {
  groupSummary: GroupSummary[];
};

export const GroupPotentialRealization: React.FC<Props> = ({ groupSummary }) => {
  const theme = useTheme();

  if (!groupSummary) {
    return null;
  }

  const filteredGroupSummary = groupSummary.filter((g) => g.profit.gt(0) || g.absolutePotential.gt(0));

  const groupWithRealizationCount = groupSummary?.filter((g) => g.profit.gt(0)).length ?? 0;
  const groupWithPotentialCount = groupSummary?.filter((g) => g.absolutePotential.gt(0)).length ?? 0;

  return (
    <div css={{ display: "flex", alignItems: "center", gap: "0.375rem" }}>
      <span css={{ fontSize: "0.875rem" }}>
        {groupWithRealizationCount}/{groupWithPotentialCount}
      </span>
      <div css={{ display: "flex", alignItems: "center", gap: "0.25rem" }}>
        {filteredGroupSummary.map((group) => {
          const realization = group.absolutePotential.gt(0)
            ? group.potentialRealization
            : group.profit
            ? new Decimal(0.5)
            : new Decimal(0);

          const isPotentialDefined = group.absolutePotential.gt(0);

          return (
            <div css={{ borderRadius: "3px", overflow: "hidden" }}>
              <div
                css={{
                  width: "28px",
                  height: "0.75rem",
                  borderRadius: "3px",
                  border: `1px solid ${tinycolor(theme.colors.primary).setAlpha(0.3)}`,
                  background: theme.colors.primaryLight,
                  position: "relative",
                  opacity: isPotentialDefined ? 1 : 0.5,
                }}
              >
                <div
                  css={{
                    height: "calc(100% + 2px)",
                    position: "absolute",
                    top: "-1px",
                    left: "-1px",
                    width: realization.eq(1)
                      ? `calc(${realization.mul(28).toFixed()}px + 2px)`
                      : realization.eq(0)
                      ? 0
                      : `calc(${realization.mul(28).toFixed()}px + 1px)`,
                    background: theme.colors.primary,
                    borderColor: theme.colors.primary,
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
