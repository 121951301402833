import { useState } from "react";
import { MdAddChart, MdOutlineSaveAlt } from "react-icons/md";

import { Dialog } from "@megaron/dash-dialog";
import { Button, Checkbox, TextField } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { Spinner } from "@megaron/dash-spinner";
import { useToast } from "@megaron/dash-toast";

import { SaveViewOptions } from "./CustomersViewPage";

type Props = {
  onClose: () => void;
  title?: string;
  description?: string;
  isUserTheOwner?: boolean;
  onSave: (options: SaveViewOptions) => void;
  isNewView: boolean;
  isPrivateView: boolean;
  isSaving?: boolean;
};

export const SaveViewDialog: React.FC<Props> = ({
  onClose,
  title: initialTitle,
  description: initialDescription,
  isUserTheOwner,
  onSave,
  isSaving,
  isNewView,
  isPrivateView,
}) => {
  const { isMobile } = useDeviceType();

  const toast = useToast();

  const [title, setTitle] = useState<string>(initialTitle ?? "");
  const [description, setDescription] = useState<string>(initialDescription ?? "");
  const [isPrivate, setIsPrivate] = useState<boolean>(isPrivateView);

  const [titleError, setTitleError] = useState<string | null>(null);
  const [descriptionError, setDescriptionError] = useState<string | null>(null);

  const handleSave = () => {
    if (!isUserTheOwner) {
      return;
    }

    if (!title) {
      setTitleError("Nazwa widoku jest wymagana");
      return;
    }

    if (!description) {
      setDescriptionError("Opis widoku jest wymagany");
      return;
    }

    onSave({
      title,
      description,
      isPrivate,
      onError: (e) => {
        if (e === "NameNotUnique") {
          setTitleError("Nazwa widoku musi być unikalna");
          return;
        }

        toast.error("Wystąpił błąd podczas zapisywania widoku");
      },
    });
  };

  const handleSaveAs = () => {
    if (!title) {
      setTitleError("Nazwa widoku jest wymagana");
      return;
    }

    setTitleError(null);

    if (!description) {
      setDescriptionError("Opis widoku jest wymagany");
      return;
    }

    setDescriptionError(null);

    onSave({
      title,
      description,
      createNew: true,
      isPrivate,
      onError: (e) => {
        if (e === "NameNotUnique") {
          setTitleError("Nazwa widoku musi być unikalna");
          return;
        }

        toast.error("Wystąpił błąd podczas zapisywania widoku");
      },
    });
  };

  return (
    <Dialog
      placement={isMobile ? "top" : "center"}
      onClose={onClose}
      css={{ width: isMobile ? "100%" : "400px" }}
      header={"Uzupełnij dane widoku"}
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
        <TextField label="Nazwa widoku" value={title} onChange={setTitle} error={titleError} />
        <TextField label="Opis widoku" value={description} onChange={setDescription} error={descriptionError} />
        <Checkbox label="Widok prywatny" value={isPrivate} onChange={setIsPrivate} />
        {isNewView ? (
          <Button
            css={{ marginTop: "0.25rem", marginLeft: "auto" }}
            onClick={handleSaveAs}
            icon={isSaving ? <Spinner size="16px" color="white" /> : <MdAddChart />}
            isDisabled={isSaving}
          >
            Utwórz widok
          </Button>
        ) : (
          <div css={{ display: "flex", gap: "0.5rem", justifyContent: "space-between" }}>
            <Button
              css={{ marginTop: "0.25rem" }}
              variant="outline"
              onClick={handleSaveAs}
              icon={isSaving ? <Spinner size="16px" color="white" /> : <MdAddChart />}
              isDisabled={isSaving}
            >
              Utwórz kopię
            </Button>
            <Button
              css={{ marginLeft: "auto", marginTop: "0.25rem" }}
              onClick={handleSave}
              icon={isSaving ? <Spinner size="16px" /> : <MdOutlineSaveAlt />}
              isDisabled={isSaving || !isUserTheOwner}
            >
              Zapisz
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};
