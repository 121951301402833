import { useClientManager } from "@megaron/react-clients";
import { Serializers, SerializerValue } from "@megaron/serializers";

import { presetFiltersSerializer } from "./useCustomersFilters";

export const mapPresetSerializer = Serializers.object({
  filters: presetFiltersSerializer,
});

export type MapPreset = SerializerValue<typeof mapPresetSerializer>;

export const newMapPreset = (): MapPreset => ({
  filters: {
    sort: undefined,
    isPro: false,
    isAffiliate: false,
    isActive: undefined,
    pos: undefined,
    tags: undefined,
    isMyRegion: true,
    regions: undefined,
    isUnassigned: undefined,
    scannedProducts: undefined,
    status: ["unverified", "verified", "vip"],
    categories: undefined,
    parents: undefined,
    relation: undefined,
  },
});

export const useCustomersQuery = (preset: MapPreset) => {
  return useClientManager("docs")
    .searchCustomers()
    .useQuery({
      ...preset.filters,
    });
};
