import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const trainingStatuses = ["planned", "completed", "canceled", "gpw", "completed", "inCompany"] as const;
export type TrainingStatusType = (typeof trainingStatuses)[number];

export const trainingSerializer = Serializers.object({
  trainingId: uuidSerializer,
  ownerId: Serializers.string,
  name: Serializers.string,
  date: Serializers.datetime,
  startTime: Serializers.datetime,
  endTime: Serializers.datetime,
  region: Serializers.string,
  trainers: Serializers.string.array(),
  participants: Serializers.string.array(),
  status: Serializers.stringOneOf(...trainingStatuses),
  location: Serializers.string,
  note: Serializers.string,
  posList: Serializers.string.array(),
});

export const saveTrainingSerializer = Serializers.object({
  trainingId: uuidSerializer,
  name: Serializers.string,
  date: Serializers.datetime,
  startTime: Serializers.datetime,
  endTime: Serializers.datetime,
  region: Serializers.string,
  trainers: Serializers.string.array(),
  participants: Serializers.string.array(),
  status: Serializers.stringOneOf(...trainingStatuses),
  location: Serializers.string,
  note: Serializers.string,
  posList: Serializers.string.array(),
});

export type Training = SerializerValue<typeof trainingSerializer>;

export const trainings = {
  trainingsQuery: HttpAction({
    path: "/trainings",
    method: "get",
    qsSerializer: Serializers.object({ startDate: Serializers.dateOnly, endDate: Serializers.dateOnly }),
    requiresAuth: true as const,
    valueSerializer: trainingSerializer.array(),
  }),

  trainingQuery: HttpAction({
    path: "/training/:uuid",
    method: "get",
    paramsSerializer: Serializers.object({ uuid: uuidSerializer }),
    requiresAuth: true as const,
    valueSerializer: trainingSerializer,
  }),

  saveTraining: HttpAction({
    path: "/training",
    method: "put",
    requiresAuth: true as const,
    bodySerializer: saveTrainingSerializer,
    valueSerializer: trainingSerializer,
  }),

  removeTraining: HttpAction({
    path: "/training/:uuid",
    paramsSerializer: Serializers.object({ uuid: uuidSerializer }),
    method: "delete",
    requiresAuth: true as const,
  }),
};
