import React from "react";
import { MdMap } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom-v5-compat";

import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { Page } from "@megaron/dash-page";
import { SearchWithPaginationSticky } from "@megaron/dash-search";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useClientManager } from "@megaron/react-clients";
import { useRegion } from "@megaron/region-context";
import { Serializers } from "@megaron/serializers";

import { ColumnsFilter } from "./ColumnsFilter";
import { CustomerList } from "./CustomerList";
import { ListPreset } from "./customerListPreset";
import { CustomersFilters } from "./CustomersFilters";
import { CustomerTable } from "./CustomerTable";
import { DownloadContactsCsvButton } from "./DownloadContactsCsvButton";
import { DownloadCustomersCsvButton } from "./DownloadCustomersCsvButton";
import { useCustomersFilters } from "./useCustomersFilters";
import { useCustomerVisibleTableColumns } from "./useCustomerVisibleTableColumns";

type Props = {
  preset: ListPreset;
  updatePreset: (preset: ListPreset) => void;
};

export const CustomersHome: React.FC<Props> = ({ preset, updatePreset }) => {
  const { isDesktop, isMobile } = useDeviceType();

  const location = useLocation();
  const navigate = useNavigate();

  const { viewId } = useParams<{ viewId: string }>();

  const { visibleTableColumns, onVisibleTableColumnsChange } = useCustomerVisibleTableColumns(preset, updatePreset);

  const { region } = useRegion();

  const pageSize = 30;
  const { filters, setFilter, setFilters } = useCustomersFilters(preset.filters, (newFilters) =>
    updatePreset({ ...preset, filters: newFilters }),
  );

  const searchQuery = useClientManager("docs")
    .searchCustomers()
    .useQuery(
      {
        offset: filters.page * pageSize,
        limit: pageSize,
        searchText: filters.searchText,
        sort: filters.sort,
        isPro: filters.isPro,
        isAffiliate: filters.isAffiliate,
        tags: filters.tags,
        scannedProducts: filters.scannedProducts,
        regions: filters.isMyRegion ? (region ? [region] : undefined) : filters.regions,
        isUnassigned: filters.isUnassigned,
        categories: filters.categories,
        status: filters.status,
        relation: filters.relation,
        isActive: filters.isActive,
      },
      { refetchInterval: 300000, refetchOnWindowFocus: true, refetchOnReconnect: true },
    );

  return (
    <Page
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        maxWidth: "100%",
        marginTop: "1rem",
      }}
    >
      <SearchWithPaginationSticky
        onSearchChange={setFilter("searchText")}
        searchValue={filters.searchText}
        page={filters.page}
        onPageChange={setFilter("page")}
        pageSize={pageSize}
        itemCount={searchQuery.data?.count}
        key={`search-pagination-${viewId}`}
        top={78}
      >
        <div css={{ display: "flex", alignItems: "stretch" }}>
          <ColumnsFilter
            visibleTableColumns={visibleTableColumns}
            onVisibleTableColumnsChange={onVisibleTableColumnsChange}
          />
          <Button
            color="normal"
            icon={<MdMap />}
            css={{ borderRadius: "0" }}
            onClick={() => navigate("/crm/customers/new-map" + location.search)}
          />
        </div>
      </SearchWithPaginationSticky>

      <CustomersFilters
        filters={filters}
        handlers={{
          onSortChange: setFilter("sort"),
          onProChange: setFilter("isPro"),
          onAffiliateChange: setFilter("isAffiliate"),
          onUnassignedChange: setFilter("isUnassigned"),
          onScannedProductsChange: setFilter("scannedProducts"),
          onRegionsChange: (v) => {
            setFilters({ ...filters, regions: v, isMyRegion: false });
          },
          onTagsChange: setFilter("tags"),
          onStatusChange: setFilter("status"),
          onCategoriesChange: setFilter("categories"),
          onRelationChange: setFilter("relation"),
          onIsMyRegionChange: setFilter("isMyRegion"),
          onActiveChange: setFilter("isActive"),
        }}
      />

      <div css={{ display: isMobile ? "none" : "flex", alignItems: "center", gap: "0.5rem", marginBottom: "1rem" }}>
        <DownloadCustomersCsvButton filters={filters} />
        <DownloadContactsCsvButton />
      </div>

      <QuerySkeleton query={searchQuery}>
        {(result) =>
          isDesktop ? (
            <CustomerTable
              customers={result.items}
              isLoading={searchQuery.isFetching}
              onSortChange={setFilter("sort")}
              activeSortValue={filters.sort}
              visibleTableColumns={visibleTableColumns}
              queryKey={searchQuery.key}
              onFiltersChange={setFilters}
              filters={filters}
            />
          ) : (
            <CustomerList customers={result.items} queryKey={searchQuery.key} />
          )
        }
      </QuerySkeleton>
    </Page>
  );
};

export type SortFilterValue =
  | Serializers.SortFilter<"totalMassKg" | "lastInteractionTime" | "lastPurchaseTime">
  | undefined;
