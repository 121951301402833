import { useTheme } from "@emotion/react";
import { MdPerson } from "react-icons/md";

type Props = {
  imageUrl: string | undefined | null;
  firstName: string;
  lastName: string;
  className?: string;
};

export const ParticipantProfileAvatar: React.FC<Props> = ({ imageUrl, firstName, lastName, className }) => {
  const theme = useTheme();

  const avatarStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  } as const;

  return (
    <div
      className={className}
      css={{
        borderRadius: "100%",
        width: "1.5rem",
        height: "1.5rem",
        border: `1px solid ${theme.colors.border}`,
        position: "relative",
        backgroundColor: "white",
        ":hover div": {
          display: "block",
        },
      }}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt={`${firstName} ${lastName}`}
          css={{
            width: "1.5rem",
            height: "1.5rem",
            borderRadius: "100%",
            ...avatarStyles,
          }}
        />
      ) : (
        <MdPerson css={{ color: theme.colors.secondaryText, ...avatarStyles }} size={20} />
      )}
      <div
        css={{
          display: "none",
          whiteSpace: "nowrap",
          padding: "0.25rem 0.5rem",
          borderRadius: theme.extraSmallBorderRadius,
          backgroundColor: theme.colors.primaryLight,
          color: theme.colors.primary,
          position: "absolute",
          top: "50%",
          left: "0",
          transform: "translate(-105%, -50%)",
          fontSize: "0.75rem",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        {firstName} {lastName}
      </div>
    </div>
  );
};
