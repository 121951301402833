import { useTheme } from "@emotion/react";
import { useClickOutside } from "@mantine/hooks";
import { useState } from "react";
import { MdCalendarToday } from "react-icons/md";

import { useDeviceType } from "@megaron/dash-mq";

import { FiltersProps } from "./CustomerAnalyticsFilters";
import { TimeRangeSelect } from "./TimeRangeSelect";

type Props = FiltersProps;

export const TimeRangeButton: React.FC<Props> = (props) => {
  const theme = useTheme();

  const { isMobile } = useDeviceType();

  const [isTimeRangeSelectOpen, setIsTimeRangeSelectOpen] = useState(false);

  const ref = useClickOutside(() => setIsTimeRangeSelectOpen(false));

  const getLabel = () => {
    if (props.filters.selectedTimeName) {
      return props.filters.selectedTimeName;
    }

    if (props.filters.unitCount && props.filters.timeUnit) {
      return `${props.filters.unitCount} ${parseTimeUnitToString(props.filters.timeUnit)}`;
    }

    if (props.filters.timeStart && props.filters.timeEnd) {
      return `${props.filters.timeStart.toLocaleDateString()} - ${props.filters.timeEnd.toLocaleDateString()}`;
    }

    return "Brak";
  };

  return (
    <div
      css={{
        position: "relative",
        display: "flex",
      }}
    >
      <button
        css={{
          border: "none",
          background: "none",
          color: theme.colors.primary,
          fontSize: "0.875rem",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          gap: "0.75rem",
          padding: "0.25rem 0.75rem",
          alignSelf: "stretch",
          textWrap: "nowrap",
          flexShrink: 0,
          borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
          fontWeight: 700,
        }}
        onClick={() => setIsTimeRangeSelectOpen(true)}
      >
        <MdCalendarToday size={16} />
        {!isMobile && getLabel()}
      </button>
      {isTimeRangeSelectOpen && (
        <div
          ref={ref}
          css={{
            padding: "0.75rem",
            borderRadius: theme.smallBorderRadius,
            width: isMobile ? "calc(100vw - 2rem)" : "414px",
            maxWidth: isMobile ? "380px" : "414px",
            position: "absolute",
            bottom: "-0.5rem",
            right: "-1px",
            background: "white",
            zIndex: 20,
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
            transform: "translate(0, 100%)",
            height: isMobile ? "min(calc(100dvh - 180px), 460px)" : "auto",
            overflow: "auto",
          }}
        >
          <TimeRangeSelect
            onDateChange={props.handlers.onDateChange}
            activeOptionName={props.filters.selectedTimeName ?? null}
            startDate={props.filters.timeStart ?? null}
            endDate={props.filters.timeEnd ?? null}
            onClose={() => setIsTimeRangeSelectOpen(false)}
            timeUnit={props.filters.timeUnit ?? null}
            unitCount={props.filters.unitCount ?? null}
          />
        </div>
      )}
    </div>
  );
};

export const parseTimeUnitToString = (timeUnit: string) => {
  switch (timeUnit) {
    case "hours":
      return "h";
    case "days":
      return "d";
    case "months":
      return "m";
    case "years":
      return "r";
    default:
      return timeUnit;
  }
};
