import { DateString } from "@megarax/common";
import { ScheduleVisitDto } from "@megarax/crm-contracts";
import { isDefined } from "@megarax/utils";
import { addMinutes } from "date-fns";
import { useQueries } from "react-query";

import { CustomerInteractionDoc } from "@megaron/crm-contracts";
import { useClientManager, useServiceClient } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

export const useWeekVisits = (dates: DateString[], userId: number, userEmail: string | undefined) => {
  const interactoinsQuery = useClientManager("docs")
    .customerInteractionQuery()
    .useQuery({ dates, ownerEmail: userEmail ?? "" }, { enabled: !!userEmail });

  const legacyClient = useServiceClient("legacyMegarax");

  const scheduleQueries = useQueries(
    dates.map((date) => ({
      queryKey: ["userVisits", date, userId],
      queryFn: () => legacyClient.visitScheduleQuery({ date, userId }),
    })),
  );

  const scheduleVisits = scheduleQueries.flatMap((q) => q?.data?.value?.visits ?? []);

  return {
    visits: mergeVisits(scheduleVisits, interactoinsQuery.data?.items ?? []),
    isLoading: interactoinsQuery.isLoading || scheduleQueries.some((q) => q.isFetching),
    reload: () => {
      interactoinsQuery.refetch();
    },
  };
};

export type CalendarVisit = {
  uuid: Uuid;
  customerUuid: Uuid;
  isCancelled: boolean;
  startTime: Date | null;
  endTime: Date | null;
  scheduledStartTime: Date | null;
  scheduledDurationMinutes: number | null;
};
const emptyCalendarVisit = (uuid: Uuid, customerUuid: Uuid): CalendarVisit => ({
  uuid,
  customerUuid,
  isCancelled: false,
  startTime: null,
  endTime: null,
  scheduledStartTime: null,
  scheduledDurationMinutes: null,
});

const mergeVisits = (schedule: ScheduleVisitDto[], interaction: CustomerInteractionDoc[]) => {
  const visitDicaionary: {
    [uuid: Uuid]: CalendarVisit | undefined;
  } = {};

  interaction.forEach((v) => {
    const existing = visitDicaionary[v.uuid] ?? emptyCalendarVisit(v.uuid, v.customerUuid);
    visitDicaionary[v.uuid] = {
      ...existing,
      customerUuid: v.customerUuid,
      startTime: v.interactionDate,
      endTime: addMinutes(v.interactionDate, v.durationInMinutes),
    };
  });

  schedule.forEach((v) => {
    const existing = visitDicaionary[v.uuid] ?? emptyCalendarVisit(v.uuid, v.customerUuid);
    visitDicaionary[v.uuid] = {
      ...existing,
      customerUuid: v.customerUuid,
      scheduledStartTime: v.startTime,
      scheduledDurationMinutes: v.durationMinutes,
    };
  });

  return Object.values(visitDicaionary).filter(isDefined);
};
