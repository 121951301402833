import { useTheme } from "@emotion/react";
import { formatCurrency } from "@megarax/react-utils";
import { MdOutlineSearch } from "react-icons/md";
import { Link } from "react-router-dom-v5-compat";
import tinycolor from "tinycolor2";

import { SectionHeader } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { Tile, TileStripe } from "@megaron/dash-tile";
import { useClientManager } from "@megaron/react-clients";

import { Filters } from "../allThreads";

type Props = {
  issuer: string;
  threadUuid: string;
  hideActions?: boolean;
  areLinksExternal?: boolean;
};

export const IssuerThreads: React.FC<Props> = ({ issuer, threadUuid, hideActions, areLinksExternal }) => {
  const theme = useTheme();

  const invoicesQuery = useClientManager("docs")
    .searchInvoiceThreads()
    .useQuery({
      offset: 0,
      limit: 10,
      issuer: issuer,
      sort: {
        field: "invoiceIssueDate",
        order: "DESC",
      },
    });

  const formatDate = (date: Date): string => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };

  const filters: Partial<Filters> = {
    page: 0,
    status: undefined,
    searchText: "",
    issuer,
    groupFilter: "all",
    sort: { field: "createdAt", order: "DESC" },
  };

  const encodedFilters = encodeURIComponent(JSON.stringify(filters));

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <SectionHeader
        isHr
        css={{ h2: { fontSize: "16px" } }}
        actions={
          hideActions ? undefined : (
            <Link
              to={`/faktury/watki?filters=${encodedFilters}`}
              css={{
                height: "16px",
                alignItems: "center",
                color: theme.colors.primary,
                padding: 0,
                ":hover": {
                  textDecoration: "none",
                },
              }}
            >
              <MdOutlineSearch />
            </Link>
          )
        }
      >
        Faktury wystawcy
      </SectionHeader>
      <QuerySkeleton query={invoicesQuery}>
        {(issuerThreads) => (
          <div
            css={{
              display: "flex",
              gap: "12px",
              flexDirection: "column",
            }}
          >
            {issuerThreads.items.map((issuerThread) => (
              <Link
                key={issuerThread.docId}
                to={`/faktury/watki/${issuerThread.threadUuid}`}
                target={areLinksExternal ? "_blank" : undefined}
                css={{
                  ":hover": {
                    textDecoration: "none",
                    h4: {
                      textDecoration: "underline",
                    },
                  },
                }}
              >
                <Tile
                  stripe={
                    <TileStripe
                      color={
                        issuerThread.status === "open"
                          ? theme.colors.primaryLight
                          : issuerThread.status === "approved"
                          ? theme.colors.success
                          : theme.colors.danger
                      }
                    ></TileStripe>
                  }
                  css={{
                    borderRadius: theme.smallBorderRadius,
                    border:
                      threadUuid === issuerThread.threadUuid
                        ? `1px solid ${
                            issuerThread.status === "open"
                              ? theme.colors.primary
                              : issuerThread.status === "approved"
                              ? theme.colors.success
                              : theme.colors.danger
                          }`
                        : `1px solid ${tinycolor(
                            issuerThread.status === "open"
                              ? theme.colors.primaryLight
                              : issuerThread.status === "approved"
                              ? theme.colors.success
                              : theme.colors.danger,
                          ).setAlpha(0.1)}`,
                  }}
                >
                  <div
                    css={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      width: "100%",
                    }}
                  >
                    <div
                      css={{
                        display: "flex",
                        fontSize: "14px",
                        gap: "6px",
                        color:
                          issuerThread.status === "open"
                            ? theme.colors.primary
                            : issuerThread.status === "approved"
                            ? theme.colors.success
                            : theme.colors.danger,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <span>{`${issuerThread.referenceNumber} ${issuerThread.title}`}</span>
                    </div>

                    <div css={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div css={{ display: "flex", fontSize: "12px", gap: "6px" }}>
                        <span>{formatDate(issuerThread.invoice.issueDate)}</span>
                        <span css={{ color: theme.colors.secondaryText }}>{issuerThread.invoice.invoiceNumber}</span>
                      </div>

                      <span css={{ fontSize: "14px" }}>
                        {formatCurrency(issuerThread.invoice.total.toNumber(), issuerThread.invoice.currency)}
                      </span>
                    </div>
                  </div>
                </Tile>
              </Link>
            ))}
          </div>
        )}
      </QuerySkeleton>
    </div>
  );
};
