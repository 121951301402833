import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { useTheme } from "@emotion/react";
import Decimal from "decimal.js";
import { useLayoutEffect, useRef, useState } from "react";

import {
  AggType,
  CustomerAnalyticsEntry,
  CustomerAnalyticsSort,
  CustomerAnalyticsSortField,
} from "@megaron/crm-contracts";
import { useDialogRoute } from "@megaron/dash-dialog";
import { useDeviceType } from "@megaron/dash-mq";
import { RowCellContent, TableBody, TableHead, TableHeadColumn } from "@megaron/dash-table";

import { AddColumnButton } from "./AddColumnButton";
import { AddColumnDialog } from "./AddColumnDialog";
import { analyticsColumns, ColumnCellContent, ColumnType } from "./analyticsColumns";
import { AnalyticsPreset, AnalyticsPresetColumn, AnalyticsPresetCustomColumn } from "./analyticsPreset";
import { ColumnSettingsDialog } from "./ColumnSettingsDialog";
import { DraggableTableHeadingWrapper } from "./DraggableTableHeadingWrapper";
import { SortableTableHeadingCell } from "./SortableTableHeadingCell";
import { TableHeadingCell } from "./TableHeadingCell";

type Props = {
  customersAnalytics: CustomerAnalyticsEntry[] | undefined;
  isLoading: boolean;
  preset: AnalyticsPreset;
  activeSortValue: CustomerAnalyticsSort | undefined;
  onSortChange: (value: CustomerAnalyticsSort | undefined) => void;
  onNextPageReach: () => void;
  onCreateAggClick: (aggType: AggType) => void;
  onAddColumn: (column: AnalyticsPresetColumn) => void;
  onEditColumn: (columnName: string, options: { newName: string }) => void;
  onDeleteColumn: (columnName: string) => void;
  onAddCustomColumn: (options: { customColumn: AnalyticsPresetCustomColumn; column: AnalyticsPresetColumn }) => void;
  onColumnSort: (columns: AnalyticsPresetColumn[]) => void;
};

export const CustomerAnalyticsTable: React.FC<Props> = ({
  customersAnalytics,
  isLoading,
  preset,
  activeSortValue,
  onSortChange,
  onNextPageReach,
  onCreateAggClick,
  onAddColumn,
  onEditColumn,
  onDeleteColumn,
  onAddCustomColumn,
  onColumnSort,
}) => {
  const theme = useTheme();

  const { isDesktop } = useDeviceType();

  const tableRef = useRef<HTMLDivElement>(null);

  const [selectedColumn, setSelectedColumn] = useState<string | null>(null);

  const [isDragging, setIsDragging] = useState(false);
  const [draggedElementId, setDraggedElementId] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    setIsDragging(false);
    setDraggedElementId(null);

    if (active.id !== over?.id) {
      const oldIndex = preset.columns.findIndex((col) => col.header === active.id);
      const newIndex = preset.columns.findIndex((col) => col.header === over?.id);

      onColumnSort(arrayMove(preset.columns, oldIndex, newIndex));
    }
  };

  useLayoutEffect(() => {
    const currentTableRef = tableRef.current;

    if (!currentTableRef) {
      return;
    }

    const callback = (e: Event) => {
      if (
        currentTableRef?.scrollHeight - 100 <
          (currentTableRef?.scrollTop ?? 0) + (currentTableRef?.clientHeight ?? 0) &&
        !isLoading
      ) {
        onNextPageReach();
      }
    };

    currentTableRef.addEventListener("scroll", callback);

    return () => {
      currentTableRef?.removeEventListener("scroll", callback);
    };
  }, [onNextPageReach, isLoading]);

  const addColumnDialog = useDialogRoute("/add-column", ({ onClose }) => (
    <AddColumnDialog
      onClose={onClose}
      preset={preset}
      onCreateAggClick={onCreateAggClick}
      onAddColumn={onAddColumn}
      onAddCustomColumn={onAddCustomColumn}
    />
  ));

  const columnSettingDialog = useDialogRoute("/column-setting", ({ onClose }) => {
    if (!selectedColumn) {
      return null;
    }

    return (
      <ColumnSettingsDialog
        onClose={onClose}
        columns={preset.columns}
        name={selectedColumn}
        onDelete={() => onDeleteColumn(selectedColumn)}
        onSave={onEditColumn}
      />
    );
  });

  const handleColumnRightClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setSelectedColumn(e.currentTarget.textContent);
    columnSettingDialog.open();
  };

  const tableColumns: TableHeadColumn<CustomerAnalyticsSortField>[] = [
    {
      label: "Klient",
      cellCss: {
        padding: "0.5rem 0.75rem",
        position: "sticky",
        zIndex: 12,
        top: 0,
        background: theme.colors.background,
        left: 0,
      },
    },
    ...preset.columns.map((column, index) => {
      return column.sortField
        ? ({
            isSortable: false,
            label: column.header,
            cellCss: {
              padding: index === preset.columns.length - 1 ? 0 : "0 8px 0 0",
              position: "sticky",
              zIndex: draggedElementId === column.header ? 12 : 11,
              top: 0,
              background: isDragging ? "transparent" : theme.colors.background,
            },
            element: (
              <DraggableTableHeadingWrapper columnHeader={column.header}>
                <SortableTableHeadingCell
                  label={column.header}
                  sortFieldName={column.sortField}
                  activeSortValue={
                    activeSortValue?.fieldName && activeSortValue?.order
                      ? {
                          fieldName: activeSortValue.fieldName,
                          order: activeSortValue.order,
                          agg: activeSortValue.agg,
                          customColumn: activeSortValue.customColumn,
                        }
                      : undefined
                  }
                  onSortChange={onSortChange}
                  aggName={column.aggName}
                  customColumn={column.customColumnUuid}
                  onRightClick={handleColumnRightClick}
                />
              </DraggableTableHeadingWrapper>
            ),
          } as const)
        : ({
            isSortable: false,
            label: column.header,
            cellCss: {
              padding: index === preset.columns.length - 1 ? 0 : "0 8px 0 0",
              position: "sticky",
              zIndex: draggedElementId === column.header ? 12 : 11,
              top: 0,
              background: isDragging ? "transparent" : theme.colors.background,
            },
            element: (
              <DraggableTableHeadingWrapper
                columnHeader={column.header}
                onContextMenu={(e) => handleColumnRightClick(e)}
              >
                <TableHeadingCell label={column.header} />
              </DraggableTableHeadingWrapper>
            ),
          } as const);
    }),
    {
      label: "Dodaj kolumnę",
      element: <AddColumnButton onClick={addColumnDialog.open} />,
      cellCss: {
        padding: "0 0.5rem",
        position: "sticky",
        zIndex: 11,
        top: 0,
        width: "100%",
        background: theme.colors.background,
      },
    },
  ];

  const visibleTableColumnsIndexes = tableColumns
    .map((column, index) => (!column.label === undefined || !column.isHidden ? index : null))
    .filter((index) => index !== null) as number[];

  const getTableRowCellsContent = (
    analyticsEntry: CustomerAnalyticsEntry,
    highestProfits: Record<string, Decimal>,
  ): RowCellContent[] => {
    return [
      {
        isLink: true,
        element: (
          <span css={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
            {analyticsEntry.categories.includes("loyaltyUser")
              ? analyticsEntry.firstName
                ? `${analyticsEntry.firstName} ${analyticsEntry.lastName}`
                : analyticsEntry.phoneNumber
              : analyticsEntry.name}
          </span>
        ),
        cellCss: { overflow: "hidden", width: "10rem" },
      },
      ...preset.columns.map((column) => {
        const columnType = analyticsColumns.find((cellConfig) => cellConfig.columnType === column.type)?.columnType;

        if (!columnType) {
          return { isLink: true, element: null };
        }

        return {
          isLink: false,
          element: (
            <ColumnCellContent
              componentType={columnType as ColumnType}
              analyticsEntry={analyticsEntry}
              aggName={column.aggName}
              column={column}
              highestProfits={highestProfits}
            />
          ),
        };
      }),
      { isLink: false, element: null },
    ];
  };

  const highestProfits = getHighestProfitForAggs(customersAnalytics ?? []);

  return (
    <div
      css={{
        overflow: "auto",
        margin: isDesktop ? "0 -2rem 0 0" : "0 -1rem 2.75rem",
      }}
      ref={tableRef}
    >
      <DndContext
        onDragEnd={handleDragEnd}
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={(e) => {
          setIsDragging(true);
          setDraggedElementId(e.active.id as string);
        }}
        modifiers={[restrictToWindowEdges]}
      >
        <table css={{ width: "100%", borderSpacing: 0, paddingRight: isDesktop ? "2rem" : "0" }}>
          <SortableContext items={preset.columns.map((col) => col.header)} strategy={horizontalListSortingStrategy}>
            <TableHead columns={tableColumns} />
          </SortableContext>
          <TableBody
            visibleColumnIndexes={visibleTableColumnsIndexes}
            isLoading={isLoading}
            rows={
              customersAnalytics?.map((analytics) => ({
                uuid: analytics.uuid,
                linkTo: `/crm/customers/id/${analytics.uuid}`,
                cellsContent: getTableRowCellsContent(analytics, highestProfits),
                css: {
                  "td:first-of-type": {
                    position: "sticky",
                    left: 0,
                    zIndex: 10,
                    borderRightStyle: "solid",
                  },
                  "&:first-child td:last-child": {
                    borderTopRightRadius: 0,
                  },
                  "&:first-child td:first-child": {
                    borderTopLeftRadius: isDesktop ? theme.smallBorderRadius : 0,
                  },
                  "&:last-child td:first-child": {
                    borderBottomLeftRadius: isDesktop ? theme.smallBorderRadius : 0,
                  },
                  "&:last-child td:last-child": {
                    borderBottomRightRadius: isDesktop ? theme.smallBorderRadius : 0,
                  },
                },
              })) ?? []
            }
          />
        </table>
      </DndContext>
      {addColumnDialog.element}
      {columnSettingDialog.element}
    </div>
  );
};

const getHighestProfitForAggs = (customerAnalytics: CustomerAnalyticsEntry[]) => {
  return customerAnalytics.reduce((acc, curr) => {
    const profits = Object.entries(curr.aggs).reduce((acc, [key, value]) => {
      if (value && value.type === "sales") {
        acc[key] = value.profit;
      }
      return acc;
    }, {} as Record<string, Decimal>);

    Object.entries(profits).forEach(([key, profit]) => {
      if (!acc[key] || profit.gt(acc[key])) {
        acc[key] = profit;
      }
    });
    return acc;
  }, {} as Record<string, Decimal>);
};
