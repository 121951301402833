import { DateString, dateStringSerializer } from "@megaron/date-string";
import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";

export type CodeActivityDayDto = {
  date: DateString;
  scansWithKey: number;
  scansWithoutKey: number;
  redemptions: number;
};

export const codeActivityDayDtoSerializer = Serializers.object<CodeActivityDayDto>({
  date: dateStringSerializer,
  scansWithKey: Serializers.integer,
  scansWithoutKey: Serializers.integer,
  redemptions: Serializers.integer,
});

export const dailyCodeActivity = HttpAction({
  path: "/codeActivity/daily",
  qsSerializer: Serializers.object<{
    groupNames?: string[];
    since?: DateString;
    until?: DateString;
  }>({
    groupNames: Serializers.jsonString(Serializers.array(Serializers.string)).optional(),
    since: dateStringSerializer.optional(),
    until: dateStringSerializer.optional(),
  }),
  method: "get",
  requiresAuth: true as const,
  valueSerializer: Serializers.array(codeActivityDayDtoSerializer),
});
