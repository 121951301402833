import { dateStringSerializer, toDateString } from "@megaron/date-string";
import { HttpAction } from "@megaron/http-service";
import { baseDocSerializer, ResourceId } from "@megaron/resource";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { newUuid, uuidSerializer } from "@megaron/uuid";

import { interactionMentionSerializer, interactionTypes } from "./history";

export const customerInteractionDocSerializer = Serializers.object({
  ...baseDocSerializer("customer-interaction").fields,

  customerUuid: uuidSerializer,
  uuid: uuidSerializer,
  ownerEmail: Serializers.string,
  type: Serializers.stringOneOf(...interactionTypes).nullable(),
  message: Serializers.string,
  interactionDate: Serializers.datetime,
  dateString: dateStringSerializer,
  samples: Serializers.array(Serializers.string),
  mentions: interactionMentionSerializer.array(),
  durationInMinutes: Serializers.integer,
  googleCalendarEventId: Serializers.string.nullable(),
});

export type CustomerInteractionDoc = SerializerValue<typeof customerInteractionDocSerializer>;

export const interactionDocActions = {
  customerInteractionDocBulkQuery: HttpAction({
    path: "/interactions/bulk",
    method: "post",
    bodySerializer: Serializers.object({
      customerUuids: uuidSerializer.array().asJsonString(),
    }).partial(),
    requiresAuth: true as const,
    valueSerializer: customerInteractionDocSerializer.array(),
  }),
};

export const newCustomerInteractionDoc = (props: Partial<CustomerInteractionDoc> = {}): CustomerInteractionDoc => {
  const uuid = props.uuid ?? newUuid();
  const date = props.interactionDate ?? new Date();

  return {
    authorizedAttributes: ["userType:megarax"],
    boostedFor: [],
    dateString: toDateString(date),
    docId: ResourceId("customer-interaction", uuid),
    docType: "customer-interaction",
    isArchived: false,
    name: "test",
    uuid: uuid,
    customerUuid: props.customerUuid ?? newUuid(),
    ownerEmail: "test@example.com",
    type: "other",
    message: "message @test test",
    interactionDate: date,
    samples: ["A-2"],
    mentions: [{ user: "test@test.com", tagLength: 9, tagPosition: 8 }],
    durationInMinutes: 15,
    googleCalendarEventId: null,
    ...props,
  };
};
