import { useTheme } from "@emotion/react";
import { MdOutlineViewColumn } from "react-icons/md";

import { useDeviceType } from "@megaron/dash-mq";
import { SelectDialog } from "@megaron/dash-select";

import { tableHeadingsList } from "./CustomerTable";

type Props = {
  visibleTableColumns: string[];
  onVisibleTableColumnsChange: (columns: string[]) => void;
};

export const ColumnsFilter: React.FC<Props> = ({ visibleTableColumns, onVisibleTableColumnsChange }) => {
  const theme = useTheme();

  const { isMobile } = useDeviceType();

  if (isMobile) {
    return null;
  }

  return (
    <SelectDialog
      label={"Kolumny"}
      initiallySelectedValues={visibleTableColumns}
      options={tableHeadingsList.map(({ heading }) => ({ label: heading, value: heading }))}
      onSelectedChange={(options) => onVisibleTableColumnsChange(options.map((option) => option.value))}
      variant="multi-select"
      renderTrigger={(_, __, ___, onOpen) => (
        <button
          onClick={onOpen}
          css={{
            background: "none",
            border: "none",
            display: "flex",
            gap: "0.375rem",
            alignItems: "center",
            fontSize: "0.875rem",
            color: theme.colors.primary,
            padding: "0.375rem 0.625rem",
            cursor: "pointer",
          }}
        >
          <MdOutlineViewColumn size={20} />
          Kolumny
        </button>
      )}
    />
  );
};
