import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { newUuid, uuidSerializer } from "@megaron/uuid";

import { customerCategories } from "./categories";

export const interactionTypes = ["call", "visit", "training", "hotline", "other"] as const;
export type InteractionType = (typeof interactionTypes)[number];

export const eventTypes = [
  "userInvited",
  "userJoined",
  "proStatusEarned",
  "rewardPurchased",
  "userRegistered",
  "accountDeleted",
  "affiliateCodeRedeemed",
  "affiliateCodeClaimed",
  "productCodeRedeemed",
] as const;
export type EventType = (typeof eventTypes)[number];

export type CustomerEvent = ProStatusEarnedEvent | UserJoinedEvent | UserInvitedEvent | RewardPurchasedEvent;

export const interactionMentionSerializer = Serializers.object({
  user: Serializers.string,
  tagPosition: Serializers.integer,
  tagLength: Serializers.integer,
});

export type InteractionMention = SerializerValue<typeof interactionMentionSerializer>;

export const interactionSerializer = Serializers.object({
  uuid: uuidSerializer,
  ownerEmail: Serializers.string,
  type: Serializers.stringOneOf(...interactionTypes).nullable(),
  message: Serializers.string,
  interactionDate: Serializers.datetime,
  samples: Serializers.array(Serializers.string),
  mentions: interactionMentionSerializer.array(),
  durationInMinutes: Serializers.integer,
  customerCategories: Serializers.stringOneOf(...customerCategories).array(),
  googleCalendarEventId: Serializers.string.nullable(),
});

export type Interaction = SerializerValue<typeof interactionSerializer>;

export const saveInteractionBodySerializer = Serializers.object({
  interactionId: uuidSerializer.optional(),
  customerId: uuidSerializer,
  type: Serializers.stringOneOf(...interactionTypes),
  message: Serializers.string,
  interactionDate: Serializers.datetime,
  samples: Serializers.array(Serializers.string),
  mentions: interactionMentionSerializer.array(),
  durationInMinutes: Serializers.integer,
  googleCalendarEventId: Serializers.string.nullable(),
});

export type SaveInteractionBody = SerializerValue<typeof saveInteractionBodySerializer>;

export const newInteraction = (props: Partial<SaveInteractionBody> = {}): SaveInteractionBody => ({
  interactionId: newUuid(),
  customerId: newUuid(),
  durationInMinutes: 15,
  googleCalendarEventId: null,
  interactionDate: new Date(),
  mentions: [],
  message: "interaction message",
  type: "other",
  samples: [],
  ...props,
});

export const userJoinedEventSerializer = Serializers.object({
  uuid: uuidSerializer,
  customerId: uuidSerializer,
  type: Serializers.stringOneOf("userJoined"),
  eventBody: Serializers.object({}),
  createdAt: Serializers.datetime,
});

export type UserJoinedEvent = SerializerValue<typeof userJoinedEventSerializer>;

export const proStatusEarnedEventSerializer = Serializers.object({
  uuid: uuidSerializer,
  customerId: uuidSerializer,
  eventBody: Serializers.object({}),
  type: Serializers.stringOneOf("proStatusEarned"),
  createdAt: Serializers.datetime,
});

export type ProStatusEarnedEvent = SerializerValue<typeof proStatusEarnedEventSerializer>;

export const userInvitedEventSerializer = Serializers.object({
  uuid: uuidSerializer,
  customerId: uuidSerializer,
  type: Serializers.stringOneOf("userInvited"),
  eventBody: Serializers.object({
    invitedUserUuid: Serializers.string,
    invitedCustomerUuid: Serializers.string,
    invitedCustomerPhoneNumber: Serializers.string,
    invitedCustomerFirstName: Serializers.string,
    invitedCustomerLastName: Serializers.string,
  }),
  createdAt: Serializers.datetime,
});

export type UserInvitedEvent = SerializerValue<typeof userInvitedEventSerializer>;

export const rewardPurchasedEventSerializer = Serializers.object({
  uuid: uuidSerializer,
  customerId: uuidSerializer,
  type: Serializers.stringOneOf("rewardPurchased"),
  eventBody: Serializers.object({
    rewardId: Serializers.string,
  }),
  createdAt: Serializers.datetime,
});

export type RewardPurchasedEvent = SerializerValue<typeof rewardPurchasedEventSerializer>;

export const customerEventSerializer = Serializers.first(
  userJoinedEventSerializer,
  proStatusEarnedEventSerializer,
  userInvitedEventSerializer,
  rewardPurchasedEventSerializer,
);

export const history = {
  getInteraction: HttpAction({
    path: "/interaction/:interactionId",
    method: "get",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      interactionId: uuidSerializer,
    }),
    errorSerializer: Serializers.stringOneOf("InteractionNotFound", "Unauthorized"),
    valueSerializer: interactionSerializer,
  }),

  saveInteraction: HttpAction({
    path: "/interaction",
    method: "put",
    requiresAuth: true as const,
    bodySerializer: saveInteractionBodySerializer,
    errorSerializer: Serializers.stringOneOf("CustomerNotFound", "Unauthorized", "InteractionDateCannotBeInTheFuture"),
  }),
};
