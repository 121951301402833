import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";

export const affiliateCodeStatuses = ["unclaimed", "claimed"] as const;
export type AffiliateCodeStatus = (typeof affiliateCodeStatuses)[number];
export const affiliateCodeStatusSerializer = Serializers.stringOneOf(...affiliateCodeStatuses);

export type AffiliateCodeDto = {
  id: string;
  status: AffiliateCodeStatus;
};

export const affiliateCodeDtoSerializer = Serializers.object<AffiliateCodeDto>({
  id: Serializers.string,
  status: affiliateCodeStatusSerializer,
});

export const affiliateCodeActions = {
  generateAffiliateCode: HttpAction({
    path: "/affiliateCodes/generate",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({
      pointValue: Serializers.decimal,
      claimBonusValue: Serializers.decimal,
    }),
    valueSerializer: Serializers.object({
      codeId: Serializers.string,
      registrationPassword: Serializers.string,
    }),
  }),
  generateAffiliateCodes: HttpAction({
    path: "/affiliateCodes/generateBatch",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({
      quantity: Serializers.integer,
      pointValue: Serializers.decimal,
      claimBonusValue: Serializers.decimal,
    }),
    valueSerializer: Serializers.array(
      Serializers.object({
        codeId: Serializers.string,
        registrationPassword: Serializers.string,
      }),
    ),
  }),
  claimAffiliateCode: HttpAction({
    path: "/affiliateCodes/id/:codeId/claim",
    method: "post",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      codeId: Serializers.string,
    }),
    bodySerializer: Serializers.object({
      registrationPassword: Serializers.string,
    }),
    errorSerializer: Serializers.stringOneOf("CodeNotFound", "IncorrectPassword", "CodeAlreadyClaimed"),
  }),
  redeemAffiliateCode: HttpAction({
    path: "/affiliateCodes/id/:codeId/redeem",
    method: "post",
    paramsSerializer: Serializers.object({
      codeId: Serializers.string,
    }),
    requiresAuth: true as const,
    errorSerializer: Serializers.stringOneOf(
      "CodeNotFound",
      "CodeNotClaimed",
      "CodeAlreadyRedeemed",
      "AlreadyJoinedProgram",
    ),
  }),
  checkAffiliateCode: HttpAction({
    path: "/affiliateCodes/id/:codeId",
    method: "get",
    paramsSerializer: Serializers.object({
      codeId: Serializers.string,
    }),
    valueSerializer: affiliateCodeDtoSerializer,
    errorSerializer: Serializers.stringOneOf("CodeNotFound"),
  }),
  generateSalespersonAffiliateCode: HttpAction({
    path: "/salespersonAffiliateCodes/generate",
    bodySerializer: Serializers.object({
      region: Serializers.string,
    }),
    requiresAuth: true as const,
    errorSerializer: Serializers.stringOneOf("AlreadyHasCode", "EmailMissing"),
    method: "post",
    valueSerializer: Serializers.object({ codeId: Serializers.string }),
  }),
  myAffiliateCodesQuery: HttpAction({
    path: "/myAffiliateCodes",
    method: "get",
    requiresAuth: true as const,
    valueSerializer: Serializers.array(affiliateCodeDtoSerializer),
  }),
};
