import { useTheme } from "@emotion/react";
import { differenceInCalendarDays, formatDistanceToNowStrict } from "date-fns";
import { pl } from "date-fns/locale";
import React from "react";
import { IconType } from "react-icons/lib";
import { MdOutlineComment, MdQuestionMark, MdShoppingCart, MdStar } from "react-icons/md";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom-v5-compat";
import tinycolor from "tinycolor2";

import { CustomerProduct } from "@megaron/crm-contracts";
import { Avatar } from "@megaron/dash-avatar";
import { useDialogRoute } from "@megaron/dash-dialog";
import { Chip } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { Tile, TileStripe } from "@megaron/dash-tile";
import { CustomerDoc } from "@megaron/docs-contracts";
import { useProfiles } from "@megaron/megarax-webapps";

import { parseCustomerAddressToString } from "./CustomerTable";
import { EditRegionDialog } from "./EditRegionDialog";

type Props = {
  customer: CustomerDoc;
  mapView?: boolean;
  queryKey: string | string[];
};

export const isInactive = (time: Date) => differenceInCalendarDays(new Date(), time) > 90;

export const useActivityColor = (time: Date | null, isArchived: boolean) => {
  const theme = useTheme();
  if (isArchived) return theme.colors.border;
  if (!time) return theme.colors.primary;
  return isInactive(time) ? theme.colors.danger : theme.colors.success;
};

export const CustomerTile: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const color = useActivityColor(props.customer.lastPurchaseTime, props.customer.isArchived);

  const queryClient = useQueryClient();

  const dialog = useDialogRoute(`/region/${props.customer.uuid}`, ({ onClose }) => (
    <EditRegionDialog
      onClose={onClose}
      customerUuid={props.customer.uuid}
      region={props.customer.region}
      onSuccess={(region, ownerEmail) => {
        const data = queryClient.getQueryData<{ items: CustomerDoc[]; count: number }>(props.queryKey);
        if (!data) return;

        const successData = data?.items.map((item) => {
          if (item.uuid === props.customer.uuid) {
            return { ...item, region, regionOwnerEmail: ownerEmail };
          }

          return item;
        });

        queryClient.setQueryData(props.queryKey, { items: successData, count: data.count });

        onClose();
      }}
    />
  ));

  return (
    <>
      <Link
        to={`/crm/customers/id/${props.customer.uuid}`}
        css={{
          ":hover": {
            textDecoration: "none",
            h4: {
              textDecoration: "underline",
            },
          },
        }}
      >
        <Tile
          stripe={
            <TileStripe color={color}>
              {props.customer.status === "unverified" && <MdQuestionMark size={12} color="white" />}
              {props.customer.status === "vip" && <MdStar size={12} color="white" />}
            </TileStripe>
          }
          css={{
            borderRadius: props.mapView ? theme.smallBorderRadius : theme.smallBorderRadius,
            border: props.mapView ? "none" : `1px solid ${tinycolor(color).setAlpha(0.1)}`,
          }}
        >
          <div
            css={{
              display: "flex",
              gap: "0.25rem",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <CustomerCardHeader customer={props.customer} color={color} avatarAction={dialog.open} />
            <LastInteractionRow customer={props.customer} />
            <CustomerProductsRow customer={props.customer} color={color} />
          </div>
        </Tile>
      </Link>
      {dialog.element}
    </>
  );
};

const CustomerCardHeader = (props: { customer: CustomerDoc; color: string; avatarAction: () => void }) => {
  const { profile } = useProfiles();

  const theme = useTheme();

  const ownerProfile = profile(props.customer.regionOwnerEmail);

  const handleAvatarClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    props.avatarAction();
  };

  return (
    <div
      css={{
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "start",
        gap: "0.5rem",
      }}
    >
      <div css={{ display: "flex", width: "100%" }}>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            padding: "2px 0",
            gap: "4px",
            flexGrow: 0,
            overflow: "hidden",
          }}
        >
          <div css={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: "0.5rem" }}>
            <h4 css={{ margin: 0, color: props.color, lineHeight: 1.25 }}>{headerText(props.customer)}</h4>
            <div css={{ display: "flex", gap: "4px", marginRight: "8px" }}>
              {props.customer.isPro && (
                <Chip
                  variant="outline"
                  color={props.color}
                  css={{ textTransform: "uppercase", padding: "0 8px", fontWeight: "600", fontSize: "10px" }}
                >
                  Pro
                </Chip>
              )}
              {props.customer.isAffiliate && (
                <Chip
                  variant="outline"
                  color={props.color}
                  css={{ textTransform: "uppercase", padding: "0 8px", fontWeight: "600", fontSize: "10px" }}
                >
                  Partner
                </Chip>
              )}
              {props.customer.categories.includes("payer") && (
                <Chip
                  variant="outline"
                  color={props.color}
                  css={{ textTransform: "uppercase", padding: "0 8px", fontWeight: "600", fontSize: "10px" }}
                >
                  Płatnik
                </Chip>
              )}
            </div>
          </div>
          {!props.customer.categories.includes("loyaltyUser") && (
            <span
              css={{
                fontSize: "0.875rem",
                color: theme.colors.secondaryText,
                marginBottom: "0.25rem",
                lineHeight: 1,
              }}
            >
              {parseCustomerAddressToString(props.customer.address)}
            </span>
          )}
          {props.customer.tags.length > 0 && (
            <div
              css={{
                display: "flex",
                flexWrap: "wrap",
                gap: "0.4rem",
                alignItems: "flex-start",
                fontSize: "0.75rem",
                opacity: "0.6",
                lineHeight: "0.4rem",
                marginTop: "0.125rem",
                color: theme.colors.primary,
              }}
            >
              {props.customer.tags.map((tag) => (
                <span key={tag}>#{tag}</span>
              ))}
            </div>
          )}
        </div>
      </div>
      <div onClick={handleAvatarClick}>
        <Avatar src={ownerProfile ? ownerProfile?.profilePictureUrl : null} size="27px" />
      </div>
    </div>
  );
};

export const LastInteractionRow = (props: { customer: CustomerDoc }) => {
  const theme = useTheme();
  const { profile } = useProfiles();

  const { isDesktop } = useDeviceType();

  if (!props.customer.lastInteraction) return null;

  const interactionAuthorProfile = profile(props.customer.lastInteraction.ownerEmail);

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "0.3rem",
        marginBottom: "0.2rem",
        width: "100%",
      }}
    >
      <Avatar
        src={!props.customer.isArchived ? interactionAuthorProfile?.profilePictureUrl : undefined}
        size="0.9rem"
        css={{ flexShrink: 0 }}
      />
      <span
        css={{
          fontSize: isDesktop ? "0.875rem" : "0.75rem",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: theme.colors.primary,
        }}
      >
        {props.customer.lastInteraction?.message || "Wizyta"}
      </span>
      <LastActivityText
        icon={MdOutlineComment}
        time={props.customer.lastInteraction!.createdAt}
        css={{ marginLeft: "auto" }}
      />
    </div>
  );
};

export const CustomerProductsRow = (props: {
  customer: CustomerDoc;
  color: string;
  hideLastActivityText?: boolean;
}) => {
  const theme = useTheme();

  if (!props.customer.lastPurchaseTime || (props.customer.products.length === 0 && props.hideLastActivityText))
    return null;

  const sortedProducts = props.customer.products.slice().sort((a, b) => b.massKg.toNumber() - a.massKg.toNumber());
  const topTwoProducts = sortedProducts.slice(0, 2);
  const remainingProductCount = sortedProducts.length > 2 ? sortedProducts.length - 2 : 0;

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "1rem",
        whiteSpace: "nowrap",
      }}
    >
      {sortedProducts.length > 0 ? (
        <div css={{ display: "flex", flexDirection: "row", gap: "0.25rem" }}>
          <Chip variant="primary" color={props.color} css={{ fontWeight: "bold", fontSize: "0.75rem", flexShrink: 0 }}>
            {props.customer.totalMassKg.toFixed(0)} kg
          </Chip>
          {topTwoProducts.map((product) => (
            <ProductChip product={product} key={product.product} />
          ))}
          {remainingProductCount > 0 && (
            <Chip variant="secondary" color={props.color} css={{ fontWeight: 700, fontSize: "0.75rem", flexShrink: 0 }}>
              +{remainingProductCount}
            </Chip>
          )}
        </div>
      ) : (
        <span css={{ fontSize: "0.75rem", color: theme.colors.primary }}>Ostatni zakup</span>
      )}
      {!props.hideLastActivityText && <LastActivityText icon={MdShoppingCart} time={props.customer.lastPurchaseTime} />}
    </div>
  );
};

const ProductChip = ({ product }: { product: CustomerProduct }) => {
  const fontWeight = product.lastPurchaseTime ? "bold" : "normal";

  const color = useActivityColor(product.lastPurchaseTime, false);

  return (
    <Chip variant="secondary" color={color} css={{ fontWeight, fontSize: "0.75rem", flexShrink: 0 }}>
      {product.product}
    </Chip>
  );
};

export const LastActivityText = (props: { time: Date; icon: IconType; className?: string }) => {
  const { isDesktop } = useDeviceType();

  const text = formatDistanceToNowStrict(props.time, { locale: pl, addSuffix: true });

  return (
    <div
      className={props.className}
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: isDesktop ? "0.875rem" : "0.75rem",
        gap: "0.2rem",
        opacity: "0.6",
        flexShrink: 0,
        whiteSpace: "nowrap",
      }}
    >
      <props.icon /> {text}
    </div>
  );
};

export const headerText = (customer: CustomerDoc) => {
  if (customer.name) return customer.name;

  if (!customer.firstName && !customer.lastName) return customer.phoneNumber;

  return `${customer.firstName} ${customer.lastName}`;
};
