import { endOfDay, subDays } from "date-fns";
import _ from "lodash";
import { useInfiniteQuery } from "react-query";

import {
  aggTypes,
  customerAnalyticsFiltersSerializer,
  customerAnalyticsSortFields,
  customerAnalyticsSortSerializer,
  interactionsAggOptionsSerializer,
  salesAggOptionsSerializer,
  salesHistoryColumnOptionsSerializer,
  salesPerInteractionOptionsSerializer,
} from "@megaron/crm-contracts";
import { useServiceClient } from "@megaron/react-clients";
import { useRegion } from "@megaron/region-context";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { analyticsColumnsTypes } from "./analyticsColumns";
import { timeUnits } from "./TimeRangeSelect";

export const analyticsPresetColumnSerializer = Serializers.object({
  header: Serializers.string,
  aggName: Serializers.string.nullable(),
  aggType: Serializers.stringOneOf(...aggTypes).nullable(),
  type: Serializers.stringOneOf(...analyticsColumnsTypes),
  sortField: Serializers.stringOneOf(...customerAnalyticsSortFields).nullable(),
  customColumnUuid: uuidSerializer.optional(),
});

export type AnalyticsPresetColumn = SerializerValue<typeof analyticsPresetColumnSerializer>;

export const analyticsPresetCustomColumnSerializer = Serializers.first(
  Serializers.object({
    ...salesHistoryColumnOptionsSerializer.fields,
    uuid: uuidSerializer,
  }),
  Serializers.object({
    ...salesPerInteractionOptionsSerializer.fields,
    uuid: uuidSerializer,
  }),
);

export type AnalyticsPresetCustomColumn = SerializerValue<typeof analyticsPresetCustomColumnSerializer>;

const aggOptionsSerializer = Serializers.first(
  Serializers.object({
    aggName: Serializers.string,
    name: Serializers.string,
    timeUnit: Serializers.stringOneOf(...timeUnits).optional(),
    unitCount: Serializers.integer.optional(),
    selectedTimeName: Serializers.string.optional(),
    ...salesAggOptionsSerializer.fields,
    isProductGroupsDefault: Serializers.boolean.optional(),
  }),
  Serializers.object({
    aggName: Serializers.string,
    name: Serializers.string,
    timeUnit: Serializers.stringOneOf(...timeUnits).optional(),
    unitCount: Serializers.integer.optional(),
    selectedTimeName: Serializers.string.optional(),
    ...interactionsAggOptionsSerializer.fields,
  }),
);

export type AggOptions = SerializerValue<typeof aggOptionsSerializer>;

const analyticsPresetFiltersSerializer = Serializers.object({
  ...customerAnalyticsFiltersSerializer.fields,
  sort: customerAnalyticsSortSerializer.optional(),
  timeUnit: Serializers.stringOneOf(...timeUnits).optional(),
  unitCount: Serializers.integer.optional(),
  selectedTimeName: Serializers.string.optional(),
  timeStart: Serializers.datetime.optional(),
  timeEnd: Serializers.datetime.optional(),
  productGroups: Serializers.string.array().optional(),
  isMyRegion: Serializers.boolean.optional(),
}).partial();

export type AnalyticsPresetFilters = SerializerValue<typeof analyticsPresetFiltersSerializer>;

export const analyticsPresetSerializer = Serializers.object({
  filters: analyticsPresetFiltersSerializer.array(),
  sortBy: customerAnalyticsSortSerializer,
  columns: analyticsPresetColumnSerializer.array(),
  customColumns: analyticsPresetCustomColumnSerializer.array().optional(),
  aggsOptions: aggOptionsSerializer.array(),
});

export type AnalyticsPreset = SerializerValue<typeof analyticsPresetSerializer>;

export const newAnalyticsPreset = (): AnalyticsPreset => {
  const today = endOfDay(new Date());

  return {
    sortBy: { agg: null, fieldName: "name", order: "ASC", customColumn: null },
    filters: [
      {
        aggName: undefined,
        sort: { agg: null, fieldName: "name", order: "ASC", customColumn: null },
        isMyRegion: true,
        region: undefined,
        status: ["unverified", "verified", "vip"],
        category: undefined,
        customerId: undefined,
        timeStart: subDays(today, 90),
        timeEnd: today,
        timeUnit: "days",
        unitCount: 90,
        selectedTimeName: "Ostatnie 90 dni",
        productGroups: undefined,
      },
    ],
    columns: [],
    customColumns: [],
    aggsOptions: [],
  };
};

export const usePresetAnalyticsQuery = (
  preset: AnalyticsPreset,
  options: { enabled?: boolean } = { enabled: true },
) => {
  const { region } = useRegion();

  const aggs = _(preset.aggsOptions)
    .keyBy((g) => g.aggName)
    .value();

  const crm = useServiceClient("crm");

  const queryKey = [
    "crm",
    "customerAnalyticsInfiniteQuery",
    JSON.stringify({
      aggs,
      sortBy: preset.sortBy,
      filters: preset.filters,
      customColumns: preset.customColumns,
    }),
  ];

  const customerAnalyticsInfiniteQuery = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam = 0 }) => {
      const filters = preset.filters.map((filters) => ({
        ...filters,
        offset: pageParam * 30,
        limit: 30,
        region: filters.isMyRegion ? (region ? [region] : undefined) : filters.region,
      }));

      const customColumns = _(preset.customColumns)
        .keyBy((c) => c.uuid)
        .value();

      return crm.customerAnalyticsQuery({
        aggs,
        sortBy: preset.sortBy,
        filters,
        customColumns,
      });
    },
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.value && lastPage.value.length < 30) {
        return undefined;
      }

      return allPages.filter((page) => page.isOk).length;
    },
    enabled: options.enabled,
  });

  return { customerAnalyticsInfiniteQuery };
};
