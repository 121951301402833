import { useTheme } from "@emotion/react";
import { useState } from "react";

import { Checkbox } from "@megaron/dash-form";
import { SelectDialog } from "@megaron/dash-select";
import { useClientManager } from "@megaron/react-clients";

type Props = {
  productGroups: string[];
  onProductGroupsChange: (productGroups: string[], options?: { isDefault?: boolean }) => void;
  isDefault: boolean;
};

export const ProductGroupsField: React.FC<Props> = ({ productGroups, onProductGroupsChange, isDefault }) => {
  const theme = useTheme();

  const productGroupsQuery = useClientManager("docs").searchProductGroups().useQuery({});

  const [isDefaultSettings, setIsDefaultSettings] = useState(isDefault);

  return (
    <div
      css={{
        position: "relative",
        display: "flex",
      }}
    >
      <div css={{ display: "flex", flexDirection: "column", width: "100%", gap: "0.5rem" }}>
        <label
          css={{
            color: theme.colors.primary,
            fontSize: "0.875rem",
            fontFamily: theme.displayFontFamily,
          }}
        >
          Grupy produktowe
        </label>
        <Checkbox
          label="Domyślne"
          value={isDefaultSettings}
          onChange={() => {
            const newIsDefault = !isDefaultSettings;
            setIsDefaultSettings(newIsDefault);
            onProductGroupsChange([], { isDefault: newIsDefault });
          }}
        />
        <div css={{ opacity: isDefault ? 0.5 : 1 }}>
          <SelectDialog
            variant="multi-select"
            options={productGroupsQuery.data?.map((group) => ({ label: group, value: group })) ?? []}
            initiallySelectedValues={productGroups}
            onSelectedChange={(options) => onProductGroupsChange(options?.map((option) => option.value))}
            isDisabled={isDefaultSettings}
          />
        </div>
      </div>
    </div>
  );
};
