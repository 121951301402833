import { Theme, useTheme } from "@emotion/react";
import { Profile } from "@megarax/auth-contracts";
import { formatCurrency } from "@megarax/react-utils";
import { differenceInDays, endOfDay } from "date-fns";
import { RowCellContent } from "libs/dash/table/src/TableRow";
import { BsPersonCheckFill } from "react-icons/bs";
import { MdAttachMoney } from "react-icons/md";

import { TableBody } from "@megaron/dash-table";
import { useToast } from "@megaron/dash-toast";
import { InvoiceThreadDoc, ThreadStatus } from "@megaron/invoices-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { DecreeNumberInput } from "./DecreeNumberInput";
import { LastComment } from "./LastComment";
import { ParticipantProfileList } from "./ParticipantProfileList";
import { PrintButton } from "./PrintButton";
import { RemainingDaysTile } from "./RemainingDaysTile";
import { SignatureTile } from "./SignatureTile";
import { ThreadStatusTile } from "./ThreadStatusTile";

type Props = {
  threads: InvoiceThreadDoc[];
  onRefetch: () => void;
  userEmail: string;
  userRoles: string[] | undefined;
  profiles: Profile[] | undefined;
  isLoading: boolean;
  visibleTableColumnsIndexes: number[];
};

export const AllThreadsTableBody: React.FC<Props> = ({
  threads,
  userEmail,
  userRoles,
  profiles,
  isLoading,
  visibleTableColumnsIndexes,
  onRefetch,
}) => {
  const theme = useTheme();

  const toast = useToast();

  const decreeNumberMutation = useClientManager("invoices").setDecreeNumber().useMutation();

  const handleSetDecreeNumber = ({ threadUuid, decreeNumber }: { threadUuid: Uuid; decreeNumber: string | null }) =>
    decreeNumberMutation.mutate(
      { threadUuid, decreeNumber },
      {
        onSuccess: () => {
          toast.info("Pomyślnie zaktualizowano dane.");
          onRefetch();
        },
        onError: (error) => {
          toast.error("Nie udało się zaktualizować danych.");
        },
      },
    );

  const getRowLink = (uuid: string) => {
    return `/faktury/watki/${uuid}`;
  };

  const getTableRowCellsContent = (thread: InvoiceThreadDoc): RowCellContent[] => {
    const remainingDays = differenceInDays(endOfDay(thread.deadline), endOfDay(new Date()));

    const threadParticipants = thread.participants;

    const participantProfiles = threadParticipants
      .map((participant) => ({
        ...profiles?.find((profile) => profile.email === participant.user),
        isRead: participant.isRead,
      }))
      .filter(Boolean)
      .sort((a, _) => (a.isRead ? 1 : -1)) as (Profile & { isRead: boolean })[];

    const userData = threadParticipants?.find((profile) => profile.user === userEmail);

    const allowAccountingActions =
      (userRoles?.includes("invoices.coordinator") || userRoles?.includes("invoices.admin")) &&
      thread.status === "approved";

    const threadTextColor = getThreadTextColor(theme, thread.status, remainingDays);
    getThreadTextColor(theme, thread.status, remainingDays);

    return [
      {
        cellCss: { padding: "0.5rem 0.25rem" },
        element: <ThreadStatusTile remainingDays={remainingDays} thread={thread} userData={userData} />,
      },
      {
        isLink: true,
        cellCss: {
          color: threadTextColor,
          minHeight: "3rem",
          fontWeight: !userData || userData?.isRead || thread.status !== "open" ? 400 : 700,
          whiteSpace: "nowrap",
        },
        element: (
          <span
            css={{
              width: "6rem",
            }}
          >
            {thread.referenceNumber}
          </span>
        ),
      },
      {
        isLink: true,
        cellCss: {
          color: threadTextColor,
          fontWeight: !userData || userData?.isRead || thread.status !== "open" ? 400 : 700,
          overflow: "hidden",
        },
        element: (
          <span css={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "10rem" }}>
            {thread.title}
          </span>
        ),
      },
      {
        isLink: true,
        cellCss: { overflow: "hidden" },
        element: (
          <span css={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "12rem" }}>
            {thread.invoice.issuer}
          </span>
        ),
      },
      {
        isLink: true,
        cellCss: { overflow: "hidden" },
        element: (
          <span css={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "8rem" }}>
            {thread.invoice.invoiceNumber}
          </span>
        ),
      },
      {
        isLink: true,
        cellCss: { justifyContent: "flex-end" },
        element: formatCurrency(thread.invoice.total.toNumber(), thread.invoice.currency),
      },
      {
        isLink: true,
        cellCss: { color: theme.colors.secondaryText },
        element: thread.createdAt.toLocaleDateString(),
      },
      {
        isLink: true,
        cellCss: { color: theme.colors.secondaryText },
        element: thread.invoice.issueDate.toLocaleDateString(),
      },
      {
        isLink: true,
        element: thread.status === "open" && (
          <RemainingDaysTile deadline={thread.deadline} remainingDays={remainingDays} />
        ),
      },
      {
        isLink: true,
        cellCss: { color: theme.colors.secondaryText },
        element: thread.invoice.dueDate?.toLocaleDateString() ?? "-",
      },
      {
        isLink: true,
        cellCss: { color: theme.colors.secondaryText },
        element: thread.approvedAt?.toLocaleDateString() ?? "-",
      },
      {
        isLink: true,
        cellCss: { color: theme.colors.secondaryText },
        element: thread.invoice.receivedDate?.toLocaleDateString() ?? "-",
      },
      {
        isLink: true,
        element: (
          <div css={{ display: "flex", alignItems: "center", gap: "0.25rem" }}>
            <SignatureTile
              Icon={BsPersonCheckFill}
              isFullySigned={thread.signedValidityRelative.toNumber() === 1}
              remainingDays={remainingDays}
              isThreadOpen={thread.status === "open"}
            />
            <SignatureTile
              Icon={MdAttachMoney}
              isFullySigned={thread.signedBudgetRelative.toNumber() === 1}
              remainingDays={remainingDays}
              isThreadOpen={thread.status === "open"}
            />
          </div>
        ),
      },
      {
        isLink: true,
        cellCss: { color: theme.colors.secondaryText },
        element: <ParticipantProfileList participantProfiles={participantProfiles || []} />,
      },
      {
        isLink: true,
        cellCss: { overflow: "hidden", color: theme.colors.secondaryText },
        element: (
          <LastComment
            message={thread.lastComment?.message}
            postedBy={profiles?.find((profile) => profile.email === thread.lastComment?.postedBy)}
          />
        ),
      },
      {
        cellCss: { minWidth: "8rem" },
        element: (
          <DecreeNumberInput
            decreeNumber={thread.decreeNumber}
            onSubmit={(decreeNumber: string | null) =>
              handleSetDecreeNumber({ decreeNumber, threadUuid: Uuid(thread.threadUuid) })
            }
            isDisabled={!allowAccountingActions}
          />
        ),
      },
      {
        element: thread.status === "approved" && <PrintButton threadUuid={thread.threadUuid} />,
      },
    ];
  };

  return (
    <TableBody
      visibleColumnIndexes={visibleTableColumnsIndexes}
      isLoading={isLoading}
      rows={threads.map((thread) => ({
        uuid: thread.threadUuid,
        linkTo: getRowLink(thread.threadUuid),
        cellsContent: getTableRowCellsContent(thread),
        css: {
          "&:hover": {
            "td:nth-child(2), td:nth-child(3)": {
              textDecoration: "underline",
            },
          },
        },
      }))}
    />
  );
};

export const warningColor = "#9B5400";

export const getThreadTextColor = (theme: Theme, status: ThreadStatus, remainingDays: number) => {
  if (status === "approved") {
    return theme.colors.success;
  }

  if (status === "closed") {
    return "black";
  }

  if (remainingDays <= 0) {
    return theme.colors.danger;
  }

  if (remainingDays <= 3) {
    return warningColor;
  }

  return theme.colors.primary;
};
