import { MdAdd } from "react-icons/md";
import { useQueryClient } from "react-query";

import { Dialog, useDialogRoute } from "@megaron/dash-dialog";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { Page, SectionHeader } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useProfiles } from "@megaron/megarax-webapps";
import { useClientManager } from "@megaron/react-clients";
import { Email } from "@megaron/serializers";

import { AddRegion } from "./AddRegion";
import { MacroregionTile } from "./MacroregionTile";
import { RegionTile } from "./RegionTile";
import { SaveMacroregion } from "./SaveMacroregion";

export const RegionsHome = () => {
  const { isMobile } = useDeviceType();
  const { profiles } = useProfiles();
  const queryClient = useQueryClient();

  const regionsQuery = useClientManager("crm").regionsQuery().useQuery({});
  const macroregionsQuery = useClientManager("crm").macroregionsQuery().useQuery({});

  const regionEmailsListSet = new Set(
    regionsQuery.data?.items.map((region) => region.ownerEmail).filter((email) => email !== null),
  ) as Set<Email>;

  const macroregionEmailsListSet = new Set(
    macroregionsQuery.data?.items.flatMap((m) => m.emails).filter((email) => email !== null),
  ) as Set<Email>;

  const regionEmails = Array.from(regionEmailsListSet) as Email[];
  const macroregionEmails = Array.from(macroregionEmailsListSet) as Email[];

  const profilesData = profiles([...regionEmails, ...macroregionEmails]);

  const addRegionDialog = useDialogRoute(`/add-region`, ({ onClose }) => (
    <Dialog
      onClose={onClose}
      header={"Dodaj region"}
      placement={isMobile ? "top" : "center"}
      css={{ width: isMobile ? "100%" : "500px" }}
    >
      <AddRegion
        onSuccess={() => {
          queryClient.invalidateQueries(regionsQuery.key);
          onClose();
        }}
      />
    </Dialog>
  ));

  const addMacroregionDialog = useDialogRoute(`/add-macroregion`, ({ onClose }) => (
    <Dialog
      onClose={onClose}
      header={"Dodaj makroregion"}
      placement={isMobile ? "top" : "center"}
      css={{ width: isMobile ? "100%" : "500px" }}
    >
      <SaveMacroregion
        onSuccess={() => {
          queryClient.invalidateQueries(macroregionsQuery.key);
          onClose();
        }}
      />
    </Dialog>
  ));

  return (
    <>
      <Page>
        <div css={{ display: "flex", gap: "32px", flexDirection: isMobile ? "column" : "row" }}>
          <div css={{ flex: "1" }}>
            <SectionHeader
              actions={
                <Button icon={<MdAdd />} variant="outline" onClick={addRegionDialog.open}>
                  Nowy
                </Button>
              }
            >
              Regiony
            </SectionHeader>
            <div css={{ display: "flex", flexDirection: "column", gap: "8px", marginTop: "16px" }}>
              <QuerySkeleton query={regionsQuery}>
                {(regions) =>
                  regions.items.map((region, i) => (
                    <RegionTile
                      region={region}
                      key={i}
                      queryKey={regionsQuery.key}
                      profile={profilesData?.find((profile) => profile.email === region.ownerEmail)}
                    />
                  ))
                }
              </QuerySkeleton>
            </div>
          </div>
          <div css={{ flex: "1" }}>
            <SectionHeader
              actions={
                <Button icon={<MdAdd />} variant="outline" onClick={addMacroregionDialog.open}>
                  Nowy
                </Button>
              }
            >
              Makroregiony
            </SectionHeader>
            <div css={{ display: "flex", flexDirection: "column", gap: "8px", marginTop: "16px" }}>
              <QuerySkeleton query={macroregionsQuery}>
                {(macroregions) =>
                  macroregions.items.map((macroregion, i) => (
                    <MacroregionTile
                      macroregion={macroregion}
                      key={i}
                      queryKey={macroregionsQuery.key}
                      profiles={profilesData?.filter((profile) => macroregion.emails.includes(profile.email as Email))}
                    />
                  ))
                }
              </QuerySkeleton>
            </div>
          </div>
        </div>
      </Page>
      {addRegionDialog.element}
      {addMacroregionDialog.element}
    </>
  );
};
