import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const groupDtoSerializer = Serializers.object({
  id: uuidSerializer,
  name: Serializers.string,
  roles: Serializers.array(Serializers.string),
  isActive: Serializers.boolean,
  accounts: Serializers.object({
    email: Serializers.string.nullable(),
    isActive: Serializers.boolean,
    firstName: Serializers.string.nullable(),
    lastName: Serializers.string.nullable(),
    profilePictureUrl: Serializers.string.nullable(),
    lastSignInAt: Serializers.datetime.nullable(),
  }).array(),
});
export type GroupDto = SerializerValue<typeof groupDtoSerializer>;

export const groupSimpleDtoSerializer = Serializers.object({
  id: uuidSerializer,
  name: Serializers.string,
  isActive: Serializers.boolean,
});
export type GroupSimpleDto = SerializerValue<typeof groupSimpleDtoSerializer>;

export const saveGroupBodySerializer = Serializers.object({
  name: Serializers.string,
  roles: Serializers.array(Serializers.string),
  isActive: Serializers.boolean,
});

export type SaveGroupBody = SerializerValue<typeof saveGroupBodySerializer>;

export const groupActions = {
  groupsQuery: HttpAction({
    path: "/groups",
    method: "get",
    requiresAuth: true as const,
    qsSerializer: Serializers.object<{ searchText?: string }>({
      searchText: Serializers.string.optional(),
    }),
    valueSerializer: Serializers.array(groupSimpleDtoSerializer),
  }),
  groupQuery: HttpAction({
    path: "/groups/id/:id",
    method: "get",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      id: Serializers.string,
    }),
    valueSerializer: groupDtoSerializer,
    errorSerializer: Serializers.stringOneOf("GroupNotFound"),
  }),
  saveGroup: HttpAction({
    path: "/groups/id/:id",
    method: "put",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      id: uuidSerializer,
    }),
    bodySerializer: saveGroupBodySerializer,
    errorSerializer: Serializers.stringOneOf("NameNotUnique"),
  }),
};
