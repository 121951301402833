import { useTheme } from "@emotion/react";

import { SavedViewDoc, SavedViewType } from "@megaron/docs-contracts";

import { getViewIcon } from "./ViewsBar";

type Props = {
  viewId: string | undefined;
  activeView?: SavedViewDoc;
};

export const EditedViewHeader: React.FC<Props> = ({ activeView, viewId }) => {
  const theme = useTheme();

  if (!viewId) {
    return null;
  }

  const Icon = activeView ? getViewIcon(activeView.viewType) : getViewIcon(getViewTypeForNewView(viewId));

  return (
    <div css={{ display: "flex", alignItems: "center", gap: "0.75rem", color: theme.colors.primary, fontSize: "1rem" }}>
      <Icon size={20} />
      <span>{activeView ? activeView.title : getLabelForViewType(getViewTypeForNewView(viewId))}</span>
    </div>
  );
};

const getViewTypeForNewView = (viewId: string): SavedViewType => {
  switch (viewId) {
    case "new-list":
      return "customerList";
    case "new-customer-analytics":
      return "customerAnalytics";
    case "new-map":
      return "customerMap";
    default:
      return "customerList";
  }
};

const getLabelForViewType = (viewType: SavedViewType): string => {
  switch (viewType) {
    case "customerList":
      return "Nowa lista";
    case "customerMap":
      return "Nowa mapa";
    case "customerAnalytics":
      return "Nowa analiza";
    default:
      return "Nowa lista";
  }
};
