import { CommonSearchProps, DocsSelectDialog, SelectOption } from "@megaron/dash-select";
import { DocType } from "@megaron/docs-contracts";

import { FilterColor, FlagFilter } from "./FlagFilter";

type SelectProps = CommonSearchProps & {
  initiallySelectedDocIds?: string[] | undefined;
  docType?: DocType[];
  color?: FilterColor;
  options?: SelectOption[];
};

export const DocsSelectFilter = (props: SelectProps) => {
  return (
    <DocsSelectDialog
      {...props}
      renderTrigger={(selectedOptions, onUnselectOption, onUnselectAll, onOpen) => {
        const isSelected = selectedOptions.length > 0;

        const chipLabel = isSelected
          ? selectedOptions.length === 1
            ? `${props.label}: ${selectedOptions[0].label}`
            : `${props.label} (${selectedOptions.length})`
          : props.label;

        return (
          <FlagFilter
            isActive={isSelected}
            label={chipLabel}
            onClick={onOpen}
            onDelete={onUnselectAll}
            color={props.color}
          />
        );
      }}
    />
  );
};
