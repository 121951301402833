import { AnyUser } from "@megaron/access-control";
import { DateString, dateStringSerializer } from "@megaron/date-string";
import { HttpAction, HttpService } from "@megaron/http-service";
import { HandlerMap } from "@megaron/http-service-host";
import { Serializers } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { accountDtoSerializer } from "./accountDto";
import { accountHistoryQuery } from "./accountHistoryQuery";
import { affiliateCodeActions } from "./affiliateCode";
import { availableRewardDtoSerializer } from "./availableRewardDto";
import { biActions } from "./bi";
import { dailyCodeActivity } from "./codeActivity";
import { codeGroupsActions } from "./codeGroups";
import { productCodeSummary } from "./codeSummary";
import { emitGiftPointsResultSerializer } from "./giftPoints";
import { importTestCodes } from "./importTestCodes";
import { listingActions } from "./listing";
import { myAccountDtoSerializer } from "./myAccountDto";
import { myCustomerDtoSerializer } from "./myCustomerDto";
import { myProductConsumption } from "./myProductConsumption";
import { myPurchaseSummaryQuery } from "./myPurchaseSummaryQuery";
import { productCodeActions } from "./productCode";
import { publicProfileActions } from "./publicProfile";
import { purchaseSerializer, purchaseSimpleSerializer } from "./purchase";
import { transactionDtoSerializer } from "./transactionDto";
import { userActivityDayDtoSerializer } from "./userActivityDayDto";

const myAccount = HttpAction({
  path: "/myAccount",
  method: "get",
  requiresAuth: true as const,
  valueSerializer: myAccountDtoSerializer,
});

const myAvailableRewards = HttpAction({
  path: "/myAvailableRewards",
  method: "get",
  requiresAuth: true as const,
  valueSerializer: Serializers.array(availableRewardDtoSerializer),
});

const availableRewards = HttpAction({
  path: "/availableRewards",
  method: "get",
  valueSerializer: Serializers.array(availableRewardDtoSerializer),
});

const buyReward = HttpAction({
  path: "/buyReward",
  method: "post",
  bodySerializer: Serializers.object({
    listingUuid: uuidSerializer,
    rewardId: Serializers.string,
  }),
  valueSerializer: Serializers.object({
    purchaseUuid: uuidSerializer,
  }),
  errorSerializer: Serializers.stringOneOf(
    "NotEnoughPoints",
    "ListingNotFound",
    "ListingUnavailable",
    "ProStatusRequired",
    "RewardNotListed",
  ),
  requiresAuth: true as const,
});

const purchases = HttpAction({
  path: "/purchases",
  method: "get",
  requiresAuth: true as const,
  qsSerializer: Serializers.object({
    since: Serializers.datetime.optional(),
  }),
  valueSerializer: Serializers.array(purchaseSimpleSerializer),
});

const purchaseQuery = HttpAction({
  path: "/purchases/uuid/:purchaseUuid",
  method: "get",
  paramsSerializer: Serializers.object({
    purchaseUuid: uuidSerializer,
  }),
  requiresAuth: true as const,
  valueSerializer: purchaseSerializer.nullable(),
});

const myTransactionHistory = HttpAction({
  path: "/myTransactionHistory",
  method: "get",
  requiresAuth: true as const,
  valueSerializer: Serializers.array(transactionDtoSerializer),
});

const myCustomers = HttpAction({
  path: "/myCustomers",
  method: "get",
  requiresAuth: true as const,
  valueSerializer: Serializers.array(myCustomerDtoSerializer),
});

const dailyUserActivity = HttpAction({
  path: "/userActivity/daily",
  qsSerializer: Serializers.object<{
    groupNames?: string[];
    since?: DateString;
    until?: DateString;
  }>({
    groupNames: Serializers.jsonString(Serializers.array(Serializers.string)).optional(),
    since: dateStringSerializer.optional(),
    until: dateStringSerializer.optional(),
  }),
  method: "get",
  requiresAuth: true as const,
  valueSerializer: Serializers.array(userActivityDayDtoSerializer),
});

const emitGiftPoints = HttpAction({
  path: "/giftPoints/emit",
  method: "post",
  requiresAuth: true as const,
  bodySerializer: Serializers.object({
    amount: Serializers.decimal,
    lifetimeLimit: Serializers.decimal,
    dryRun: Serializers.boolean,
  }),
  valueSerializer: emitGiftPointsResultSerializer,
});

const claimGiftPoints = HttpAction({
  path: "/giftPoints/claim",
  method: "post",
  requiresAuth: true as const,
  bodySerializer: Serializers.object({}),
  errorSerializer: Serializers.stringOneOf("NoUnclaimedGiftPoints"),
});

const grantOverdueProStatuses = HttpAction({
  path: "/grantOverdueProStatuses",
  method: "post",
  requiresAuth: true as const,
  valueSerializer: Serializers.object({
    grantedCount: Serializers.integer,
  }),
});

const grantOverdueReferralBonuses = HttpAction({
  path: "/grantOverdueReferralBonuses",
  method: "post",
  requiresAuth: true as const,
  valueSerializer: Serializers.object({
    grantedCount: Serializers.integer,
  }),
});

const account = HttpAction({
  path: "/account/uuid/:uuid",
  method: "get",
  requiresAuth: true as const,
  paramsSerializer: Serializers.object({
    uuid: uuidSerializer,
  }),
  valueSerializer: accountDtoSerializer,
  errorSerializer: Serializers.stringOneOf("AccountNotFound"),
});

const grantProStatus = HttpAction({
  requiresAuth: true as const,
  path: "/account/uuid/:accountUuid/grantProStatus",
  method: "post",
  paramsSerializer: Serializers.object({
    accountUuid: uuidSerializer,
  }),
});

export const pointsHttpService = HttpService({
  account,
  accountHistoryQuery,

  availableRewards,
  buyReward,
  claimGiftPoints,
  dailyUserActivity,
  emitGiftPoints,
  grantOverdueProStatuses,
  grantOverdueReferralBonuses,
  grantProStatus,
  myAccount,
  myAvailableRewards,
  myCustomers,
  myProductConsumption,
  myPurchaseSummaryQuery,
  myTransactionHistory,
  purchaseQuery,
  purchases,
  importTestCodes,
  dailyCodeActivity,
  productCodeSummary,
  ...productCodeActions,
  ...publicProfileActions,
  ...affiliateCodeActions,
  ...listingActions,
  ...biActions,
  ...codeGroupsActions,
});

export type PointsHandlers = HandlerMap<typeof pointsHttpService, AnyUser>;
