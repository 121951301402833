import { accountPreferencesHttpService } from "@megarax/account-preferences-contracts";
import { authHttpService } from "@megarax/auth-contracts";
import { legacyMegaraxHttpService } from "@megarax/crm-contracts";
import { notificationsHttpService } from "@megarax/notifications-contracts";
import React, { useContext } from "react";

import { crmHttpService } from "@megaron/crm-contracts";
import { docsHttpService } from "@megaron/docs-contracts";
import { HttpServiceClient } from "@megaron/http-service-client";
import { invoicesHttpService } from "@megaron/invoices-contracts";
import { ordersHttpService } from "@megaron/loyalty-orders-contracts";
import { pointsHttpService } from "@megaron/loyalty-points-contracts";
import { qrSyncService } from "@megaron/loyalty-qr-sync-contracts";
import { Failure, Ok } from "@megaron/result";

export type ClientAction<TIn, TValue, TErr> = (input: TIn) => Promise<Ok<TValue> | Failure<TErr>>;

export type Clients = {
  auth: HttpServiceClient<typeof authHttpService>;
  notifications: HttpServiceClient<typeof notificationsHttpService>;
  accountPreferences: HttpServiceClient<typeof accountPreferencesHttpService>;
  loyaltyPoints: HttpServiceClient<typeof pointsHttpService>;
  loyaltyOrders: HttpServiceClient<typeof ordersHttpService>;
  crm: HttpServiceClient<typeof crmHttpService>;
  docs: HttpServiceClient<typeof docsHttpService>;
  qrSync: HttpServiceClient<typeof qrSyncService>;
  invoices: HttpServiceClient<typeof invoicesHttpService>;
  legacyMegarax: HttpServiceClient<typeof legacyMegaraxHttpService>;
};

export const ClientContext = React.createContext<Partial<Clients>>({});

export type ClientName = keyof Clients;

export const useServiceClient = <TName extends keyof Clients>(serviceName: TName): Clients[TName] => {
  const clients = useContext(ClientContext);
  const client = clients[serviceName];

  if (client === undefined) {
    throw new Error(`No client for service ${serviceName} found`);
  }
  return client!;
};
