import { useTheme } from "@emotion/react";
import Decimal from "decimal.js";

type Props = {
  salesHistory: Decimal[] | undefined;
};

export const SalesHistory: React.FC<Props> = ({ salesHistory }) => {
  const theme = useTheme();

  if (!salesHistory) return null;

  const maxHeight = 26;

  const maxValue = Math.max(...salesHistory.map((x) => x.toNumber()));

  const multiplier = Number(maxValue ? (maxHeight / maxValue).toFixed(6) : 0);

  return (
    <div css={{ display: "flex", alignItems: "center", gap: "2px" }}>
      {salesHistory.map((value, index) => {
        const height = Number(value.mul(multiplier).toFixed(0));

        return (
          <div
            key={`sales-history-${value}-${index}`}
            css={{
              height: height >= 1 ? `${height}px` : "1px",
              width: "8px",
              borderRadius: "3px",
              background: theme.colors.primary,
              opacity: height ? 1 : 0.3,
            }}
          />
        );
      })}
    </div>
  );
};
