import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom-v5-compat";

import { Avatar } from "@megaron/dash-avatar";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { Tile } from "@megaron/dash-tile";
import { UserDoc } from "@megaron/docs-contracts";
import { useProfiles } from "@megaron/megarax-webapps";
import { useClientManager } from "@megaron/react-clients";
import { ResourceId } from "@megaron/resource";
import { CustomerDoc, CustomerInteractionDoc } from "@megaron/crm-contracts";

export const LatestMentions = () => {
  const theme = useTheme();

  const mentionsQuery = useClientManager("docs").customerMentionedInteractionsQuery().useQuery({});

  const { profiles } = useProfiles();

  const ownerProfiles = profiles(mentionsQuery.data?.items.map((mention) => mention.ownerEmail) ?? []);

  const customers = useClientManager("docs")
    .retrieveDocs()
    .useQuery({
      docIds: mentionsQuery.data?.items.map((mention) => ResourceId("customer", mention.customerUuid)) ?? [],
    });

  return (
    <QuerySkeleton query={mentionsQuery}>
      {(mentions) => (
        <div css={{ display: "flex", flexDirection: "column", gap: "0.675rem" }}>
          {mentions.items.length > 0 ? (
            mentions.items.map((mention) => (
              <MentionTile
                mention={mention}
                key={mention.uuid}
                ownerProfile={ownerProfiles?.find((profile) => profile.email === mention.ownerEmail)}
                customerName={
                  customers.data?.find((customer) => (customer as CustomerDoc).uuid === mention.customerUuid)?.name ||
                  undefined
                }
              />
            ))
          ) : (
            <div css={{ fontSize: "0.875rem", color: theme.colors.secondaryText }}>Brak oznaczeń</div>
          )}
        </div>
      )}
    </QuerySkeleton>
  );
};

const MentionTile: React.FC<{
  mention: CustomerInteractionDoc;
  ownerProfile: UserDoc | undefined;
  customerName: string | undefined;
}> = ({ mention, ownerProfile, customerName }) => {
  const theme = useTheme();

  const navigate = useNavigate();

  const handleMentionClick = () => {
    const path = `/crm/customers/id/${mention.customerUuid}?tab=historia`;

    navigate(path, { state: { scrollTo: `interaction-${mention.uuid}` } });
  };

  return (
    <Tile onClick={handleMentionClick} css={{ cursor: "pointer" }}>
      <div css={{ display: "flex", flexDirection: "column", gap: "0.675rem" }}>
        <div css={{ display: "flex", alignItems: "center", gap: "0.5rem", justifyContent: "space-between" }}>
          <div css={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <Avatar src={ownerProfile?.profilePictureUrl} size="18px" />
            <span css={{ fontSize: "0.875rem", color: theme.colors.secondaryText }}>{ownerProfile?.name ?? "-"}</span>
          </div>
          <span css={{ fontSize: "0.75rem", color: theme.colors.secondaryText }}>
            {mention.interactionDate.toLocaleDateString()}
          </span>
        </div>
        <p css={{ margin: 0, fontSize: "1rem", color: "black" }}>{mention.message}</p>
        <span css={{ fontSize: "0.875rem", color: theme.colors.primary }}>
          Dotyczy klienta: {customerName || mention.customerUuid}
        </span>
      </div>
    </Tile>
  );
};
