import { useTheme } from "@emotion/react";
import { getMonth, getYear } from "date-fns";

import { getMonthString } from "@megaron/dash-filters";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useClientManager } from "@megaron/react-clients";
import { getMonths, Month } from "@megaron/serializers";

import { RegionRealizationCard } from "./RegionRealizationCard";
import { TimeRangeSelect } from "./TimeRangeSelect";

type Props = {
  region: string;
} & (
  | {
      startDate?: undefined;
      endDate?: undefined;
      onDatesChange?: undefined;
      hideTimeRangeSelect: true;
    }
  | {
      startDate: Month;
      endDate: Month;
      onDatesChange: (startDate: Month, endDate: Month) => void;
      hideTimeRangeSelect?: false;
    }
);

export const RegionRealizationSection: React.FC<Props> = ({
  startDate,
  endDate,
  onDatesChange,
  region,
  hideTimeRangeSelect,
}) => {
  const theme = useTheme();

  const regionProfitMetricsQuery = useClientManager("crm")
    .regionProfitMetricsQuery()
    .useQuery({
      months: getMonths(
        startDate ?? getStartDate(new Date()),
        endDate ?? (`${getYear(new Date())}-${getMonthString(getMonth(new Date()) + 1)}` as Month),
      ),
    });

  const isTwoMonthsDifference = startDate && endDate ? checkIsTwoMonthsDifference(startDate, endDate) : true;

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "2rem", flexGrow: 1 }}>
      {!hideTimeRangeSelect && (
        <TimeRangeSelect startDate={startDate} endDate={endDate} onDatesChange={onDatesChange} />
      )}
      <QuerySkeleton query={regionProfitMetricsQuery}>
        {(metrics) => {
          const regionMetrics = metrics.byRegion.find((m) => m.region === region);

          if (!regionMetrics) {
            return (
              <div
                css={{
                  display: "flex",
                  padding: "1rem",
                  borderRadius: theme.smallBorderRadius,
                  background: "white",
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                  color: theme.colors.secondaryText,
                  fontSize: "0.875rem",
                }}
              >
                Brak danych dla wybranego zakresu czasowego
              </div>
            );
          }

          return (
            <RegionRealizationCard
              regionName={regionMetrics.region ?? ""}
              regionOwnerEmail={regionMetrics.regionOwner}
              currentRealization={regionMetrics.profit.toNumber()}
              goalRealization={regionMetrics.target.toNumber()}
              hideDetails={!isTwoMonthsDifference}
            />
          );
        }}
      </QuerySkeleton>
    </div>
  );
};

const checkIsTwoMonthsDifference = (startDate: Month, endDate: Month) => {
  const [startYear, startMonth] = startDate.split("-");
  const [endYear, endMonth] = endDate.split("-");

  if (startYear === endYear) {
    return parseInt(endMonth) - parseInt(startMonth) === 1;
  }

  if (parseInt(startYear) + 1 === parseInt(endYear)) {
    return 12 - parseInt(startMonth) + parseInt(endMonth) === 1;
  }

  return false;
};

const getStartDate = (today: Date) => {
  const month = getMonth(today);
  const year = getYear(today);

  if (month === 0) {
    return `${year - 1}-12` as Month;
  }

  return `${year}-${getMonthString(month)}` as Month;
};
