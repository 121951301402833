import Decimal from "decimal.js";

import { DottedProgress } from "@megaron/dash-progress";

type Props = {
  potentialRealization: Decimal | undefined;
  potential: Decimal | undefined;
  actualRealization?: Decimal | undefined;
  singleDotValue?: number;
};

export const PotentialRealization: React.FC<Props> = ({
  potential,
  potentialRealization,
  actualRealization,
  singleDotValue,
}) => {
  return (
    <div css={{ display: "flex", alignItems: "center", gap: "0.375rem", flexWrap: "nowrap" }}>
      <span css={{ fontSize: "0.875rem" }}>{(potentialRealization ?? new Decimal(0)).mul(100).toFixed()}%</span>
      <DottedProgress
        currentValue={
          potentialRealization && potential && potential.gt(0)
            ? Number(potentialRealization.mul(potential).toFixed(2))
            : Number(actualRealization) ?? 0
        }
        goalValue={Number(potential ?? 0)}
        singleDotValue={singleDotValue ?? 10000}
      />
    </div>
  );
};
