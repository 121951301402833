import { useClientManager } from "@megaron/react-clients";
import { Serializers, SerializerValue } from "@megaron/serializers";

import { presetFiltersSerializer } from "./useCustomersFilters";

export const listPresetColumnsSerializer = Serializers.object({
  added: Serializers.string.array(),
  removed: Serializers.string.array(),
});

export type ListPresetColumns = SerializerValue<typeof listPresetColumnsSerializer>;

export const listPresetSerializer = Serializers.object({
  filters: presetFiltersSerializer,
  columns: listPresetColumnsSerializer,
});

export type ListPreset = SerializerValue<typeof listPresetSerializer>;

export const newListPreset = (): ListPreset => ({
  filters: {
    sort: undefined,
    isPro: false,
    isAffiliate: false,
    isActive: undefined,
    pos: undefined,
    tags: undefined,
    isMyRegion: true,
    regions: undefined,
    isUnassigned: undefined,
    scannedProducts: undefined,
    status: ["unverified", "verified", "vip"],
    categories: undefined,
    parents: undefined,
    relation: undefined,
  },
  columns: { added: [], removed: [] },
});

export const useCustomersQuery = (preset: ListPreset) => {
  return useClientManager("docs")
    .searchCustomers()
    .useQuery({
      ...preset.filters,
    });
};
