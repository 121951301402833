import Decimal from "decimal.js";

export type BlikRewardConfig = {
  type: "blik";
  valuePln: Decimal;
  amount: Decimal;
};

export type TestRewardConfig = {
  type: "test";
  valuePln: Decimal;
  isFulfilled: boolean;
  isFailed: boolean;
};

export type ShoperRewardConfig = {
  valuePln: Decimal;
  type: "shoper";
  productOptions: string[];
};

export type RewardConfig = BlikRewardConfig | ShoperRewardConfig | TestRewardConfig;

export const rewards: {
  [key: string]: RewardConfig | undefined;
} = {
  blikTestReward: {
    type: "blik",
    valuePln: new Decimal(1),
    amount: new Decimal(1),
  },
  shoperTestReward: {
    type: "shoper",
    valuePln: new Decimal(1),
    productOptions: ["1"],
  },
  failedTestReward: {
    type: "test",
    valuePln: new Decimal(1),
    isFulfilled: false,
    isFailed: true,
  },
  fulfilledTestReward: {
    type: "test",
    valuePln: new Decimal(1),
    isFulfilled: true,
    isFailed: false,
  },
  pendingTestReward: {
    type: "test",
    valuePln: new Decimal(1),
    isFulfilled: false,
    isFailed: false,
  },
  testReward1000pln: {
    type: "test",
    valuePln: new Decimal(1000),
    isFulfilled: true,
    isFailed: false,
  },

  "100-pln-promo": {
    type: "blik",
    valuePln: new Decimal(100),
    amount: new Decimal(100),
  },
  "100-pln": {
    type: "blik",
    valuePln: new Decimal(100),
    amount: new Decimal(100),
  },
  "200-pln": {
    type: "blik",
    valuePln: new Decimal(200),
    amount: new Decimal(200),
  },
  "500-pln": {
    type: "blik",
    valuePln: new Decimal(500),
    amount: new Decimal(500),
  },
  "800-pln": {
    type: "blik",
    valuePln: new Decimal(800),
    amount: new Decimal(800),
  },
  "1000-pln": {
    type: "blik",
    valuePln: new Decimal(1000),
    amount: new Decimal(1000),
  },

  "kielnia-contact-max": {
    type: "shoper",
    valuePln: new Decimal("59"),
    productOptions: ["75"],
  },
  "kielnia-specjalista": {
    type: "shoper",
    valuePln: new Decimal("59"),
    productOptions: ["75"],
  },
  "wzornik-bihui": {
    type: "shoper",
    valuePln: new Decimal("58.99"),
    productOptions: ["89"],
  },
  "paca-zebata-contact-max": {
    type: "shoper",
    valuePln: new Decimal("139"),
    productOptions: ["74"],
  },
  "zestaw-glazurniczy-bihui": {
    type: "shoper",
    valuePln: new Decimal("264"),
    productOptions: ["90"],
  },
  "przecinarka-sigma": {
    type: "shoper",
    valuePln: new Decimal("2126"),
    productOptions: ["91"],
  },
  "odpylacz-dustex": {
    type: "shoper",
    valuePln: new Decimal("189"),
    productOptions: ["76"],
  },
  "tshirt-contact-max": {
    type: "shoper",
    valuePln: new Decimal("119"),
    productOptions: ["157", "158", "159", "160"],
  },
  "czapka-contact-max": {
    type: "shoper",
    valuePln: new Decimal("119"),
    productOptions: ["77"],
  },
  "bluza-contact-max": {
    type: "shoper",
    valuePln: new Decimal("219"),
    productOptions: ["161", "162", "163"],
  },
  "softshell-contact-max": {
    type: "shoper",
    valuePln: new Decimal("299"),
    productOptions: ["156", "155", "154", "153"],
  },
  "paca-grawerowana-pro": {
    type: "shoper",
    valuePln: new Decimal("250"),
    productOptions: ["88"],
  },
  "szpachelka-grawerowana-pro": {
    type: "shoper",
    valuePln: new Decimal("250"),
    productOptions: ["108"],
  },
  "papa-w-plynie-1-l-biala": {
    type: "shoper",
    valuePln: new Decimal("79.81"),
    productOptions: ["69"],
  },
  "papa-w-plynie-1-l-brazowa": {
    type: "shoper",
    valuePln: new Decimal("79.81"),
    productOptions: ["70"],
  },
  "papa-w-plynie-1-l-grafitowa": {
    type: "shoper",
    valuePln: new Decimal("79.81"),
    productOptions: ["71"],
  },
  "papa-w-plynie-1-l-ceglana": {
    type: "shoper",
    valuePln: new Decimal("79.81"),
    productOptions: ["72"],
  },
  "papa-w-plynie-3-l-biala": {
    type: "shoper",
    valuePln: new Decimal("209"),
    productOptions: ["61"],
  },
  "papa-w-plynie-3-l-brazowa": {
    type: "shoper",
    valuePln: new Decimal("209"),
    productOptions: ["42"],
  },
  "papa-w-plynie-3-l-ceglana": {
    type: "shoper",
    valuePln: new Decimal("209"),
    productOptions: ["60"],
  },
  "papa-w-plynie-3-l-grafitowa": {
    type: "shoper",
    valuePln: new Decimal("209"),
    productOptions: ["59"],
  },
  "kamera-flir-one-pro-lt-android": {
    type: "shoper",
    valuePln: new Decimal("1200"),
    productOptions: ["98"],
  },
  "kamera-flir-one-pro-lt-ios": {
    type: "shoper",
    valuePln: new Decimal("1200"),
    productOptions: ["99"],
  },
  "bluza-swiateczna": {
    type: "shoper",
    valuePln: new Decimal("200"),
    productOptions: ["103", "104"],
  },
  "czapka-zimowa-contact-max": {
    type: "shoper",
    valuePln: new Decimal("99"),
    productOptions: ["106"],
  },
  "komin-smig": {
    type: "shoper",
    valuePln: new Decimal("60"),
    productOptions: ["107"],
  },
  "szpachelka-jednoczesciowa-50-mm": {
    type: "shoper",
    valuePln: new Decimal("65"),
    productOptions: ["109"],
  },
  "szpachelka-jednoczesciowa-150-mm": {
    type: "shoper",
    valuePln: new Decimal("80"),
    productOptions: ["110"],
  },
  "nabierak-jednoczesciowy": {
    type: "shoper",
    valuePln: new Decimal("85"),
    productOptions: ["111"],
  },
  "skrobak-jednoczesciowy": {
    type: "shoper",
    valuePln: new Decimal("75"),
    productOptions: ["112"],
  },
  "noz-do-gladzi-specjalista": {
    type: "shoper",
    valuePln: new Decimal("240"),
    productOptions: ["168"],
  },

  "czarny-tshirt-specjalista": {
    type: "shoper",
    valuePln: new Decimal("119"),
    productOptions: ["145", "146", "147", "148"],
  },
  "czarna-bluza-specjalista": {
    type: "shoper",
    valuePln: new Decimal("245"),
    productOptions: ["137", "138", "139", "140"],
  },
  "czarna-kamizelka-specjalista": {
    type: "shoper",
    valuePln: new Decimal("299"),
    productOptions: ["149", "150", "151", "152", "165"],
  },
  "czarne-spodnie-robocze-specjalista": {
    type: "shoper",
    valuePln: new Decimal("250"),
    productOptions: ["141", "142", "143", "144"],
  },
  "kubek-termiczny-specjalista": {
    type: "shoper",
    valuePln: new Decimal("110"),
    productOptions: ["164"],
  },
};

export const rewardIds = Object.keys(rewards);
