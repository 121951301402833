import {
  customerCategories,
  CustomerDoc,
  customerDocSerializer,
  customerInteractionDocSerializer,
  CustomerPricingRules,
  customerStatuses,
  relationTypes,
} from "@megaron/crm-contracts";
import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { customerEventDocSerializer } from "./customerEvents";

export { customerDocSerializer };
export type { CustomerDoc, CustomerPricingRules };

export const customerSortFields = [
  "name",
  "totalMassKg",
  "lastInteractionTime",
  "lastVisitTime",
  "lastPurchaseTime",
  "invitedChildrenCount",
  "supplyPointChildrenCount",
  "payerChildrenCount",
  "distributorChildrenCount",
  "chainChildrenCount",
] as const;
export type CustomerSortField = (typeof customerSortFields)[number];

export const customerActions = {
  searchCustomers: HttpAction({
    path: "/customers",
    qsSerializer: Serializers.object({
      searchText: Serializers.string,
      sort: Serializers.sortFilter(customerSortFields).asJsonString(),
      isPro: Serializers.boolean.asJsonString(),
      isAffiliate: Serializers.boolean.asJsonString(),
      tags: Serializers.string.array().asJsonString(),
      regions: Serializers.string.array().asJsonString(),
      isArchived: Serializers.boolean.asJsonString(),
      isUnassigned: Serializers.boolean.asJsonString(),
      isActive: Serializers.boolean.asJsonString(),
      status: Serializers.stringOneOf(...customerStatuses)
        .array()
        .asJsonString(),
      hasPricingRules: Serializers.booleanFieldFilter,
      isOnTradeRoute: Serializers.booleanFieldFilter,
      lastPurchaseTimeBefore: Serializers.datetime,
      lastPurchaseTimeAfter: Serializers.datetime,
      lastInteractionTimeBefore: Serializers.datetime,
      lastInteractionTimeAfter: Serializers.datetime,
      lastVisitTimeBefore: Serializers.datetime,
      lastVisitTimeAfter: Serializers.datetime,
      limit: Serializers.integer,
      offset: Serializers.integer,
      categories: Serializers.stringOneOf(...customerCategories)
        .array()
        .asJsonString(),
      scannedProducts: Serializers.string.array().asJsonString(),
      relation: Serializers.object({
        relationType: Serializers.stringOneOf(...relationTypes),
        parents: Serializers.string.array(),
      }).asJsonString(),
      nearCoordinates: Serializers.object({
        latitude: Serializers.float,
        longitude: Serializers.float,
        rangeKm: Serializers.float,
      }).asJsonString(),
    }).partial(),
    valueSerializer: Serializers.paginatedList(customerDocSerializer),
    requiresAuth: true,
  }),

  customerHistoryQuery: HttpAction({
    path: "/customer/:customerUuid/history",
    paramsSerializer: Serializers.object({
      customerUuid: uuidSerializer,
    }),
    valueSerializer: Serializers.paginatedList(
      Serializers.first(customerEventDocSerializer, customerInteractionDocSerializer),
    ),
    requiresAuth: true,
  }),
};
