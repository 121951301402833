import { Profile } from "@megarax/auth-contracts";

import { Avatar, DefaultAvatar } from "@megaron/dash-avatar";

type Props = {
  message?: string;
  postedBy?: Profile;
};

export const LastComment: React.FC<Props> = ({ message, postedBy }) => {
  if (!message) {
    return null;
  }

  return (
    <div css={{ display: "flex", alignItems: "center", gap: "0.375rem" }}>
      {postedBy?.profilePictureUrl ? <Avatar src={postedBy.profilePictureUrl} size="1rem" /> : <DefaultAvatar />}
      <p
        css={{
          fontSize: "0.875rem",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "12rem",
          margin: 0,
        }}
      >
        {message}
      </p>
    </div>
  );
};
