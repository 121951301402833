import { authAttributeSerializer } from "@megaron/access-control";
import { HttpAction } from "@megaron/http-service";
import { PubsubTopic } from "@megaron/pubsub-contracts";
import { resourceIdSerializer } from "@megaron/resource";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const budgetDtoSerializer = Serializers.object({
  uuid: uuidSerializer,
  name: Serializers.string,
  budgetNumber: Serializers.string,
  alert: Serializers.string,
  managerGroupIds: Serializers.string.array(),
  description: Serializers.string,
  accountant: Serializers.string.nullable(),
  isArchived: Serializers.boolean,
});

export type BudgetDto = SerializerValue<typeof budgetDtoSerializer>;

const saveBudget = HttpAction({
  path: "/budgets/uuid/:uuid",
  method: "put",
  requiresAuth: true,
  paramsSerializer: Serializers.object({
    uuid: uuidSerializer,
  }),
  errorSerializer: Serializers.stringOneOf("BudgetNumberNotUnique", "NameNotUnique"),
  bodySerializer: budgetDtoSerializer,
});

export const budgetDocSerializer = Serializers.object({
  docType: Serializers.stringOneOf("budget"),
  docId: resourceIdSerializer("budget"),
  authorizedAttributes: authAttributeSerializer.array(),
  boostedFor: authAttributeSerializer.array(),
  name: Serializers.string,
  uuid: uuidSerializer,
  budgetNumber: Serializers.string,
  alert: Serializers.string,
  description: Serializers.string,
  managerGroupIds: Serializers.string.array(),
  accountant: Serializers.string.nullable(),
  isArchived: Serializers.boolean,
});

export type BudgetDoc = SerializerValue<typeof budgetDocSerializer>;

export const budgetDocsQuery = HttpAction({
  path: "/budgets/docs",
  method: "get",
  requiresAuth: true,
  valueSerializer: budgetDocSerializer.array(),
  qsSerializer: Serializers.object({
    uuid: uuidSerializer,
  }).partial(),
});

export const budgetActions = {
  saveBudget,
  budgetDocsQuery,
};

export const budgetChangedTopic = PubsubTopic({
  name: "invoices-budget-changed",
  serializer: Serializers.object({
    uuid: uuidSerializer,
  }),
});

export type BudgetChangedMessage = SerializerValue<typeof budgetChangedTopic.serializer>;
