import { ListPreset } from "./customerListPreset";
import { tableHeadingsList } from "./CustomerTable";

export const defaultCustomersVisibleTableColumns = tableHeadingsList
  .filter((heading) => heading.isDefaultForAll)
  .map((heading) => heading.heading);

export const useCustomerVisibleTableColumns = (preset: ListPreset, updatePreset: (newPreset: ListPreset) => void) => {
  const visibleTableColumns = [
    ...defaultCustomersVisibleTableColumns.filter((column) => !preset.columns.removed.includes(column)),
    ...preset.columns.added,
  ];

  const onVisibleTableColumnsChange = (columns: string[]) => {
    updatePreset({
      ...preset,
      columns: {
        added: columns.filter((column) => !defaultCustomersVisibleTableColumns.includes(column)),
        removed: defaultCustomersVisibleTableColumns.filter((column) => !columns.includes(column)),
      },
    });
  };

  return { visibleTableColumns, onVisibleTableColumnsChange };
};
