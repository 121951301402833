import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { currencies } from "../common";

export const createCopyInputSerializer = Serializers.object({
  uuid: uuidSerializer,
  templateUuid: uuidSerializer,
});

const myOrderDraftSerializer = Serializers.object({
  buyer: Serializers.object({
    name: Serializers.string.optional(),
    uuid: uuidSerializer,
  }),
  payer: Serializers.object({
    name: Serializers.string.optional(),
    uuid: uuidSerializer,
  }),
  draftStartedAt: Serializers.datetime,
  isPlaced: Serializers.boolean,
  placedAt: Serializers.datetime.nullable(),
  uuid: uuidSerializer,
});

export type MyOrderDraft = SerializerValue<typeof myOrderDraftSerializer>;

export const myOrderSortFields = ["placedAt"] as const;

export type MyOrderSortField = (typeof myOrderSortFields)[number];

const orderDraftLineSerializer = Serializers.object({
  uuid: uuidSerializer,
  item: Serializers.object({ gtin: Serializers.string }),
  promoPrice: Serializers.decimal.nullable(),
  quantity: Serializers.integer,
  lineNetTotal: Serializers.decimal,
  finalPrice: Serializers.decimal,
  basePrice: Serializers.decimal,
});

export type OrderDraftLine = SerializerValue<typeof orderDraftLineSerializer>;

const orderDraftDtoSerializer = Serializers.object({
  uuid: uuidSerializer,
  payerDiscountRate: Serializers.decimal,
  buyer: Serializers.object({
    uuid: uuidSerializer,
  }),
  payer: Serializers.object({
    uuid: uuidSerializer,
  }),
  recipient: Serializers.object({
    uuid: uuidSerializer,
  }),
  isFinalized: Serializers.boolean,
  finalizedAt: Serializers.datetime.nullable(),
  priceList: Serializers.object({
    uuid: uuidSerializer,
  }),
  lines: Serializers.array(orderDraftLineSerializer),
  owner: Serializers.object({
    id: Serializers.integer,
  }),
  isDiscarded: Serializers.boolean,
  startedAt: Serializers.datetime,
  discardedAt: Serializers.datetime.nullable(),
  netTotal: Serializers.decimal,
  currency: Serializers.stringOneOf(...currencies),
});

export type OrderDraftDto = SerializerValue<typeof orderDraftDtoSerializer>;

const deliveryAddressSerializer = Serializers.object({
  houseNumber: Serializers.string,
  country: Serializers.string,
  locality: Serializers.string,
  name: Serializers.string,
  postalCode: Serializers.string,
  street: Serializers.string,
});

export type DeliveryAddress = SerializerValue<typeof deliveryAddressSerializer>;

const editCustomersInputSerializer = Serializers.object({
  sellToUuid: uuidSerializer,
  billToUuid: uuidSerializer,
  payerDepartmentUuid: uuidSerializer,
  payerDiscountRate: Serializers.decimal,
  priceListUuid: uuidSerializer,
});

export type EditCustomersInput = SerializerValue<typeof editCustomersInputSerializer>;

export const orderDraftActions = {
  getOrderDrafts: HttpAction({
    method: "get",
    path: "/sales/orderDrafts",
    qsSerializer: Serializers.object({
      limit: Serializers.integer.optional(),
      offset: Serializers.integer.optional(),
      searchText: Serializers.string.optional(),
      sortBy: Serializers.sortFilter(myOrderSortFields).asJsonString(),
    }).partial(),
    valueSerializer: Serializers.paginatedList(myOrderDraftSerializer),
  }),
  retrieveOrderDraft: HttpAction({
    method: "get",
    path: "/sales/orderDrafts/:uuid",
    paramsSerializer: Serializers.object({ uuid: uuidSerializer }),
    valueSerializer: orderDraftDtoSerializer,
    errorSerializer: Serializers.stringOneOf("DraftNotFound", "PriceListNotFound", "DraftDiscarded", "DraftFinalized"),
  }),
  placeOrderDraft: HttpAction({
    method: "post",
    path: "/sales/orderDrafts/:uuid",
    paramsSerializer: Serializers.object({ uuid: uuidSerializer }),
    bodySerializer: Serializers.object({
      deliveryAddress: deliveryAddressSerializer,
      comment: Serializers.string,
      deliveryDeadline: Serializers.dateOnly,
      externalOrderNumber: Serializers.string,
    }),
    errorSerializer: Serializers.stringOneOf(
      "PriceListNotFound",
      "OrderNotFound",
      "DraftNotOpen",
      "ItemNotFound",
      "UserNotOrderOwner",
      "UnknownGtin",
    ),
  }),
  discardOrderDraft: HttpAction({
    method: "delete",
    path: "/sales/orderDrafts/:uuid",
    errorSerializer: Serializers.stringOneOf("OrderNotFound", "DraftNotOpen", "UserNotOrderOwner"),
  }),
  editOrderDraftCustomers: HttpAction({
    method: "put",
    path: "/sales/orderDrafts/:uuid",
    bodySerializer: editCustomersInputSerializer,
    errorSerializer: Serializers.stringOneOf("OrderNotFound", "DraftNotOpen", "UserNotOrderOwner"),
  }),
  startOrderDraft: HttpAction({
    path: "/sales/orderDrafts",
    method: "post",
    bodySerializer: Serializers.object({
      uuid: uuidSerializer,
      payerDiscountRate: Serializers.decimal,
      buyer: Serializers.object({
        uuid: uuidSerializer,
      }),
      payer: Serializers.object({
        uuid: uuidSerializer,
      }),
      recipient: Serializers.object({
        uuid: uuidSerializer,
      }),
      priceList: Serializers.object({
        uuid: uuidSerializer,
      }),
    }),
    errorSerializer: Serializers.stringOneOf("InvalidDiscountRate"),
  }),
  createOrderCopy: HttpAction({
    path: "/sales/orderDrafts/createCopy",
    method: "post",
    errorSerializer: Serializers.stringOneOf("DraftNotFound"),
    bodySerializer: createCopyInputSerializer,
  }),
};
