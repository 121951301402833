import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export type RegistrationData = {
  firstName: string;
  lastName: string;
  email: string;
  newsletterConsent: boolean;
};

export const registrationDataSerializer = Serializers.object({
  firstName: Serializers.string,
  lastName: Serializers.string,
  email: Serializers.string,
  postalCode: Serializers.postalCode.nullable(),
  emailConsent: Serializers.boolean,
  phoneCallConsent: Serializers.boolean,
  smsConsent: Serializers.boolean,
});

export const registerActions = {
  register: HttpAction({
    path: "/register",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: registrationDataSerializer,
    errorSerializer: Serializers.stringOneOf(
      "AlreadyRegistered",
      "InvalidEmail",
      "AccountNotFound",
      "EmailAlreadyInUse",
      "FailedToSendEmail",
      "InvalidPostalCode",
    ),
  }),

  updateMyAccount: HttpAction({
    path: "/updateMyAccount",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: registrationDataSerializer,
    errorSerializer: Serializers.stringOneOf(
      "InvalidEmail",
      "AccountNotFound",
      "EmailAlreadyInUse",
      "NotRegistered",
      "FailedToSendEmail",
      "InvalidPostalCode",
    ),
  }),

  findAccount: HttpAction({
    path: "/preregisterUser",
    method: "post",
    bodySerializer: Serializers.object({
      phoneNumber: Serializers.phoneNumber,
      preregister: Serializers.boolean,
    }),
    valueSerializer: Serializers.object({
      loyaltyId: uuidSerializer,
      isNew: Serializers.boolean,
    }),
    errorSerializer: Serializers.stringOneOf("AccountNotFound"),
    requiresAuth: true as const,
  }),

  verifyEmail: HttpAction({
    path: "/verifyEmail",
    method: "post",
    bodySerializer: Serializers.object({ token: Serializers.string }),
    errorSerializer: Serializers.stringOneOf(
      "InvalidToken",
      "AccountDeleted",
      "EmailAlreadyInUse",
      "EmailAlreadyVerified",
    ),
  }),
  resendVerificationMessage: HttpAction({
    path: "/resendVerificationMessage",
    method: "post",
    requiresAuth: true as const,
    errorSerializer: Serializers.stringOneOf(
      "NoPendingVerification",
      "AccountNotFound",
      "InvalidEmail",
      "FailedToSendEmail",
      "Unauthorized",
    ),
    bodySerializer: Serializers.object({
      accountUuid: uuidSerializer,
    }).partial(),
  }),
  skipEmailVerification: HttpAction({
    path: "/skipEmailVerification",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({ accountUuid: Serializers.string }),
    errorSerializer: Serializers.stringOneOf("NoPendingVerification", "AccountNotFound", "AccountDeleted"),
  }),
};
