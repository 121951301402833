import { parse } from "csv/browser/esm/sync";
import Decimal from "decimal.js";
import { useState } from "react";
import { MdOutlineUploadFile } from "react-icons/md";

import { SaveRegionTargetResponseValue } from "@megaron/crm-contracts";
import { Button } from "@megaron/dash-form";
import { useToast } from "@megaron/dash-toast";
import { useClientManager } from "@megaron/react-clients";

import { ImportedProfitTargetsDialog } from "./ImportedProfitTargetsDialog";
import { rawToInput } from "./input";

type Props = {
  onImportSuccess: () => void;
};

export const ImportProfitTargetsButton: React.FC<Props> = ({ onImportSuccess }) => {
  const toast = useToast();

  const [saveRegionTargetResponseValue, setSaveRegionTargetResponseValue] =
    useState<SaveRegionTargetResponseValue | null>(null);
  const [isImportedProfitTargetsDialogOpen, setIsImportedProfitTargetsDialogOpen] = useState(false);

  const saveRegionTargetMutation = useClientManager("crm").saveRegionTarget().useMutation();

  const onFileUpload: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;
    const parsed: any[] = parse(await file.text(), {
      columns: true,
    });

    const inputRowsResult = rawToInput(parsed);
    if (inputRowsResult.isFailure) {
      if (inputRowsResult.error === "InvalidInput") {
        toast.error("Nieprawidłowy format danych");
        return;
      }

      if (inputRowsResult.error === "MissingRegion") {
        toast.error("Plik musi zawierać kolumnę 'Nazwa regionu'");
        return;
      }

      if (inputRowsResult.error === "InvalidMonthValue") {
        toast.error("Niepoprawny format marży");
        return;
      }

      if (inputRowsResult.error === "InvalidRegion") {
        toast.error("Niepoprawny region");
        return;
      }

      toast.error("Nie udało się zaimportować pliku");
      return;
    }

    const regionTargetsBody = inputRowsResult.value.map((row) => ({
      regionName: row.region,
      targets: row.targets.reduce((acc, target) => {
        acc[target.month] = target.target;
        return acc;
      }, {} as Record<string, Decimal | null>),
    }));

    saveRegionTargetMutation.mutate(regionTargetsBody, {
      onSuccess: (responseValue) => {
        onImportSuccess();
        setSaveRegionTargetResponseValue(responseValue);
        setIsImportedProfitTargetsDialogOpen(true);
      },
      onError: () => {
        setSaveRegionTargetResponseValue(null);
        toast.error("Nie udało się zaimportować pliku");
      },
    });
  };

  return (
    <>
      <input accept=".csv" type="file" id="contained-button-file" style={{ display: "none" }} onChange={onFileUpload} />
      <label htmlFor="contained-button-file">
        <Button variant="outline" as="div" icon={<MdOutlineUploadFile />}>
          Importuj
        </Button>
      </label>
      {isImportedProfitTargetsDialogOpen && saveRegionTargetResponseValue && (
        <ImportedProfitTargetsDialog
          onClose={() => setIsImportedProfitTargetsDialogOpen(false)}
          responseValue={saveRegionTargetResponseValue}
        />
      )}
    </>
  );
};
