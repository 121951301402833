import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { PropsWithChildren } from "react";

type Props = {
  columnHeader: string;
  onContextMenu?: (e: React.MouseEvent) => void;
} & PropsWithChildren;

export const DraggableTableHeadingWrapper: React.FC<Props> = ({ columnHeader, children, onContextMenu }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: columnHeader });

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      css={{
        transform: CSS.Translate.toString(transform),
        transition,
        cursor: "grab",
      }}
      onContextMenu={onContextMenu}
    >
      {children}
    </div>
  );
};
