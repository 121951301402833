import { MdOutlineCheck, MdOutlineDelete } from "react-icons/md";

import { Button } from "@megaron/dash-form";

type Props = {
  onSave: () => void;
  onDelete?: () => void;
}

export const AggActionButtons: React.FC<Props> = ({onSave, onDelete}) => {
  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        justifyContent: "space-between",
        marginTop: "0.25rem",
      }}
    >
      {onDelete && (
        <Button onClick={onDelete} icon={<MdOutlineDelete />} color="danger">
          Usuń
        </Button>
      )}
      <Button
        onClick={onSave}
        icon={<MdOutlineCheck />}
        css={{ marginLeft: "auto" }}
      >
        Zatwierdź
      </Button>
    </div>
  );
};
