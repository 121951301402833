import { baseDocSerializer, ResourceId } from "@megaron/resource";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { newUuid } from "@megaron/uuid";

export const testDocSerializer = Serializers.object({
  ...baseDocSerializer("test-doc").fields,
  id: Serializers.string,
  name: Serializers.string,
  content: Serializers.string,
});

export type TestDoc = SerializerValue<typeof testDocSerializer>;

export const newTestDoc = (props: Partial<TestDoc> = {}): TestDoc => {
  const id = newUuid();
  return {
    authorizedAttributes: ["userType:megarax"],
    content: "content",
    docId: ResourceId("test-doc", id),
    docType: "test-doc",
    id,
    name: "test doc",
    isArchived: false,
    boostedFor: [],
    ...props,
  };
};
