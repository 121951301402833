import { Dialog } from "@megaron/dash-dialog";

import { FiltersProps } from "./CustomerAnalyticsFilters";
import { FiltersAndAggList } from "./FiltersAndAggList";

type Props = {
  onClose: () => void;
  handleAddFiltersClick: () => void;
} & FiltersProps;

export const FiltersAndAggDialog: React.FC<Props> = ({ onClose, ...restProps }) => {
  return (
    <Dialog placement="top" onClose={onClose} css={{ width: "100%" }} header="Filtry i agregacje">
      <div css={{ display: "flex", flexWrap: "wrap", width: "100%", gap: "0.625rem" }}>
        <FiltersAndAggList {...restProps} disableTileClick />
      </div>
    </Dialog>
  );
};
