import { useTheme } from "@emotion/react";
import { OrderDraftLine } from "@megarax/crm-contracts";
import { formatCurrency } from "@megarax/react-utils";
import Decimal from "decimal.js";
import { useState } from "react";
import { MdAdd } from "react-icons/md";

import { useDialogRoute } from "@megaron/dash-dialog";
import { Button } from "@megaron/dash-form";
import { SectionHeader } from "@megaron/dash-page";
import { RowCellContent, Table, TableBody, TableHeadColumn } from "@megaron/dash-table";

import { AddItemDialog } from "./AddItemDialog";
import { EditItemDialog } from "./EditItemDialog";

type Props = {
  lines: OrderDraftLine[];
  onLinesChange: (lines: OrderDraftLine[]) => void;
  isDisabled?: boolean;
};

export const OrderItemsSection: React.FC<Props> = ({ lines, isDisabled, onLinesChange }) => {
  const theme = useTheme();

  const [editedLine, setEditedLine] = useState<OrderDraftLine | null>(null);

  const addItemDialog = useDialogRoute("/add-item", ({ onClose }) => (
    <AddItemDialog onClose={onClose} onAddLine={() => {}} />
  ));

  const editItemDialog = useDialogRoute("/edit-item", ({ onClose }) => {
    if (!editedLine) return null;

    return <EditItemDialog onClose={onClose} onEditLine={() => {}} line={editedLine} />;
  });

  const tableColumns: TableHeadColumn<string>[] = [
    ...tableHeadings.map(
      (heading) =>
        ({
          isSortable: false,
          label: heading,
        } as const),
    ),
  ];

  const getTableRowCellsContent = (line: OrderDraftLine): RowCellContent[] => {
    return [
      {
        cellCss: { textWrap: "nowrap" },
        element: line.item.gtin,
      },
      {
        element: line.quantity.toFixed(0),
      },
      {
        element: formatCurrency(line.lineNetTotal, "PLN"),
      },
    ];
  };

  const handleLineClick = (line: OrderDraftLine) => {
    setEditedLine(line);
    editItemDialog.open();
  };

  const fullPrice = lines.reduce((acc, line) => acc.add(line.lineNetTotal), new Decimal(0));

  const rows = [
    ...lines.map((line) => ({
      uuid: line.uuid,
      cellsContent: getTableRowCellsContent(line),
      onClick: () => handleLineClick(line),
      css: {
        "td:first-child": {
          padding: "0.75rem",
        },
      },
    })),
    {
      uuid: "lines-total",
      cellsContent: [
        {
          element: <span css={{ fontSize: "1rem", fontWeight: 700, color: theme.colors.primary }}>Razem:</span>,
        },
        { element: "" },
        {
          element: (
            <span css={{ fontSize: "1rem", fontWeight: 700, color: theme.colors.primary }}>
              {formatCurrency(fullPrice, "PLN")}
            </span>
          ),
        },
      ],
      css: {
        "td:first-child": {
          padding: "0.75rem",
          borderLeftStyle: "none",
        },
        td: {
          background: "none",
          borderTopStyle: "none",
        },
        "td:last-child": {
          borderRightStyle: "none",
        },
        "&:last-child td": {
          borderBottomStyle: "none",
        },
      },
    },
  ];

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%", opacity: isDisabled ? 0.5 : 1 }}>
      <SectionHeader
        isHr
        actions={
          <Button variant="outline" icon={<MdAdd />} isDisabled={isDisabled} onClick={addItemDialog.open}>
            Dodaj
          </Button>
        }
      >
        Przedmioty zamówienia
      </SectionHeader>

      <div css={{ overflow: "auto", margin: "0 -2rem 1rem", padding: "0 2rem" }}>
        <Table columns={tableColumns}>
          <TableBody
            rows={rows}
            css={{
              "tr:nth-last-child(2) td:last-child": {
                borderBottomRightRadius: theme.smallBorderRadius,
                overflow: "hidden",
              },
              "tr:nth-last-child(2) td:first-child": {
                borderBottomLeftRadius: theme.smallBorderRadius,
                overflow: "hidden",
              },
              "tr:nth-last-child(2) td": {
                borderBottomStyle: "solid",
              },
            }}
          />
        </Table>
      </div>

      {addItemDialog.element}

      {editItemDialog.element}
    </div>
  );
};

const tableHeadings = ["Jednostka", "Ilość", "Wartość netto"];
