import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { currencies } from "../common";

export const newOrderLineInputSerializer = Serializers.object({
  gtin: Serializers.gtin,
  quantity: Serializers.decimal,
  price: Serializers.decimal,
  discountRate: Serializers.decimal,
});

export const newOrderInputSerializer = Serializers.object({
  comment: Serializers.string,
  payerDepartmentUuid: uuidSerializer,
  sellToCustomerUuid: uuidSerializer,
  billToCustomerUuid: uuidSerializer,
  shipToCity: Serializers.string.optional(),
  shipToPostCode: Serializers.string.optional(),
  externalDocumentNumber: Serializers.string,
  shipToAddressLine1: Serializers.string.optional(),
  shipToAddressLine2: Serializers.string.optional(),
  shipToCountry: Serializers.string.optional(),
  requestedDeliveryDate: Serializers.dateOnly.optional(),
  lines: Serializers.array(newOrderLineInputSerializer),
  orderDate: Serializers.string,
  currency: Serializers.stringOneOf(...currencies),
});

export const bcActions = {
  placeNewOrder: HttpAction({
    path: "/bc/orders/uuid/:uuid",
    method: "post",
    paramsSerializer: Serializers.object({ uuid: uuidSerializer }),
    errorSerializer: Serializers.stringOneOf("OrderAlreadyExists", "UnknownGtin"),
    bodySerializer: newOrderInputSerializer,
    valueSerializer: uuidSerializer,
  }),
};
