import "./infoWindowStyle.css";

import { useTheme } from "@emotion/react";
import { MarkerClusterer, SuperClusterViewportAlgorithm } from "@googlemaps/markerclusterer";
import { useClickOutside } from "@mantine/hooks";
import { InfoWindow, useJsApiLoader } from "@react-google-maps/api";
import { differenceInCalendarDays } from "date-fns";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom-v5-compat";

import { CustomerDoc } from "@megaron/docs-contracts";

import { CustomerTile } from "./CustomerTile";

type Props = {
  customers: CustomerDoc[];
  queryKey: string | string[];
  map: google.maps.Map | undefined;
  onMarkerClick?: (customer: CustomerDoc) => void;
};

export const CustomerMarkers: React.FC<Props> = ({ customers, map, queryKey, onMarkerClick }) => {
  const [isMarkersLoaded, setIsMarkersLoaded] = useState(!!google.maps.marker);
  const theme = useTheme();
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerDoc | null>(null);
  const [searchParams] = useSearchParams();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env["NX_PUBLIC_GOOGLE_MAPS_API_KEY"]!,
  });

  const customerTileRef = useClickOutside(() => {
    setSelectedCustomer(null);

    /**
     * this is a hack needed to remove a div that is rendered under infowindow
     * and doesn't get removed when the infowindow is closed
     * this is a bug in google maps api
     */
    document.querySelectorAll(".gm-style-iw-tc").forEach((node) => node.remove());
  });

  const handleMarkerClick = useCallback(
    (customer: CustomerDoc) => {
      setSelectedCustomer(customer);
      onMarkerClick?.(customer);
    },
    [onMarkerClick],
  );

  useEffect(() => {
    const customerId = searchParams.get("customerId");
    if (customerId) {
      const customer = customers.find((customer) => customer.uuid === customerId);
      if (customer) handleMarkerClick(customer);
    }
  }, [customers, searchParams]);

  const isInactive = (time: Date) => differenceInCalendarDays(new Date(), time) > 90;

  useEffect(() => {
    if (!isLoaded) return;

    const loadMarkers = async () => {
      await google.maps.importLibrary("marker");
      setIsMarkersLoaded(true);
    };

    if (!isMarkersLoaded) {
      loadMarkers();
    }
  }, [isMarkersLoaded, isLoaded]);

  useLayoutEffect(() => {
    if (!customers || !map || !isMarkersLoaded || !isLoaded) {
      return;
    }

    // Add markers to the map.
    const markers = customers.map((customer, i) => {
      const position = {
        lat: Number(customer.address?.latitude || 0),
        lng: Number(customer.address?.longitude || 0),
      };

      const getActivityColor = (time: Date | null, isArchived: boolean) => {
        if (isArchived) return theme.colors.border;
        if (!time) return theme.colors.primary;
        return isInactive(time) ? theme.colors.danger : theme.colors.success;
      };

      const marker = new google.maps.Marker({
        position,
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          fillColor: getActivityColor(customer.lastPurchaseTime, customer.isArchived),
          fillOpacity: 1,
          strokeWeight: 1,
          scale: 8,
          strokeColor: "#ffffff",
        },
        clickable: true,
      });

      marker.addListener("click", () => {
        handleMarkerClick(customer);
      });

      return marker;
    });

    // Add a marker clusterer to manage the markers.
    const clusterer = new MarkerClusterer({
      markers,
      map,
      algorithm: new SuperClusterViewportAlgorithm({
        maxZoom: 10,
      }),
    });

    return () => {
      clusterer?.removeMarkers(markers);
      clusterer?.clearMarkers();
      clusterer?.setMap(null);
    };
  }, [
    customers,
    map,
    isMarkersLoaded,
    theme.colors.border,
    theme.colors.primary,
    theme.colors.danger,
    theme.colors.success,
    isLoaded,
  ]);

  if (!selectedCustomer) return null;

  return (
    <InfoWindow
      position={{
        lat: Number(selectedCustomer.address.latitude),
        lng: Number(selectedCustomer.address.longitude),
      }}
      options={{ pixelOffset: new window.google.maps.Size(0, -20) }}
    >
      <div ref={customerTileRef}>
        <CustomerTile customer={selectedCustomer} mapView queryKey={queryKey} />
      </div>
    </InfoWindow>
  );
};
