import { UserReference, uuidSerializer } from "@megarax/common";
import {
  BaseResource,
  idObjectReferenceSerializer,
  NestedResourceNode,
  ResourceAction,
  ResourceActionV2,
  uuidObjectReferenceSerializer,
  UuidResourceParam,
} from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";
import Decimal from "decimal.js";

import { Failure } from "@megaron/result";

import { eventNotificationAction } from "./eventNotificationAction";

export type PayerDetailQueryFailure = Failure<"PayerDoesNotExist">;

export interface ProposalDetail {
  uuid: string;
  priceList: { uuid: string } | null;
  baseDiscount: Decimal;
  proposedBy: UserReference;
  otherTerms: string;
  proposedAt: Date;
}

export interface PayerDetail {
  customerUuid: string;
  priceList: { uuid: string } | null;
  baseDiscount: Decimal;
  otherTerms: string;
  modifiedAt: Date;

  proposals: ProposalDetail[];
}

export interface ProposeRuleChangeInput {
  baseDiscount: Decimal;
  priceList: { uuid: string } | null;
  otherTerms: string;
}

export const payerDetailSerializer = MSerializers.object<PayerDetail>({
  baseDiscount: MSerializers.decimal,
  customerUuid: MSerializers.uuid(),
  modifiedAt: MSerializers.datetime,
  priceList: uuidObjectReferenceSerializer.nullable(),
  otherTerms: MSerializers.string,
  proposals: MSerializers.array(
    MSerializers.object<ProposalDetail>({
      baseDiscount: MSerializers.decimal,
      priceList: uuidObjectReferenceSerializer.nullable(),
      proposedBy: idObjectReferenceSerializer,
      uuid: MSerializers.uuid(),
      otherTerms: MSerializers.string,
      proposedAt: MSerializers.datetime,
    }),
  ),
});

export const proposeRuleChangeInput = MSerializers.object<ProposeRuleChangeInput>({
  baseDiscount: MSerializers.decimal,
  priceList: uuidObjectReferenceSerializer.nullable(),
  otherTerms: MSerializers.string,
});

export const payerResource = BaseResource(["pricing", "payers"], {
  eventNotifications: eventNotificationAction,
  payerBulkQuery: ResourceActionV2({
    name: "payerBulkQuery",
    method: "post",
    path: "payerBulkQuery",
    requestBodySerializer: MSerializers.object({
      customerUuids: MSerializers.array(uuidSerializer).optional(),
    }),
    responseValueSerializer: MSerializers.array(payerDetailSerializer),
  }),
  byCustomerUuid: UuidResourceParam("customerUuid", {
    retrieve: ResourceAction({
      name: "retrieve",
      method: "get",
      path: "",
      responseSerializer: MSerializers.result(
        payerDetailSerializer,
        MSerializers.identity<PayerDetailQueryFailure["error"]>(),
      ),
    }),
    proposals: NestedResourceNode("proposals", {
      propose: ResourceAction({
        name: "proposeRuleChange",
        method: "post",
        path: "",
        requestBodySerializer: proposeRuleChangeInput,
        responseSerializer: MSerializers.result<void, any>(),
      }),
      byUuid: UuidResourceParam("proposalUuid", {
        approve: ResourceAction({
          name: "approveProposal",
          method: "post",
          path: "approve",
          responseSerializer: MSerializers.result<void, any>(),
        }),
        reject: ResourceAction({
          name: "rejectProposal",
          method: "post",
          path: "reject",
          responseSerializer: MSerializers.result<void, any>(),
        }),
        retract: ResourceAction({
          name: "retractProposal",
          method: "delete",
          path: "",
          responseSerializer: MSerializers.result<void, any>(),
        }),
      }),
    }),
  }),
});
