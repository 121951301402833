export const checkArePresetsEqual = <T extends object>(obj1: T, obj2: T) => {
  const normalizeValue = (value: unknown): unknown => {
    if (Array.isArray(value)) {
      return value.map((item) => normalizeValue(item));
    }

    if (value !== null && typeof value === "object") {
      return Object.keys(value)
        .sort()
        .reduce<Record<string, unknown>>((acc, key) => {
          if (["offset", "limit", "page", "searchText"].includes(key)) {
            return acc;
          }

          const nestedValue = (value as Record<string, unknown>)[key];
          acc[key] = normalizeValue(nestedValue);
          return acc;
        }, {});
    }

    if (value === null) {
      return null;
    }

    return value;
  };

  const normalize = (obj: T) =>
    JSON.stringify(
      Object.keys(obj)
        .sort()
        .reduce<Record<string, unknown>>((acc, key) => {
          if (key === "offset" || key === "limit") {
            return acc;
          }

          acc[key] = normalizeValue(obj[key as keyof T]);
          return acc;
        }, {}),
    );

  return normalize(obj1) === normalize(obj2);
};
