import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";

import { ProductCodeStatus } from "./codeStatus";

export type CodeSummary = {
  total: number;
  byStatus: {
    [status in ProductCodeStatus]: number;
  };
};

export const codeSummarySerializer = Serializers.object<CodeSummary>({
  total: Serializers.integer,
  byStatus: Serializers.object({
    blank: Serializers.integer,
    active: Serializers.integer,
    redeemed: Serializers.integer,
    expired: Serializers.integer,
    destroyed: Serializers.integer,
  }),
});

export const productCodeSummary = HttpAction({
  path: "/productCodes/summary",
  method: "get",
  valueSerializer: codeSummarySerializer,
  qsSerializer: Serializers.object<{ groupNames?: string[] }>({
    groupNames: Serializers.jsonString(Serializers.array(Serializers.string)).optional(),
  }),
  requiresAuth: true as const,
});
