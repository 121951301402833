import { SectionHeader } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { ResourceId } from "@megaron/resource";
import { CustomerDoc } from "@megaron/docs-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { RelatedCustomerTile } from "./RelatedCustomerTile";

type RelatedCustomersCount = {
  invitedChildrenCount: number;
  supplyPointChildrenCount: number;
  payerChildrenCount: number;
  distributorChildrenCount: number;
  chainChildrenCount: number;
};

type Props = {
  customerUuid: Uuid;
};

export const RelatedCustomersList: React.FC<Props> = ({ customerUuid }) => {
  const customerDocQuery = useClientManager("docs")
    .retrieveDocs()
    .useQuery({ docIds: [ResourceId("customer", customerUuid)] });

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <QuerySkeleton height="41px" query={customerDocQuery}>
        {(customerDoc) => {
          const customer = customerDoc.find((c) => c?.docType === "customer") as CustomerDoc;

          const relatedCustomers: RelatedCustomersCount = {
            invitedChildrenCount: customer?.invitedChildrenCount,
            supplyPointChildrenCount: customer?.supplyPointChildrenCount,
            payerChildrenCount: customer?.payerChildrenCount,
            distributorChildrenCount: customer?.distributorChildrenCount,
            chainChildrenCount: customer?.chainChildrenCount,
          };

          const hasRelatedCustomers = Object.values(relatedCustomers).some((count) => count > 0);

          return (
            <>
              {hasRelatedCustomers && <SectionHeader>Powiązani klienci</SectionHeader>}
              {Object.entries(relatedCustomers).map(
                ([category, count]) =>
                  count > 0 && (
                    <RelatedCustomerTile
                      key={category}
                      count={count}
                      category={category as keyof RelatedCustomersCount}
                      customerUuid={customerUuid}
                    />
                  ),
              )}
            </>
          );
        }}
      </QuerySkeleton>
    </div>
  );
};
