import { useTheme } from "@emotion/react";
import { PaginatedList } from "libs/serializers/src/serializers";
import { useCallback, useEffect, useRef, useState } from "react";
import { MdClose } from "react-icons/md";
import tinycolor from "tinycolor2";

import { Dialog, DialogPlacement } from "@megaron/dash-dialog";
import { useDeviceType } from "@megaron/dash-mq";
import { SearchBox } from "@megaron/dash-search";
import { Failure, Ok } from "@megaron/result";

export type SearchDialogResult<T> =
  | T[]
  | Promise<T[]>
  | Promise<Ok<T[]> | Failure<unknown>>
  | Promise<Ok<PaginatedList<T>> | Failure<unknown>>;

export type SearchDialogFunction<T> = (searchText: string) => SearchDialogResult<T>;

type Props<T> = {
  search: (searchText: string) => SearchDialogResult<T>;
  renderItem: (item: T) => React.ReactNode;
  onClose?: () => void;
  placement?: DialogPlacement;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  hideHeader?: boolean;
  hideDefaultResults?: boolean;
  onListItemClick?: (item: T) => void;
  className?: string;
  checkCloseButton?: boolean;
  onSearchTextChange?: (text: string) => void;
  optionalNoResultsText?: string;
  isSearchReady?: boolean;
};

export const SearchDialog = <T,>({
  onClose,
  placement,
  header,
  footer,
  hideHeader,
  renderItem,
  search,
  hideDefaultResults,
  onListItemClick,
  className,
  checkCloseButton,
  onSearchTextChange,
  optionalNoResultsText,
  isSearchReady,
}: Props<T>) => {
  const { isMobile } = useDeviceType();

  const listResultRef = useRef<HTMLDivElement>(null);
  const [isResultListScrolled, setIsResultListScrolled] = useState(false);

  const handleScroll = useCallback(() => {
    if (!listResultRef.current) {
      return;
    }

    if (listResultRef.current?.scrollTop >= 16 && !isResultListScrolled) {
      setIsResultListScrolled(true);
    }

    if (listResultRef.current?.scrollTop < 16 && isResultListScrolled) {
      setIsResultListScrolled(false);
    }
  }, [isResultListScrolled]);

  const theme = useTheme();

  const [isDataFetching, setIsDataFetching] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [options, setOptions] = useState<T[] | undefined>(undefined);

  const areInitialOptionsFetched = useRef(false);

  const handleSearchTextChange = useCallback(
    async (text: string) => {
      onSearchTextChange?.(text);

      setIsDataFetching(true);

      const result = await search(text);

      if (result) {
        setIsDataFetching(false);
      }

      // result = T[]
      if (Array.isArray(result)) {
        setOptions(result);

        return;
      }

      // result = Failure<unknown>
      if (result.isFailure) {
        setOptions([]);

        return;
      }

      // result = Ok<T[]>
      if (Array.isArray(result.value)) {
        setOptions(result.value);

        return;
      }

      // result = Ok<PaginatedList<T>>
      setOptions(result.value.items);
    },
    [search, onSearchTextChange],
  );

  useEffect(() => {
    if (isSearchReady) {
      return;
    }

    if (!areInitialOptionsFetched.current) {
      areInitialOptionsFetched.current = true;
      handleSearchTextChange("");
    }
  }, [handleSearchTextChange, isSearchReady]);

  return (
    <Dialog
      onClose={onClose}
      css={{ width: isMobile ? "100%" : "400px", height: isMobile ? "80%" : "min(600px, 90vh)" }}
      placement={placement || isMobile ? "top" : "center"}
      hideHeader={hideHeader}
      header={header}
      footer={footer}
      className={className}
      checkCloseButton={checkCloseButton}
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "1rem", height: "100%" }}>
        <div css={{ display: "flex", gap: "1rem" }}>
          <SearchBox
            value={searchText}
            onChange={(text) => {
              setSearchText(text);
              handleSearchTextChange(text);
            }}
            variant="grayscale"
            autoFocus
            css={{ width: "100%" }}
            showBorder
          />
          {hideHeader && (
            <button
              onClick={onClose}
              css={{
                background: "none",
                border: "none",
                padding: "0.25rem",
                margin: "-0.25rem",
                cursor: "pointer",
                radius: theme.smallBorderRadius,
                color: theme.colors.primary,
                display: "flex",
                alignItems: "center",
              }}
            >
              <MdClose size="1.5rem" />
            </button>
          )}
        </div>

        <div
          css={{
            height: "1px",
            margin: "0 -1rem",
            background: `${tinycolor(theme.colors.primary).setAlpha(0.1)}`,
            boxShadow: isResultListScrolled ? "0 8px 24px rgba(0, 0, 0)" : "0 8px 24px rgba(0, 0, 0, 0)",
            transition: "box-shadow 0.3s",
          }}
        />

        <div
          css={{
            margin: "-1rem 0",
            width: "100%",
            flex: 1,
            minHeight: 0,
            overflow: "auto",
          }}
          ref={listResultRef}
          onScroll={handleScroll}
        >
          <div
            css={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              padding: "1rem 0",
            }}
          >
            {hideDefaultResults && (!searchText || isDataFetching) ? null : (
              <ResultList
                results={options || []}
                renderItem={renderItem}
                onListItemClick={onListItemClick}
                optionalNoResultsText={optionalNoResultsText}
              />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const ResultList = <T,>({
  results,
  renderItem,
  onListItemClick,
  optionalNoResultsText,
}: {
  results: T[];
  renderItem: (item: T) => React.ReactNode;
  onListItemClick?: (item: T) => void;
  optionalNoResultsText?: string;
}) => {
  const theme = useTheme();

  const [currentResult, setCurrentResult] = useState<number | undefined>(0);

  useEffect(() => {
    if (!results?.length || results.length === 0) {
      setCurrentResult(undefined);
    }

    if (results?.length && results.length > 0 && currentResult === undefined) {
      setCurrentResult(0);
    }
  }, [results, currentResult]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (results?.length && results.length > 0 && currentResult !== undefined) {
        if (e.key === "ArrowDown") {
          e.preventDefault();

          if (currentResult < results.length - 1) {
            document
              .getElementById(`search-result-${currentResult + 1}`)
              ?.scrollIntoView({ behavior: "smooth", block: "center" });

            setCurrentResult(currentResult + 1);
          }
        }

        if (e.key === "ArrowUp") {
          e.preventDefault();

          if (currentResult > 0) {
            document
              .getElementById(`search-result-${currentResult - 1}`)
              ?.scrollIntoView({ behavior: "smooth", block: "center" });

            setCurrentResult(currentResult - 1);
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [results, currentResult]);

  useEffect(() => {
    const handleEnter = (e: KeyboardEvent) => {
      if (e.key === "Enter" && currentResult !== undefined) {
        e.preventDefault();
        const resultElement = document.getElementById(`search-result-${currentResult}`);

        if (resultElement) {
          const linkElement = resultElement.querySelector("a");
          if (linkElement) {
            linkElement.click();
          } else {
            resultElement.click();
          }
        }
      }
    };

    document.addEventListener("keydown", handleEnter);

    return () => document.removeEventListener("keydown", handleEnter);
  }, [currentResult]);

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "0.5rem",
        overflow: "auto",
      }}
    >
      {results?.length && results.length > 0 ? (
        results?.map((result, index) => {
          const isFocused = currentResult === index;

          const cssStyles = {
            width: "100%",
            display: "flex",
            flexShrink: 0,
            borderRadius: theme.smallBorderRadius,
            overflow: "hidden",
            color: theme.colors.border,
            background: "none",
            padding: 0,
            border: isFocused ? `1px solid ${theme.colors.primary}` : `1px solid ${theme.colors.skeleton}`,
            textDecoration: "none",
            outline: "none",
          };

          return (
            <button
              css={cssStyles}
              onClick={() => {
                onListItemClick?.(result);
              }}
              key={`search-result-${index}`}
              id={`search-result-${index}`}
            >
              {renderItem(result)}
            </button>
          );
        })
      ) : (
        <span css={{ color: theme.colors.secondaryText, fontSize: "1rem" }}>
          {optionalNoResultsText ?? "Brak wyników wyszukiwania..."}
        </span>
      )}
    </div>
  );
};
