import { css, useTheme } from "@emotion/react";
import React from "react";
import { MdCheck } from "react-icons/md";

type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
  className?: string;
  customCheckMark?: React.ReactNode;
  label?: string;
};

export const Checkbox: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  const buttonStyles = css({
    display: "flex",
    alignItems: "center",
    borderRadius: theme.extraSmallBorderRadius,
    gap: "0.5rem",
    cursor: "pointer",
    border: "none",
    padding: 0,
    background: "none",
    width: "fit-content",
    '&[data-checked="true"] div div': {
      borderRadius: theme.extraSmallBorderRadius,
      backgroundColor: theme.colors.primary,
      visibility: "visible",
    },
  });

  const checkboxContainerStyles = css({
    position: "relative",
    borderRadius: theme.extraSmallBorderRadius,
    width: "16px",
    height: "16px",
    border: "none",
    backgroundColor: theme.colors.primaryLight,
    div: {
      visibility: "hidden",
    },
  });

  const customCheckMarkStyles = css({
    position: "absolute",
    inset: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10%",
    color: "white",
  });

  return (
    <button
      role="checkbox"
      type="button"
      aria-checked={props.value}
      data-checked={props.value.toString()}
      onClick={() => {
        props.onChange(!props.value);
      }}
      css={buttonStyles}
    >
      <div css={checkboxContainerStyles} className={props.className}>
        <div css={customCheckMarkStyles}>{props.customCheckMark ?? <MdCheck />}</div>
      </div>
      {props.label && (
        <label
          css={{
            fontSize: "0.875rem",
            color: theme.colors.primary,
            fontFamily: theme.displayFontFamily,
            cursor: "pointer",
          }}
        >
          {props.label}
        </label>
      )}
    </button>
  );
};
