import { Failure, Ok } from "@megaron/result";
import { Serializer } from "@megaron/serializers";

export type ResourceId<T extends string = string> = `${T}:${string}`;

export const ResourceId = <T extends string = string>(type: T, id: string): ResourceId<T> => `${type}:${id}`;

export const decodeResourceId = <T extends string = string>(resourceId: ResourceId<T>) => {
  const [type, id] = resourceId.split(":") as [T, string];

  return { type, id };
};

export const resourceIdSerializer = <T extends string = string>(...types: T[]): Serializer<ResourceId<T>> => ({
  serialize: (id) => id,
  deserialize: (raw: unknown) => {
    if (typeof raw !== "string") return Failure("NotAString");
    const [type, id] = raw.split(":") as [T, string];
    if (type === undefined || id === undefined) return Failure("InvalidResourceId");
    if (!types.includes(type)) return Failure("InvalidResourceType");

    return Ok(raw as ResourceId<T>);
  },
});
