import { useEffect, useRef } from "react";

import { customerAnalyticsSortSerializer, customerCategories, customerStatuses } from "@megaron/crm-contracts";
import { useQsFilters } from "@megaron/dash-filters";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { AnalyticsPreset } from "./analyticsPreset";
import { timeUnits } from "./TimeRangeSelect";

export const filtersSerializer = Serializers.object({
  aggName: Serializers.string.nullable().optional(),
  customerId: uuidSerializer.array().optional(),
  isMyRegion: Serializers.boolean.optional(),
  region: Serializers.string.array().optional(),
  category: Serializers.stringOneOf(...customerCategories)
    .array()
    .optional(),
  status: Serializers.stringOneOf(...customerStatuses)
    .array()
    .optional(),
  sort: customerAnalyticsSortSerializer.optional(),
  timeStart: Serializers.datetime.optional(),
  timeEnd: Serializers.datetime.optional(),
  timeUnit: Serializers.stringOneOf(...timeUnits).optional(),
  unitCount: Serializers.integer.optional(),
  selectedTimeName: Serializers.string.optional(),
  productGroups: Serializers.string.array().optional(),
});

export type Filters = SerializerValue<typeof filtersSerializer>;

export const useCustomerAnalyticsGlobalFilters = (
  preset: AnalyticsPreset,
  updatePreset: (newPreset: AnalyticsPreset) => void,
) => {
  const isPresetUpdatedOnMount = useRef(false);

  const defaultFilters: Filters = {
    aggName: undefined,
    sort: preset.sortBy,
    isMyRegion: preset.filters.find((f) => f.isMyRegion !== undefined)?.isMyRegion,
    region: preset.filters.find((f) => f.region)?.region,
    status: preset.filters.find((f) => f.status)?.status,
    category: preset.filters.find((f) => f.category)?.category,
    customerId: preset.filters.find((f) => f.customerId)?.customerId,
    timeStart: preset.filters.find((f) => f.timeStart)?.timeStart,
    timeEnd: preset.filters.find((f) => f.timeEnd)?.timeEnd,
    timeUnit: preset.filters.find((f) => f.timeUnit)?.timeUnit,
    unitCount: preset.filters.find((f) => f.unitCount)?.unitCount,
    selectedTimeName: preset.filters.find((f) => f.selectedTimeName)?.selectedTimeName,
    productGroups: preset.filters.find((f) => f.productGroups)?.productGroups,
  };

  const { filters, setFilters, setFilter } = useQsFilters(filtersSerializer, defaultFilters);

  useEffect(() => {
    if (!isPresetUpdatedOnMount.current) {
      updatePreset({ ...preset, filters: [filters] });
      isPresetUpdatedOnMount.current = true;
    }
  }, [filters, isPresetUpdatedOnMount, preset, updatePreset]);

  return { filters, setFilter, setFilters, isPresetUpdatedOnMount: isPresetUpdatedOnMount.current };
};
