import { customerInteractionDocSerializer } from "@megaron/crm-contracts";
import { dateStringSerializer } from "@megaron/date-string";
import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const customerInteractionActions = {
  customerInteractionQuery: HttpAction({
    path: "/customer/interactions",
    qsSerializer: Serializers.object({
      customerUuid: uuidSerializer,
      ownerEmail: Serializers.string,
      dates: dateStringSerializer.array().asJsonString(),
      limit: Serializers.integer,
    }).partial(),
    valueSerializer: Serializers.paginatedList(customerInteractionDocSerializer),
    requiresAuth: true,
  }),

  customerMentionedInteractionsQuery: HttpAction({
    path: "/customer/mentionedInteractions",
    qsSerializer: Serializers.object({
      limit: Serializers.integer,
    }).partial(),
    valueSerializer: Serializers.paginatedList(customerInteractionDocSerializer),
    requiresAuth: true,
  }),
};
