import { dateStringSerializer } from "@megaron/date-string";
import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const tradeRouteActions = {
  customerTradeRouteCountBulkQuery: HttpAction({
    path: "/crm/tradeRoutes/customerTradeRouteCountBulkQuery",
    method: "post",
    bodySerializer: Serializers.object({
      customerUuids: Serializers.array(uuidSerializer).optional(),
    }),
    valueSerializer: Serializers.array(
      Serializers.object({
        customerUuid: uuidSerializer,
        fixedTradeRouteCount: Serializers.integer,
      }),
    ),
    requiresAuth: true,
  }),

  getScheduledTradeRoute: HttpAction({
    path: "/crm/tradeRoutes/:userId/date/:date/retrieve",
    paramsSerializer: Serializers.object({
      userId: Serializers.integer,
      date: dateStringSerializer,
    }),
    valueSerializer: Serializers.object({
      uuid: uuidSerializer,
      salespersonId: Serializers.integer,
      route: Serializers.object({
        uuid: uuidSerializer,
        customerUuid: uuidSerializer,
        durationMinutes: Serializers.integer,
      }).array(),
      distanceMeters: Serializers.integer,
      fullDurationMinutes: Serializers.integer,
      name: Serializers.string.nullable(),
    }),
  }),
};
