import { MegaraxRole, megaraxRoles } from "@megaron/access-control";
import { SwitchField, TextField } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { SectionHeader } from "@megaron/dash-page";
import { DocsSelectDialog, SelectDialog } from "@megaron/dash-select";
import { useToast } from "@megaron/dash-toast";
import { ResourceId } from "@megaron/resource";

import { Config, useConfig } from "../ConfigContextProvider";

const ENVIRONMENT_TEMPLATE = process.env.NX_PUBLIC_ENVIRONMENT_TEMPLATE;

export const TestUserSettings = () => {
  const { isMobile } = useDeviceType();

  const toast = useToast();

  const { config, setConfig } = useConfig();

  const isDisabled = !!ENVIRONMENT_TEMPLATE;

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <SectionHeader isHr>Test user payload</SectionHeader>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "0.625rem",
          opacity: isDisabled ? 0.5 : 1,
        }}
      >
        <SwitchField
          isDisabled={isDisabled}
          label="Enable test user auth"
          trueLabel="Enable"
          falseLabel="Disable"
          selectedValue={config.testUser.enabled}
          onChange={(value) => {
            if (value) {
              if (!config.testUser.uuid) {
                toast.error("Uuid is required");
                return;
              }
            }

            setConfig({ ...config, testUser: { ...config.testUser, enabled: value } } as Config);
          }}
          fullWidth
        />
        <div css={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr", gap: "0.625rem" }}>
          <TextField
            isDisabled={isDisabled}
            label="Uuid"
            value={config.testUser.uuid || ""}
            onChange={(value) => {
              if (config.testUser.enabled && !value) {
                toast.error("Uuid is required");
                return;
              }

              setConfig({ ...config, testUser: { ...config.testUser, uuid: value } });
            }}
          />
          <TextField
            isDisabled={isDisabled}
            label="Phone number"
            value={config.testUser.phoneNumber || ""}
            onChange={(value) => setConfig({ ...config, testUser: { ...config.testUser, phoneNumber: value } })}
          />
          <TextField
            isDisabled={isDisabled}
            label="Email"
            value={config.testUser.email || ""}
            onChange={(value) => setConfig({ ...config, testUser: { ...config.testUser, email: value } })}
          />
          <SelectDialog
            isDisabled={isDisabled}
            variant="multi-select"
            label="Roles"
            onSelectedChange={(options) =>
              setConfig({
                ...config,
                testUser: { ...config.testUser, roles: options.map((option) => option.value as MegaraxRole) },
              })
            }
            initiallySelectedValues={config.testUser.roles}
            options={megaraxRoles.map((role) => ({ label: role, value: role }))}
          />
          <DocsSelectDialog
            isDisabled={isDisabled}
            variant="multi-select"
            docType={["user-group"]}
            label="Groups"
            onSelectedChange={(options) =>
              setConfig({
                ...config,
                testUser: {
                  ...config.testUser,
                  groups: options.map((option) => ({ id: option.value, name: option.label })),
                },
              })
            }
            initiallySelectedDocIds={config.testUser.groups.map((group) => ResourceId("user-group", group.id))}
          />
        </div>
      </div>
    </div>
  );
};
