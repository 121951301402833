import { DocsSelectDialog, SelectDialog } from "@megaron/dash-select";
import { ResourceId } from "@megaron/resource";
import { CustomerDoc } from "@megaron/docs-contracts";
import { useServiceClient } from "@megaron/react-clients";

type Props = {
  sellToId: string | undefined;
  billToId: string | undefined;
  recipientId: string | undefined;
  handlers: {
    onSellToIdChange: (sellToId: string | undefined) => void;
    onBillToIdChange: (billToId: string | undefined) => void;
    onRecipientIdChange: (recipientId: string | undefined) => void;
  };
};

export const OrderDetailsForm: React.FC<Props> = ({ billToId, sellToId, recipientId, handlers }) => {
  const docs = useServiceClient("docs");

  return (
    <div css={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(360px, 1fr))", gap: "0.625rem" }}>
      <DocsSelectDialog
        variant="single-select"
        docType={["customer"]}
        label="Nabywca"
        initiallySelectedDocIds={sellToId ? [ResourceId("customer", sellToId)] : undefined}
        onSelect={(customer) => {
          handlers.onSellToIdChange((customer as CustomerDoc)?.uuid ?? undefined);
          handlers.onRecipientIdChange((customer as CustomerDoc)?.uuid ?? undefined);

          const payer = (customer as CustomerDoc)?.parents?.find((parent) => parent.relationType === "payer");

          handlers.onBillToIdChange(payer?.uuid ?? undefined);
        }}
      />
      <SelectDialog
        key={`bill-to-select-${billToId}`}
        variant="single-select"
        label="Odbiorca faktury"
        initiallySelectedValues={billToId ? [billToId] : undefined}
        onSelect={(option) => {
          handlers.onBillToIdChange(option?.value ?? undefined);
        }}
        search={async (search) => {
          const results = await docs.searchCustomers({
            searchText: search,
            categories: ["payer"],
            limit: search ? 50 : 10000,
          });

          return results
            ? results.value?.items.map((result) => ({ label: result.name, value: result.uuid })) ?? []
            : [];
        }}
      />
      <DocsSelectDialog
        key={`recipient-select-${recipientId}`}
        variant="single-select"
        docType={["customer"]}
        label="Oddział płatnika"
        initiallySelectedDocIds={recipientId ? [ResourceId("customer", recipientId)] : undefined}
        onSelect={(customer) => {
          handlers.onRecipientIdChange((customer as CustomerDoc)?.uuid ?? undefined);
        }}
      />
    </div>
  );
};
