import { useTheme } from "@emotion/react";
import { IdTokenPayload } from "@megarax/auth-contracts";
import { ScheduleVisitDto } from "@megarax/crm-contracts";
import React from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { Avatar } from "@megaron/dash-avatar";
import { Button } from "@megaron/dash-form";

type Props = {
  visit: ScheduleVisitDto;
  user: IdTokenPayload | undefined;
};

export const NewVisitProposal: React.FC<Props> = ({ visit, user }) => {
  const theme = useTheme();

  const navigate = useNavigate();

  return (
    <div
      css={{
        display: "flex",
        padding: "0.75rem 1rem",
        borderRadius: theme.smallBorderRadius,
        border: "1px solid rgba(0, 0, 0, 0.1)",
        background: "white",
        gap: "1rem",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "0.125rem" }}>
        <span css={{ fontSize: "0.9375rem", fontWeight: 700, color: theme.colors.primary }}>Planowana wizyta</span>
        <div css={{ display: "flex", gap: "0.375rem", alignItems: "center" }}>
          <Avatar src={user?.profilePictureUrl} size="16px" />
          <span css={{ fontSize: "0.875rem", color: "black" }}>
            {user?.firstName} {user?.lastName} - {visit?.startTime.toLocaleDateString()}
          </span>
        </div>
      </div>
      <Button
        onClick={() =>
          navigate(`/crm/customers/id/${visit.customerUuid}/add-interaction?interactionUuid=${visit.uuid}&tab=historia`)
        }
      >
        Utwórz
      </Button>
    </div>
  );
};
