import { currencies, orderStatuses } from "@megaron/crm-contracts";
import { dateStringSerializer } from "@megaron/date-string";
import { baseDocSerializer } from "@megaron/resource";
import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const orderDocSerializer = Serializers.object({
  ...baseDocSerializer("order").fields,
  id: uuidSerializer,
  referenceNumber: Serializers.string,
  type: Serializers.stringOneOf("order", "creditMemo"),
  status: Serializers.stringOneOf(...orderStatuses),
  region: Serializers.string.nullable(),
  regionOwnerEmail: Serializers.string.nullable(),
  sellToId: uuidSerializer.nullable(),
  sellToName: Serializers.string.nullable(),
  billToId: uuidSerializer.nullable(),
  billToName: Serializers.string.nullable(),
  orderDate: dateStringSerializer,
  month: Serializers.month,
  createdAt: Serializers.datetime,
  updatedAt: Serializers.datetime,
  netTotal: Serializers.decimal,
  currency: Serializers.stringOneOf(...currencies),
  profit: Serializers.decimal,
});

export type OrderDoc = SerializerValue<typeof orderDocSerializer>;

export const orderSortFields = [
  "referenceNumber",
  "sellToName",
  "billToName",
  "orderDate",
  "createdAt",
  "updatedAt",
  "profit",
] as const;

export type OrderSortField = (typeof orderSortFields)[number];

export const orderActions = {
  searchOrders: HttpAction({
    path: "/orders",
    qsSerializer: Serializers.object({
      sellToId: uuidSerializer.array().asJsonString(),
      searchText: Serializers.string,
      sort: Serializers.sortFilter(orderSortFields).asJsonString(),
      status: Serializers.stringOneOf(...orderStatuses)
        .array()
        .asJsonString(),
      type: Serializers.stringOneOf("order", "creditMemo").array().asJsonString(),
      region: Serializers.string.array().asJsonString(),
      month: Serializers.month.array().asJsonString(),
      limit: Serializers.integer,
      offset: Serializers.integer,
    }).partial(),
    valueSerializer: Serializers.paginatedList(orderDocSerializer),
    requiresAuth: true,
  }),
};
