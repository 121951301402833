import { useState } from "react";

import { InteractionType, interactionTypes } from "@megaron/crm-contracts";
import { TextField } from "@megaron/dash-form";
import { SelectDialog } from "@megaron/dash-select";

import { AggActionButtons } from "./AggActionButtons";
import { AggDialogProps } from "./SalesAggDialogContent";
import { TimeRangeField } from "./TimeRangeField";
import { TimeUnit } from "./TimeRangeSelect";

type Props = AggDialogProps;

export const InteractionsAggDialogContent: React.FC<Props> = ({
  onClose,
  aggName,
  analyticsPreset,
  onAddAgg,
  onUpdateAgg,
  onDelete,
}) => {
  const agg = analyticsPreset?.aggsOptions.find((agg) => agg.aggName === aggName);

  const [name, setName] = useState(agg?.name ?? "Interakcje");
  const [types, setTypes] = useState<InteractionType[]>(agg?.type === "interactions" ? agg?.interactionType || [] : []);

  const [nameError, setNameError] = useState<string | null>(null);

  const [startTime, setStartTime] = useState<Date | null>(agg?.timeStart || null);
  const [endTime, setEndTime] = useState<Date | null>(agg?.timeEnd || null);
  const [unitCount, setUnitCount] = useState<number | null>(agg?.unitCount || null);
  const [timeUnit, setTimeUnit] = useState<TimeUnit | null>(agg?.timeUnit ?? null);
  const [activeOptionName, setActiveOptionName] = useState<string | "default" | null>(
    agg?.selectedTimeName && agg.selectedTimeName === "default" ? "default" : agg?.selectedTimeName || "default",
  );

  const handleDateChange = (
    startDate: Date | null,
    endDate: Date | null,
    timeUnit: TimeUnit | null,
    unitCount: number | null,
    activeOptionName: string | null,
  ) => {
    setStartTime(startDate);
    setEndTime(endDate);
    setTimeUnit(timeUnit);
    setUnitCount(unitCount);
    setActiveOptionName(activeOptionName);
  };

  const handleSave = () => {
    if (!name) {
      setNameError("Nazwa jest wymagana");
      return;
    }

    const isValidName = aggName === name || !analyticsPreset?.aggsOptions.find((agg) => agg.aggName === name);

    if (!isValidName) {
      setNameError("Istnieje już agregacja o takiej nazwie");
      return;
    }

    setNameError(null);

    const dateDetails =
      activeOptionName === "default"
        ? {
            selectedTimeName: "default",
            timeUnit: undefined,
            unitCount: undefined,
            timeEnd: analyticsPreset?.filters[0]?.timeEnd,
            timeStart: analyticsPreset?.filters[0]?.timeStart,
          }
        : {
            selectedTimeName: activeOptionName || undefined,
            timeUnit: timeUnit || undefined,
            unitCount: unitCount || undefined,
            timeEnd: endTime || undefined,
            timeStart: startTime || undefined,
          };

    if (agg) {
      onUpdateAgg(agg.aggName, {
        ...agg,
        name,
        aggName: name,
        type: "interactions",
        interactionType: types.length > 0 ? types : undefined,
        ...dateDetails,
      });
    } else {
      onAddAgg({
        name,
        type: "interactions",
        interactionType: types.length > 0 ? types : undefined,
        aggName: name,
        ...dateDetails,
      });
    }
    onClose();
  };

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
      <TextField label="Nazwa" value={name} onChange={setName} error={nameError ? "Nazwa jest wymagana" : undefined} />
      <TimeRangeField
        activeOptionName={activeOptionName}
        endDate={endTime}
        startDate={startTime}
        timeUnit={timeUnit}
        unitCount={unitCount}
        onDateChange={handleDateChange}
      />
      <SelectDialog
        label="Typy interakcji"
        variant="multi-select"
        options={interactionTypes.map((type) => ({ label: interactionTypeNameMap[type], value: type }))}
        initiallySelectedValues={types}
        onSelectedChange={(options) => setTypes(options.map((option) => option.value as InteractionType))}
      />
      <AggActionButtons
        onSave={handleSave}
        onDelete={
          onDelete
            ? () => {
                onDelete();
                onClose();
              }
            : undefined
        }
      />
    </div>
  );
};

const interactionTypeNameMap = {
  call: "telefon",
  visit: "wizyta",
  hotline: "infolinia",
  training: "szkolenie",
  other: "inna",
};
