import { useTheme } from "@emotion/react";

import { CustomerAnalyticsFilters, FiltersProps } from "./CustomerAnalyticsFilters";
import { ProductGroupsButton } from "./ProductGroupsButton";
import { TimeRangeButton } from "./TimeRangeButton";

type Props = FiltersProps & {
  handleAddFiltersClick: () => void;
};

export const CustomerAnalyticsFiltersBar: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <div
      css={{
        display: "flex",
        borderRadius: theme.smallBorderRadius,
        background: "white",
        border: "1px solid rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        css={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          padding: "0.5rem 0.75rem",
          overflow: "auto",
          width: "100%",
        }}
      >
        <CustomerAnalyticsFilters {...props} />
      </div>

      <ProductGroupsButton
        selectedProductGroups={props.filters.productGroups ?? []}
        onProductGroupsChange={props.handlers.onProductGroupsChange}
      />

      <TimeRangeButton {...props} />
    </div>
  );
};
