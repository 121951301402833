import { Uuid } from "@megarax/common";
import { DayDto, WorkDayDto } from "@megarax/crm-contracts";
import { isDefined } from "@megarax/utils";
import { addMinutes } from "date-fns";
import _ from "lodash";

import { CustomerInteractionDoc } from "@megaron/crm-contracts";

import { ActivityListVisit } from "./ActivityList";
import { ActivityListEvent } from "./ActivityList/EventCard";

export type CustomerDictionary = {
  [uuid: Uuid]: { name: string } | undefined | null;
};

const emptyVisit = (uuid: Uuid): ActivityListVisit => ({
  uuid,
  type: "visit",
  customerName: null,
  customerUuid: null,
  endTime: null,
  scheduledDurationMinutes: null,
  scheduledStartTime: null,
  startTime: null,
});

export const getActivityListEntries = (
  schedule: DayDto | null,
  interactions: CustomerInteractionDoc[],
  myWorkDay: WorkDayDto,
  customerDictionary: CustomerDictionary,
) => {
  const visitDicaionary: {
    [uuid: Uuid]: ActivityListVisit | undefined;
  } = {};

  interactions.forEach((v) => {
    const existing = visitDicaionary[v.uuid] ?? emptyVisit(v.uuid);
    visitDicaionary[v.uuid] = {
      ...existing,
      customerName: customerDictionary[v.customerUuid]?.name,
      customerUuid: v.customerUuid,
      startTime: v.interactionDate,
      endTime: addMinutes(v.interactionDate, v.durationInMinutes),
    };
  });

  schedule?.visits.forEach((v) => {
    const existing = visitDicaionary[v.uuid] ?? emptyVisit(v.uuid);
    visitDicaionary[v.uuid] = {
      ...existing,
      scheduledStartTime: v.startTime,
      scheduledDurationMinutes: v.durationMinutes,
      customerName: customerDictionary[v.customerUuid]?.name,
      customerUuid: v.customerUuid,
    };
  });

  const events = myWorkDay.events.map<ActivityListEvent>((e) => ({
    type: "event",
    endTime: e.endTime,
    name: e.title,
    startTime: e.startTime,
    description: e.description,
    uuid: e.uuid,
  }));

  const visits = _.values(visitDicaionary).filter(isDefined);

  const entries = _.sortBy([...events, ...visits], (entry) => {
    if (entry.type === "visit" && entry.startTime === null) {
      if (entry.scheduledStartTime === null) return "";
      return `2${entry.scheduledStartTime}`;
    }
    return `1${entry.startTime}`;
  });

  return entries;
};
