import { AuthAttribute, authAttributeSerializer } from "@megaron/access-control";
import { Serializers } from "@megaron/serializers";

import { ResourceId, resourceIdSerializer } from "./ResourceId";

export type BaseDoc<T extends string = string> = {
  docType: T;
  docId: ResourceId<T>;
  authorizedAttributes: AuthAttribute[];
  name: string;
  isArchived: boolean;
  boostedFor: AuthAttribute[];
};

export const baseDocSerializer = <T extends string>(docType: T) =>
  Serializers.object({
    docType: Serializers.stringOneOf(docType),
    docId: resourceIdSerializer(docType),
    authorizedAttributes: authAttributeSerializer.array(),
    boostedFor: authAttributeSerializer.array(),
    isArchived: Serializers.boolean,
    name: Serializers.string,
  });
