import { useTheme } from "@emotion/react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek,
  subMonths,
} from "date-fns";
import { pl } from "date-fns/locale";
/** @jsxImportSource @emotion/react */
import { useContext, useState } from "react";

import { TrainingStatusType } from "@megaron/crm-contracts";
import { Button, Chip } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { IamAuthContext } from "@megaron/iam-auth-react";
import { useClientManager } from "@megaron/react-clients";

import { AddTraining } from "./AddTraining";

export const TrainingHome = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);
  const { isMobile } = useDeviceType();
  const theme = useTheme();
  const auth = useContext(IamAuthContext);

  const startDate = startOfMonth(currentMonth);
  const endDate = endOfMonth(currentMonth);
  const userEmail = auth.iamUser?.email ?? "";

  const trainingQuery = useClientManager("crm").trainingsQuery().useQuery({ startDate, endDate });

  const handlePrevMonth = () => setCurrentMonth(subMonths(currentMonth, 1));
  const handleNextMonth = () => setCurrentMonth(addMonths(currentMonth, 1));

  const daysOfWeek = ["Pn", "Wt", "Śr", "Cz", "Pt", "So", "Nd"];

  const handleDayClick = (day: Date) => {
    setSelectedDay(day);
  };

  const getEventColor = (status: TrainingStatusType) => {
    switch (status) {
      case "planned":
        return "#FEC318";
      case "completed":
        return "#28a745";
      case "canceled":
        return "#dc3545";
      case "gpw":
        return "#007bff";
      case "inCompany":
        return "#6f42c1";
      default:
        return "#ffffff";
    }
  };

  const getMonthInNominalForm = (date: Date) => {
    const monthsInNominal = [
      "styczeń",
      "luty",
      "marzec",
      "kwiecień",
      "maj",
      "czerwiec",
      "lipiec",
      "sierpień",
      "wrzesień",
      "październik",
      "listopad",
      "grudzień",
    ];

    const monthIndex = new Date(date).getMonth();
    return monthsInNominal[monthIndex] + " " + format(date, "yyyy", { locale: pl });
  };

  return (
    <>
      <div
        css={{
          width: "100%",
          maxWidth: "1200px",
          margin: "0 auto",
          padding: "20px",
          marginTop: isMobile ? "40px" : "0",
          fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
          "@media (max-width: 768px)": {
            padding: "2px",
          },
        }}
      >
        <div
          css={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Button onClick={handlePrevMonth} icon={<ChevronLeft />} />

          <h2
            css={{
              margin: 0,
              fontSize: "32px",
              fontWeight: "bold",
              textTransform: "capitalize",
              color: theme.colors.primary,
            }}
          >
            {getMonthInNominalForm(currentMonth)}
          </h2>
          <Button onClick={handleNextMonth} icon={<ChevronRight />} />
        </div>
        <QuerySkeleton height="41px" query={trainingQuery}>
          {(trainings) => (
            <>
              <div
                css={{
                  display: "grid",
                  gridTemplateColumns: "repeat(7, 1fr)",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                  marginBottom: "10px",
                  color: "#8e8e93",
                }}
              >
                {daysOfWeek.map((day) => (
                  <div key={day}>{day}</div>
                ))}
              </div>
              <div
                css={{
                  display: "grid",
                  gridTemplateColumns: "repeat(7, 1fr)",
                  gridAutoRows: "minmax(100px, auto)",
                  gap: "1px",
                  background: "#e6e6e6",
                  "@media (max-width: 768px)": {
                    gridAutoRows: "70px",
                    fontSize: "12px",
                  },
                }}
              >
                {eachDayOfInterval({
                  start: startOfWeek(startOfMonth(currentMonth), { weekStartsOn: 1 }),
                  end: endOfWeek(endOfMonth(currentMonth), { weekStartsOn: 1 }),
                }).map((day) => {
                  const dayEvents = trainings.filter((training) => isSameDay(new Date(training.date), day));

                  return (
                    <div
                      key={day.toISOString()}
                      onClick={() => handleDayClick(day)}
                      css={{
                        backgroundColor: "#ffffff",
                        padding: "5px",
                        position: "relative",
                        border: trainings.some(
                          (training) =>
                            (training.participants?.includes(userEmail) || training.trainers?.includes(userEmail)) &&
                            isSameDay(new Date(training.date), day),
                        )
                          ? `2px solid ${theme.colors.primary}`
                          : "1px solid #e6e6e6",
                        color: isSameMonth(day, currentMonth) ? "#000000" : "#c7c7c7",
                        cursor: "pointer",
                        "@media (max-width: 768px)": {
                          padding: "0",
                        },
                        ":hover": {
                          backgroundColor: "#d3d3d3",
                        },
                      }}
                    >
                      <div
                        css={{
                          fontSize: "14px",
                          fontWeight: isSameDay(day, new Date()) ? "bold" : "normal",
                          backgroundColor: isSameDay(day, new Date()) ? "#ff3b30" : "transparent",
                          color: isSameDay(day, new Date()) ? "#ffffff" : "inherit",
                          borderRadius: "50%",
                          width: "25px",
                          height: "25px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "5px",
                        }}
                      >
                        {format(day, "d")}
                      </div>
                      {dayEvents.length > 0 && (
                        <div
                          css={{
                            position: "absolute",
                            bottom: "5px",
                            left: "5px",
                            right: "5px",
                            fontSize: "12px",
                            display: "flex",
                            gap: "2px",
                            color: "#ff3b30",
                          }}
                        >
                          {isMobile ? (
                            <>
                              {dayEvents.map((event) => (
                                <div
                                  key={event.trainingId}
                                  css={{
                                    background: getEventColor(event.status),
                                    flex: 1,
                                    height: "4px",
                                    borderRadius: "2px",
                                  }}
                                ></div>
                              ))}
                            </>
                          ) : (
                            <>
                              {dayEvents.map((event) => (
                                <Chip key={event.trainingId} color={getEventColor(event.status)}>
                                  {event.name}
                                </Chip>
                              ))}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>

              <div
                css={{
                  marginTop: "20px",
                  display: "flex",
                  fontSize: "14px",
                  gap: "12px",
                  flexWrap: "wrap",
                }}
              >
                <Chip color="#FEC318">Planowane szkolenie</Chip>
                <Chip color="#28a745">Zakończone</Chip>
                <Chip color="#dc3545">Odwołane</Chip>
                <Chip color="#007bff">Szkoła GPW</Chip>
                <Chip color="#6f42c1">Szkolenie w firmie</Chip>
              </div>
            </>
          )}
        </QuerySkeleton>
      </div>

      {selectedDay && (
        <AddTraining
          isOpen={!!selectedDay}
          onClose={() => setSelectedDay(null)}
          queryKey={trainingQuery.key}
          date={selectedDay}
          training={
            selectedDay && trainingQuery.data?.find((training) => isSameDay(new Date(training.date), selectedDay))
          }
        />
      )}
    </>
  );
};
