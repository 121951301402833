import { useTheme } from "@emotion/react";
import { IconType } from "react-icons/lib";
import {
  MdBarChart,
  MdFormatListBulleted,
  MdOutlineMap,
  MdOutlineSave,
  MdOutlineViewList,
  MdUndo,
} from "react-icons/md";
import { Link, useParams } from "react-router-dom-v5-compat";
import tinycolor from "tinycolor2";

import { useDialogRoute } from "@megaron/dash-dialog";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { SavedViewDoc, SavedViewType } from "@megaron/docs-contracts";

import { AllViewsDialog } from "./AllViewsDialog";
import { SaveViewOptions } from "./CustomersViewPage";
import { EditedViewHeader } from "./EditedViewHeader";
import { SaveViewDialog } from "./SaveViewDialog";
import { ViewsMenu } from "./ViewsMenu";

type Props = {
  bookmarkedViews: SavedViewDoc[];
  queryKey: string | string[];
  activeView?: SavedViewDoc;
  handleSave: (options: SaveViewOptions) => void;
  isViewEdited: boolean;
  clearPresetChanges: () => void;
  onArchive: () => void;
  isUserTheOwner?: boolean;
  isSaving?: boolean;
};

export const ViewsBar: React.FC<Props> = ({
  bookmarkedViews,
  handleSave,
  activeView,
  queryKey,
  isUserTheOwner,
  isSaving,
  isViewEdited,
  clearPresetChanges,
  onArchive,
}) => {
  const theme = useTheme();

  const { isMobile, isTablet } = useDeviceType();

  const { viewId } = useParams<{ viewId: string }>();

  const buttonStyles = {
    background: theme.colors.primaryLight,
    color: theme.colors.primary,
    padding: "0.75rem 1rem",
    fontFamily: theme.displayFontFamily,
    fontWeight: 400,
    fontSize: "0.9375rem",
    border: "none",
    borderTopLeftRadius: theme.extraSmallBorderRadius,
    borderTopRightRadius: theme.extraSmallBorderRadius,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    marginRight: "0.5rem",
  };

  const getViewTileStyles = (isActive?: boolean) =>
    ({
      display: "flex",
      alignItems: "center",
      gap: "0.625rem",
      padding: "0.75rem 1rem",
      border: isActive ? `1px solid ${tinycolor(theme.colors.primary).setAlpha(0.4)}` : "1px solid transparent",
      borderBottom: "1px solid transparent",
      borderTopLeftRadius: theme.smallBorderRadius,
      borderTopRightRadius: theme.smallBorderRadius,
      cursor: "pointer",
      zIndex: 2,
      background: isActive ? theme.colors.background : "transparent",
      color: theme.colors.primary,
      fontWeight: isActive ? 700 : 400,
      fontSize: "0.875rem",
      transition: "all 0.15s",
      textWrap: "nowrap",
    } as const);

  const allViewsDialog = useDialogRoute("/all-views", ({ onClose }) => (
    <AllViewsDialog onClose={onClose} queryKey={queryKey} />
  ));

  const saveViewDialog = useDialogRoute("/save-view", ({ onClose }) => (
    <SaveViewDialog
      onClose={onClose}
      onSave={handleSave}
      title={activeView?.title}
      description={activeView?.description}
      isUserTheOwner={isUserTheOwner}
      isSaving={isSaving}
      isPrivateView={activeView?.isPrivate ?? true}
      isNewView={!activeView}
    />
  ));

  return (
    <div
      css={{
        width: "auto",
        position: "fixed",
        height: "62px",
        top: 0,
        zIndex: 1000,
        right: 0,
        left: isTablet || isMobile ? "0" : "260px",
      }}
    >
      <div
        css={{
          display: "flex",
          padding: isMobile
            ? isViewEdited
              ? "0.75rem 1rem"
              : "1rem 1rem 0"
            : isViewEdited
            ? "0.75rem 2rem"
            : "1rem 2rem 0",
          width: "100%",
          background: "white",
          position: "relative",
          height: "100%",
        }}
      >
        <div
          css={{
            position: "absolute",
            height: "1px",
            width: "100%",
            bottom: 0,
            left: 0,
            background: theme.colors.primary,
            opacity: 0.4,
          }}
        />
        {isViewEdited && <EditedViewHeader viewId={viewId} activeView={activeView} />}

        {!isViewEdited && (
          <>
            <Link to={`/crm/customers/new-list`} css={buttonStyles}>
              <MdOutlineViewList size={18} css={{ flexShrink: 0 }} />
            </Link>
            <Link to={`/crm/customers/new-customer-analytics`} css={buttonStyles}>
              <MdBarChart size={18} css={{ flexShrink: 0 }} />
            </Link>
            <Link to={`/crm/customers/new-map`} css={buttonStyles}>
              <MdOutlineMap size={18} css={{ flexShrink: 0 }} />
            </Link>

            <div
              css={{
                display: "flex",
                overflow: "auto",
              }}
            >
              {!isMobile &&
                bookmarkedViews.map((view) => {
                  const isActive = viewId === view.id;

                  const styles = getViewTileStyles(isActive);

                  const Icon = getViewIcon(view.viewType);

                  return (
                    <Link to={`/crm/customers/${view.id}`} key={view.id} css={styles}>
                      <Icon size={14} css={{ flexShrink: 0 }} />
                      {view.name}
                    </Link>
                  );
                })}
            </div>

            <div css={{ display: "flex", gap: "0.5rem", alignItems: "center", marginLeft: "auto" }}>
              <button css={{ ...getViewTileStyles() }} onClick={allViewsDialog.open}>
                <MdFormatListBulleted size={isMobile ? 18 : 14} css={{ flexShrink: 0 }} />
                {!isMobile && "Wszystkie widoki"}
              </button>
              <ViewsMenu
                onArchive={onArchive}
                onEdit={saveViewDialog.open}
                isArchived={!!activeView?.isArchived}
                isDisabled={!activeView}
                isLoading={isSaving}
              />
            </div>
          </>
        )}

        {isViewEdited && (
          <div css={{ display: "flex", alignItems: "center", gap: "0.75rem", marginLeft: "auto" }}>
            <Button icon={<MdUndo />} css={{ alignSelf: "flex-start" }} onClick={clearPresetChanges} variant="outline">
              {!isMobile && "Odrzuć zmiany"}
            </Button>
            <Button
              icon={<MdOutlineSave />}
              css={{ alignSelf: "flex-start", border: `1px solid ${theme.colors.primary}` }}
              onClick={saveViewDialog.open}
            >
              {!isMobile && "Zapisz"}
            </Button>
          </div>
        )}

        {allViewsDialog.element}
        {saveViewDialog.element}
      </div>
    </div>
  );
};

export const getViewIcon = (viewType: SavedViewType): IconType => {
  switch (viewType) {
    case "customerMap":
      return MdOutlineMap;
    case "customerList":
      return MdOutlineViewList;
    case "customerAnalytics":
      return MdBarChart;
    default:
      return MdOutlineViewList;
  }
};
