import { DocsSelectDialog } from "libs/dash/select/src/DocsSelectDialog";
import { useState } from "react";
import { MdCheck } from "react-icons/md";
import { useNavigate } from "react-router-dom-v5-compat";

import { Button, SwitchField, TextField } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { useToast } from "@megaron/dash-toast";
import { ResourceId } from "@megaron/resource";
import { BudgetDoc, BudgetDto } from "@megaron/invoices-contracts";
import { useClientManager } from "@megaron/react-clients";
import { newUuid } from "@megaron/uuid";

type Props =
  | {
      budget: BudgetDoc;
      onRefetch: () => void;
      isNew?: undefined;
    }
  | {
      budget?: undefined;
      onRefetch?: undefined;
      isNew: true;
    };

export const BudgetDetails: React.FC<Props> = ({ budget, onRefetch, isNew }) => {
  const toast = useToast();

  const navigate = useNavigate();

  const { isMobile } = useDeviceType();

  const [name, setName] = useState(budget?.name || "");
  const [budgetNumber, setBudgetNumber] = useState(budget?.budgetNumber || "");
  const [description, setDescription] = useState(budget?.description || "");
  const [alert, setAlert] = useState(budget?.alert || "");
  const [managerGroupIds, setManagerGroupIds] = useState<string[]>(budget?.managerGroupIds || []);
  const [accountant, setAccountant] = useState(budget?.accountant || null);
  const [isArchived, setIsArchived] = useState(budget?.isArchived || false);

  const budgetMutation = useClientManager("invoices").saveBudget().useMutation();

  const handleBudgetSave = async () => {
    if (!name) {
      toast.error("Nazwa jest wymagana.");
      return;
    }

    if (!budgetNumber) {
      toast.error("Numer jest wymagany.");
      return;
    }

    const newBudgetUuid = newUuid();

    const budgetData: BudgetDto = {
      uuid: budget?.uuid || newBudgetUuid,
      name,
      budgetNumber,
      description,
      alert,
      accountant,
      managerGroupIds,
      isArchived,
    };

    budgetMutation.mutate(budgetData, {
      onSuccess: () => {
        toast.info("Pomyślnie zaktualizowano dane.");
        if (isNew) {
          navigate(`/faktury/budzety/${newBudgetUuid}`, { replace: true });
        } else onRefetch();
      },
      onError: (error) => {
        if (error === "BudgetNumberNotUnique") {
          toast.error("Istnieje już budżet o takim numerze.");
          return;
        }

        if (error === "NameNotUnique") {
          toast.error("Istnieje już budżet o takiej nazwie.");
          return;
        }

        toast.error("Nie udało się zaktualizować danych.");
      },
    });
  };

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
      <SwitchField
        selectedValue={!isArchived}
        onChange={(v) => setIsArchived(!v)}
        label="Aktywny?"
        trueLabel="Aktywny"
        falseLabel="Nieaktywny"
      />
      <div css={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr", gap: "0.625rem" }}>
        <TextField label={"Nazwa"} value={name} onChange={setName} />
        <TextField label={"Numer"} value={budgetNumber} onChange={setBudgetNumber} />
        <TextField label={"Opis"} value={description} onChange={setDescription} multiline />
        <TextField label={"Przypomnienie"} value={alert} onChange={setAlert} multiline />
        <DocsSelectDialog
          label="Dysponenci budżetu"
          initiallySelectedDocIds={managerGroupIds.map((id) => ResourceId("user-group", id))}
          onSelectedChange={(options) => {
            setManagerGroupIds(options.map((option) => option.value));
          }}
          docType={["user-group"]}
          variant="multi-select"
        />
        <DocsSelectDialog
          label="Opiekun budżetu"
          initiallySelectedDocIds={accountant ? [ResourceId("user", accountant)] : []}
          onSelect={(option) => setAccountant(option?.value || null)}
          docType={["user"]}
          css={{ color: "black" }}
          variant="single-select"
        />
      </div>
      <Button onClick={handleBudgetSave} css={{ margin: isMobile ? 0 : "1rem 0 0 auto" }}>
        <div css={{ display: "flex", alignItems: "center", gap: "0.25rem" }}>
          <MdCheck size={16} /> Zapisz
        </div>
      </Button>
    </div>
  );
};
