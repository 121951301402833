import { useState } from "react";

import { CustomerCategory, CustomerStatus, RelationType } from "@megaron/crm-contracts";
import {
  BoolFilter,
  FilterList,
  FlagFilter,
  MultiSelectSimpleFilter,
  SelectFilter,
  SingleSelectSimpleFilter,
  Sort,
} from "@megaron/dash-filters";
import { SelectDialog } from "@megaron/dash-select";
import { CustomerSortField } from "@megaron/docs-contracts";
import { useClientManager, useServiceClient } from "@megaron/react-clients";

import { customerCategories } from "./addCustomer/CustomerCategorySection";
import { SortFilterValue } from "./CustomersHome";
import { customerStatusList } from "./StatusField";
import { Filters } from "./useCustomersFilters";

type Props = {
  filters: Filters;
  handlers: {
    onProChange: (isPro: boolean) => void;
    onAffiliateChange: (isAffiliate: boolean) => void;
    onUnassignedChange: (isUnassigned: boolean | undefined) => void;
    onTagsChange: (tags: string[] | undefined) => void;
    onRegionsChange: (regions: string[] | undefined) => void;
    onRelationChange: (relation: { relationType: RelationType; parents: string[] } | undefined) => void;
    onScannedProductsChange: (pos: string[] | undefined) => void;
    onSortChange: (sort: { field: CustomerSortField; order?: "ASC" | "DESC" | undefined } | undefined) => void;
    onStatusChange: (status: CustomerStatus[] | undefined) => void;
    onCategoriesChange: (categories: CustomerCategory[] | undefined) => void;
    onActiveChange: (isActive: boolean | undefined) => void;
    onIsMyRegionChange: (isMyRegion: boolean | undefined) => void;
  };
  hideSortFilter?: boolean;
};

export const sortOptions: { label: string; value: CustomerSortField }[] = [
  { label: "Ostatnia interakcja", value: "lastInteractionTime" },
  { label: "Ostatnia wizyta", value: "lastVisitTime" },
  { label: "Ostatni zakup", value: "lastPurchaseTime" },
  { label: "Całkowite zakupy", value: "totalMassKg" },
  { label: "Zaproszeni wykonawcy", value: "invitedChildrenCount" },
  { label: "Przypisani wykonawcy pod punkt sprzedaży", value: "supplyPointChildrenCount" },
  { label: "Klienci przypisani pod płatnika", value: "payerChildrenCount" },
  { label: "Klienci przypisani pod dystrybutora", value: "distributorChildrenCount" },
  { label: "Klienci przypisani pod sieć", value: "chainChildrenCount" },
];

const relationToCategory: Record<RelationType, CustomerCategory> = {
  invitedBy: "loyaltyUser",
  supplyPoint: "pos",
  chain: "chain",
  payer: "payer",
  distributor: "distributor",
};

const relationTypes: { label: string; value: RelationType }[] = [
  { label: "Zaproszeni przez", value: "invitedBy" },
  { label: "Punkt sprzedaży", value: "supplyPoint" },
  { label: "Płatnik", value: "payer" },
  { label: "Dystrybutor", value: "distributor" },
  { label: "Sieć", value: "chain" },
];

export const CustomersFilters: React.FC<Props> = ({ filters, handlers, hideSortFilter }) => {
  const regionsQuery = useClientManager("crm").regionsQuery().useQuery({});
  const docs = useServiceClient("docs");

  const [isSelectDialogOpen, setSelectDialogOpen] = useState(false);
  const [currentRelationType, setCurrentRelationType] = useState<RelationType | undefined>(
    filters.relation?.relationType,
  );
  const [selectedParents, setSelectedParents] = useState<string[]>(filters.relation?.parents || []);

  const regionsOptions = regionsQuery.data
    ? regionsQuery.data.items.map((region) => ({
        label: region.name,
        value: region.name,
      }))
    : [];

  const handleRelationTypeChange = (value: RelationType | undefined) => {
    if (value) {
      setCurrentRelationType(value);
      setSelectDialogOpen(true);
    } else {
      setCurrentRelationType(undefined);
      setSelectedParents([]);
      handlers.onRelationChange(undefined);
    }
  };

  const handleParentSelectionChange = (options: { label: string; value: string }[]) => {
    const selected = options.map((option) => option.value);
    setSelectedParents(selected);

    if (selected.length > 0) {
      handlers.onRelationChange({
        relationType: currentRelationType!,
        parents: selected,
      });
    } else {
      handlers.onRelationChange(undefined);
    }
  };

  return (
    <FilterList>
      <MultiSelectSimpleFilter
        label={"Status"}
        values={filters.status || []}
        options={customerStatusList}
        onChange={(values: CustomerStatus[]) => handlers.onStatusChange(values.length > 0 ? values : undefined)}
      />
      <MultiSelectSimpleFilter
        label={"Kategoria"}
        values={filters.categories || []}
        options={customerCategories.map((category) => ({ label: category.label, value: category.value }))}
        onChange={(values: CustomerCategory[]) => handlers.onCategoriesChange(values.length > 0 ? values : undefined)}
      />
      {!hideSortFilter && (
        <Sort
          type="button"
          label={"Sortuj"}
          value={{
            field: filters.sort?.field || "",
            order: filters.sort?.order || "",
          }}
          options={sortOptions}
          onChange={(v) => handlers.onSortChange((v as SortFilterValue) ?? undefined)}
        />
      )}
      <FlagFilter
        isActive={filters.isPro}
        label={"Pro"}
        onDelete={() => handlers.onProChange(false)}
        onClick={() => handlers.onProChange(!filters.isPro)}
      />
      <FlagFilter
        isActive={filters.isAffiliate}
        label={"Partner"}
        onDelete={() => handlers.onAffiliateChange(false)}
        onClick={() => handlers.onAffiliateChange(!filters.isAffiliate)}
      />
      <BoolFilter
        label={"Przypisani?"}
        value={filters.isUnassigned}
        onChange={(value) => handlers.onUnassignedChange(value)}
        falseLabel="Tak"
        trueLabel="Nie"
      />
      <BoolFilter
        label={"Aktywny?"}
        value={filters.isActive}
        onChange={(value) => handlers.onActiveChange(value)}
        falseLabel="Nie"
        trueLabel="Tak"
      />
      <SelectFilter
        label="Tagi"
        initiallySelectedValues={filters.tags ?? []}
        onSelectedChange={(options) =>
          handlers.onTagsChange((options.map((option) => option.value) as string[]) ?? undefined)
        }
        search={async (searchtext) => {
          const result = await docs.autocomplete({ doctype: "customer", field: "tags", text: searchtext, limit: 100 });

          return result.value?.map((tag) => ({ label: `#${tag}`, value: tag })) ?? [];
        }}
        variant="multi-select"
      />
      <BoolFilter
        label={"Mój region?"}
        value={filters.isMyRegion}
        onChange={(value) => handlers.onIsMyRegionChange(value)}
        falseLabel="Nie"
        trueLabel="Tak"
        disableDelete
      />
      <div css={{ opacity: filters.isMyRegion ? 0.5 : 1 }}>
        <SelectFilter
          label="Regiony"
          initiallySelectedValues={filters.regions ?? []}
          options={regionsOptions ?? []}
          onSelectedChange={(options) => handlers.onRegionsChange(options.map((option) => option.value) ?? undefined)}
          variant="multi-select"
        />
      </div>
      <MultiSelectSimpleFilter
        label={"Skanowany Produkt"}
        values={filters.scannedProducts || []}
        options={[
          { label: "smigS8", value: "smigS8" },
          { label: "megaronDv21", value: "megaronDv21" },
          { label: "smigC50s", value: "smigC50s" },
          { label: "megaronGv15", value: "megaronGv15" },
          { label: "megaronDv20", value: "megaronDv20" },
          { label: "smigA11", value: "smigA11" },
        ]}
        onChange={(values: string[]) => handlers.onScannedProductsChange(values.length > 0 ? values : undefined)}
      />
      <SingleSelectSimpleFilter
        label={"Relacja z"}
        value={filters.relation?.relationType || undefined}
        options={relationTypes}
        onChange={handleRelationTypeChange}
      />
      {isSelectDialogOpen && currentRelationType && (
        <SelectDialog
          label="Wybierz powiązamych klientów"
          initiallySelectedValues={selectedParents}
          variant="multi-select"
          onSelectedChange={handleParentSelectionChange}
          isOpen={isSelectDialogOpen}
          onClose={() => {
            setSelectDialogOpen(false);
            setSelectedParents([]);
          }}
          noTrigger
          search={async (searchText) => {
            const results = await docs.searchCustomers({
              categories: currentRelationType ? [relationToCategory[currentRelationType]] : [],
              limit: 100,
              searchText: searchText,
            });

            return results
              ? results.value?.items.map((result) => ({ label: result.name, value: result.uuid })) ?? []
              : [];
          }}
        />
      )}
    </FilterList>
  );
};
