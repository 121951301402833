import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const currencies = ["PLN", "EUR", "USD", "GBP"] as const;
export type Currency = (typeof currencies)[number];
export const isCurrency = (x: unknown): x is Currency => currencies.includes(x as Currency);

const priceListDetailSerializer = Serializers.object({
  name: Serializers.string,
  uuid: uuidSerializer,
  currentPrices: Serializers.array(
    Serializers.object({
      gtin: Serializers.string,
      activeFrom: Serializers.datetime,
      netPrice: Serializers.decimal,
    }),
  ),
  currency: Serializers.stringOneOf(...currencies),
});

export type PriceListDetail = SerializerValue<typeof priceListDetailSerializer>;
