import { OrderDraftLine } from "@megarax/crm-contracts";
import { useState } from "react";

import { Uuid } from "@megaron/uuid";

import { OrderDetailsForm } from "./OrderDetailsForm";
import { OrderItemsSection } from "./OrderItemsSection";

type Props = {
  initialSellToId: Uuid | undefined;
  initialBillToId: Uuid | undefined;
  initialRecipientId: Uuid | undefined;
  lines: OrderDraftLine[];
};

export const OrderDraftPageContent: React.FC<Props> = ({
  initialBillToId,
  initialRecipientId,
  initialSellToId,
  lines,
}) => {
  const [sellToId, setSellToId] = useState<string | undefined>(initialSellToId);
  const [billToId, setBillToId] = useState<string | undefined>(initialBillToId);
  const [recipientId, setRecipientId] = useState<string | undefined>(initialRecipientId);
  const [orderLines, setOrderLines] = useState<OrderDraftLine[]>(lines);

  const areItemsDisabled = !sellToId || !billToId || !recipientId;

  return (
    <>
      <OrderDetailsForm
        billToId={billToId}
        sellToId={sellToId}
        recipientId={recipientId}
        handlers={{ onBillToIdChange: setBillToId, onRecipientIdChange: setRecipientId, onSellToIdChange: setSellToId }}
      />
      <OrderItemsSection lines={orderLines} isDisabled={areItemsDisabled} onLinesChange={setOrderLines} />
    </>
  );
};
