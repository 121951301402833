import { Dialog } from "@megaron/dash-dialog";
import { useDeviceType } from "@megaron/dash-mq";

import { AggDialogProps, SalesAggDialogContent } from "./SalesAggDialogContent";

export type TimeRange = "last_30_days" | "last_90_days" | "default";

type Props = AggDialogProps;

export const SalesAggDialog: React.FC<Props> = ({ onClose, ...props }) => {
  const { isMobile } = useDeviceType();

  return (
    <Dialog
      onClose={onClose}
      placement={isMobile ? "top" : "center"}
      css={{ width: isMobile ? "100%" : "400px" }}
      header={props.aggName ? "Dane agregacji" : "Nowy zestaw danych"}
    >
      <SalesAggDialogContent onClose={onClose} {...props} />
    </Dialog>
  );
};
