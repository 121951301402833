import { useTheme } from "@emotion/react";
import * as Popover from "@radix-ui/react-popover";
import { useRef, useState } from "react";

import { SelectListElement } from "@megaron/dash-select";

import { FilterColor, FlagFilter } from "./FlagFilter";

type Props<TOptionValue> = {
  className?: string;
  label: string;
  values: TOptionValue[];
  options: {
    label: string;
    value: TOptionValue;
  }[];
  onChange: (values: TOptionValue[]) => void;
  color?: FilterColor;
  isDisabled?: boolean;
};

export const MultiSelectSimpleFilter = <TOptionValue,>(props: Props<TOptionValue>) => {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  const triggerRef = useRef<HTMLButtonElement>(null);

  const selectedOptions = props.options.filter((o) => props.values?.includes(o.value));

  const isSelected = props.values && props.values.length > 0;
  const label = isSelected
    ? selectedOptions.length === 1
      ? `${props.label}: ${selectedOptions[0].label}`
      : `${props.label} (${selectedOptions?.length})`
    : props.label;

  const handleOptionClick = (value: TOptionValue) => {
    if (props.values?.includes(value)) {
      props.onChange(props.values.filter((v) => v !== value));
    } else {
      props.onChange([...(props.values || []), value]);
    }
  };

  return (
    <Popover.Root open={isOpen} onOpenChange={props.isDisabled ? undefined : setIsOpen}>
      <Popover.Trigger css={{ border: "none", padding: 0, background: "none" }} ref={triggerRef}>
        <FlagFilter
          isActive={isSelected}
          label={label}
          onClick={() => {
            if (props.isDisabled) {
              return;
            }
            setIsOpen(true);
          }}
          onDelete={() => {
            props.onChange([]);
            setIsOpen(false);
          }}
          className={props.className}
          color={props.color}
        />
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content css={{ zIndex: 100 }}>
          <div
            css={{
              margin: "0.25rem",
              display: "flex",
              flexDirection: "column",
              gap: "0.25rem",
              minWidth: `max(${triggerRef.current?.clientWidth}px, 12rem)`,
              padding: "0.5rem",
              borderRadius: theme.smallBorderRadius,
              background: theme.colors.background,
              boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
            }}
          >
            {props.options.map((option) => (
              <SelectListElement
                label={option.label}
                value={option.value}
                onClick={handleOptionClick}
                isSelected={props.values?.includes(option.value)}
                key={option.label}
                showBorder
              />
            ))}
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
