import { baseDocSerializer } from "@megaron/resource";
import { Serializers, SerializerValue } from "@megaron/serializers";

export const linkDocSerializer = Serializers.object({
  ...baseDocSerializer("link").fields,
  url: Serializers.string,
  baseUrl: Serializers.string,
  name: Serializers.string.nullable(),
  type: Serializers.stringOneOf("page", "app"),
  app: Serializers.string.nullable(),
  order: Serializers.integer,
});

export type LinkDoc = SerializerValue<typeof linkDocSerializer>;
