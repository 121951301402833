import { useState } from "react";

import { RelationType } from "@megaron/crm-contracts";
import { TextField } from "@megaron/dash-form";
import { DocsSelectDialog, SelectDialog } from "@megaron/dash-select";
import { useServiceClient } from "@megaron/react-clients";
import { decodeResourceId, ResourceId } from "@megaron/resource";

import { AggActionButtons } from "./AggActionButtons";
import { AggOptions, AnalyticsPreset } from "./analyticsPreset";
import { ProductGroupsField } from "./ProductGroupsField";
import { TimeRangeField } from "./TimeRangeField";
import { TimeUnit } from "./TimeRangeSelect";

export type TimeRange = "last_30_days" | "last_90_days" | "default";

export type AggDialogProps = {
  onClose: () => void;
  analyticsPreset?: AnalyticsPreset;
  onAddAgg: (agg: AggOptions) => void;
  onUpdateAgg: (aggName: string, agg: AggOptions) => void;
  aggName?: string;
  onDelete?: () => void;
};

type Props = AggDialogProps;

export const SalesAggDialogContent: React.FC<Props> = ({
  onClose,
  aggName,
  analyticsPreset,
  onAddAgg,
  onUpdateAgg,
  onDelete,
}) => {
  const agg = analyticsPreset?.aggsOptions.find((agg) => agg.aggName === aggName);

  const docs = useServiceClient("docs");

  const [name, setName] = useState(agg?.name ?? "Sprzedaż");
  const [productGroups, setProductGroups] = useState<string[]>(agg?.type === "sales" ? agg.productGroup || [] : []);
  const [isProductGroupsDefault, setIsProductGroupsDefault] = useState(
    agg?.type === "sales" ? agg?.isProductGroupsDefault ?? true : true,
  );
  const [itemIds, setItemIds] = useState<string[]>(agg?.type === "sales" ? agg.itemId || [] : []);
  const [productIds, setProductIds] = useState<string[]>(agg?.type === "sales" ? agg.productId || [] : []);
  const [related, setRelated] = useState<RelationType | undefined>(agg?.type === "sales" ? agg.related : undefined);

  const [startTime, setStartTime] = useState<Date | null>(agg?.timeStart || null);
  const [endTime, setEndTime] = useState<Date | null>(agg?.timeEnd || null);
  const [unitCount, setUnitCount] = useState<number | null>(agg?.unitCount || null);
  const [timeUnit, setTimeUnit] = useState<TimeUnit | null>(agg?.timeUnit ?? null);
  const [activeOptionName, setActiveOptionName] = useState<string | "default" | null>(
    agg?.selectedTimeName && agg.selectedTimeName === "default" ? "default" : agg?.selectedTimeName || "default",
  );

  const [nameError, setNameError] = useState<string | null>(null);

  const handleProductGroupsChange = (value: string[] | undefined, options?: { isDefault?: boolean }) => {
    setProductGroups(value || []);
    setIsProductGroupsDefault(options?.isDefault || false);
  };

  const handleDateChange = (
    startDate: Date | null,
    endDate: Date | null,
    timeUnit: TimeUnit | null,
    unitCount: number | null,
    activeOptionName: string | null,
  ) => {
    setStartTime(startDate);
    setEndTime(endDate);
    setTimeUnit(timeUnit);
    setUnitCount(unitCount);
    setActiveOptionName(activeOptionName);
  };

  const handleSave = () => {
    if (!name) {
      setNameError("Nazwa jest wymagana");
      return;
    }

    const isValidName = aggName === name || !analyticsPreset?.aggsOptions.find((agg) => agg.aggName === name);

    if (!isValidName) {
      setNameError("Istnieje już agregacja o takiej nazwie");
      return;
    }

    setNameError(null);

    const dateDetails =
      activeOptionName === "default"
        ? {
            selectedTimeName: "default",
            timeUnit: undefined,
            unitCount: undefined,
            timeEnd: analyticsPreset?.filters[0]?.timeEnd,
            timeStart: analyticsPreset?.filters[0]?.timeStart,
          }
        : {
            selectedTimeName: activeOptionName || undefined,
            timeUnit: timeUnit || undefined,
            unitCount: unitCount || undefined,
            timeEnd: endTime || undefined,
            timeStart: startTime || undefined,
          };

    const productGroupsDetails = isProductGroupsDefault
      ? { isProductGroupsDefault: true, productGroup: analyticsPreset?.filters[0]?.productGroups }
      : { productGroup: productGroups, isProductGroupsDefault: false };

    if (agg) {
      onUpdateAgg(agg.aggName, {
        ...agg,
        name,
        aggName: name,
        type: "sales",
        itemId: itemIds.length > 0 ? itemIds : undefined,
        productId: productIds.length > 0 ? productIds : undefined,
        related,
        ...dateDetails,
        ...productGroupsDetails,
      });
    } else {
      onAddAgg({
        name,
        type: "sales",
        aggName: name,
        itemId: itemIds.length > 0 ? itemIds : undefined,
        productId: productIds.length > 0 ? productIds : undefined,
        related,
        ...dateDetails,
        ...productGroupsDetails,
      });
    }
    onClose();
  };

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
      <TextField label="Nazwa" value={name} onChange={setName} error={nameError} />
      <TimeRangeField
        activeOptionName={activeOptionName}
        endDate={endTime}
        startDate={startTime}
        timeUnit={timeUnit}
        unitCount={unitCount}
        onDateChange={handleDateChange}
      />
      <ProductGroupsField
        productGroups={productGroups}
        onProductGroupsChange={handleProductGroupsChange}
        isDefault={isProductGroupsDefault}
      />
      <SelectDialog
        label="Wyroby"
        variant="multi-select"
        search={async (search) => {
          const results = await docs.searchProductIds({ searchText: search, isArchived: false });
          return results.value ? results.value.map((r) => ({ label: r, value: r })) : [];
        }}
        initiallySelectedValues={productIds}
        onSelectedChange={(selected) => setProductIds(selected.map((product) => product.value))}
      />
      <DocsSelectDialog
        label="Jednostki handlowe"
        variant="multi-select"
        docType={["item"]}
        initiallySelectedDocIds={itemIds.map((id) => ResourceId("item", id))}
        onSelectedChange={(options) => setItemIds(options.map((option) => decodeResourceId(option.docId).id))}
      />
      <SelectDialog
        label="Powiązana sprzedaż"
        variant="single-select"
        options={relationOptions}
        initiallySelectedValues={related ? [related] : []}
        onSelect={(selected) => setRelated(selected?.value)}
      />
      <AggActionButtons
        onSave={handleSave}
        onDelete={
          onDelete
            ? () => {
                onDelete();
                onClose();
              }
            : undefined
        }
      />
    </div>
  );
};

const relationOptions: { label: string; value: RelationType }[] = [
  { label: "Zaproszeni wykonawcy", value: "invitedBy" },
  { label: "Sieci", value: "chain" },
  { label: "Dystrybutorzy", value: "distributor" },
  { label: "Płatnicy", value: "payer" },
  { label: "Punkty sprzedaży", value: "supplyPoint" },
];
