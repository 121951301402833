import { OrderDraftLine } from "@megarax/crm-contracts";

import { OrderLineDto } from "@megaron/crm-contracts";
import { Dialog } from "@megaron/dash-dialog";
import { useDeviceType } from "@megaron/dash-mq";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { ItemDialogContent } from "./ItemDialogContent";

type Props = {
  onClose: () => void;
  line: OrderDraftLine;
  onEditLine: (line: OrderLineDto) => void;
};

export const EditItemDialog: React.FC<Props> = ({ onClose, onEditLine, line }) => {
  const { isMobile } = useDeviceType();

  const itemQuery = useClientManager("crm").itemQuery().useQuery({ itemId: line.item.gtin });

  return (
    <Dialog
      onClose={onClose}
      placement={isMobile ? "top" : "center"}
      css={{ width: isMobile ? "100%" : "400px" }}
      header={itemQuery.data?.itemName ?? ""}
    >
      <QuerySkeleton query={itemQuery} height="158px" width="100%">
        {(item) => (
          <ItemDialogContent
            item={item}
            onSave={onEditLine}
            lineId={line.item.gtin as unknown as Uuid | undefined}
            onDelete={() => {}}
          />
        )}
      </QuerySkeleton>
    </Dialog>
  );
};
