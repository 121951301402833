import { useTheme } from "@emotion/react";
import { MdClose } from "react-icons/md";

export type FilterColor = "normal" | "danger" | "light";

type Props = {
  isActive: boolean;
  label: React.ReactNode;
  onDelete?: () => void;
  onClick: () => void;
  className?: string;
  color?: FilterColor;
  isDisabled?: boolean;
};

export const FlagFilter: React.FunctionComponent<Props> = ({
  isActive,
  label,
  onClick,
  className,
  color,
  isDisabled,
  onDelete,
}) => {
  const theme = useTheme();

  const textColor = color === "danger" ? theme.colors.danger : theme.colors.primary;

  return (
    <div
      className={className}
      css={{
        cursor: isDisabled ? "not-allowed" : "pointer",
        display: "inline-flex",
        alignItems: "center",
        fontWeight: isActive && color !== "light" ? 700 : 400,
        color: isActive ? (color === "light" ? theme.colors.primary : "white") : textColor,
        borderRadius: theme.smallBorderRadius,
        background: isActive
          ? color === "light"
            ? theme.colors.primaryLight
            : theme.colors.primary
          : theme.colors.background,
        border: `1px solid ${isActive ? "transparent" : textColor}`,
        fontSize: "0.875rem",
        whiteSpace: "nowrap",
      }}
      color={color}
      onClick={isDisabled ? undefined : onClick}
    >
      <div
        css={{
          padding: "5px 10px",
        }}
      >
        {label}
      </div>
      {isActive && onDelete && (
        <button
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "none",
            color: color === "light" ? theme.colors.primary : "white",
            padding: "0.25rem",
            alignSelf: "stretch",
            backgroundColor: color === "light" ? "transparent" : "rgba(255, 255, 255, 0.15)",
            margin: 0,
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <MdClose size={14} />
        </button>
      )}
    </div>
  );
};
