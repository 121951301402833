import axios, { AxiosError } from "axios";
import _ from "lodash";
import { compile } from "path-to-regexp";
import { BehaviorSubject, Observable, Subject } from "rxjs";

import { EventStreamContentType, fetchEventSource } from "@megaron/fetch-event-source";
import { HttpAction, HttpService, HttpSseAction } from "@megaron/http-service";
import { logger } from "@megaron/logger";
import { Failure, Ok } from "@megaron/result";
import { sanitizeError } from "@megaron/utils";
import { newUuid } from "@megaron/uuid";

export type CommonErrors = "Unauthorized" | "InternalServerError" | "ConnectionError";

export type HttpServiceClient<TSchema extends HttpService> = {
  [name in keyof TSchema]: TSchema[name] extends HttpAction<
    infer TValue,
    infer TError,
    infer TBody,
    infer TParams,
    infer TQs,
    infer TAuth
  >
    ? HttpServiceClientAction<TValue, TError | CommonErrors, TBody, TParams, TQs>
    : TSchema[name] extends HttpSseAction<
        infer TValue,
        infer TError,
        infer TBody,
        infer TParams,
        infer TQs,
        infer TAuth
      >
    ? HttpServiceClientSseAction<TValue, TError | CommonErrors, TBody, TParams, TQs>
    : never;
};

type ActionOptions<TBody, TParams, TQs> = TBody & TParams & TQs;

export type HttpServiceClientAction<TValue, TError, TBody, TParams, TQs> = (
  options: ActionOptions<TBody, TParams, TQs>,
) => HttpServiceClientActionResult<TValue, TError>;

type HttpServiceClientActionResult<TValue, TError> = Promise<Ok<TValue> | Failure<TError>> & {
  unwrap: () => Promise<TValue>;
};

export type HttpServiceClientSseAction<TValue, TError, TBody, TParams, TQs> = (
  options: ActionOptions<TBody, TParams, TQs>,
  handler?: (result: Ok<TValue> | Failure<TError>) => void,
) => {
  stop: () => void;
  observable: Observable<Ok<TValue> | Failure<TError>>;
  statusObservable: Observable<boolean>;
  getStatus: () => boolean;
};

class RetriableError extends Error {}
class FatalError extends Error {}

export const HttpServiceClient = <TSchema extends HttpService>(
  getHost: string | (() => Promise<string> | string),
  schema: TSchema,
  getAuthHeader?: () => Promise<string | undefined | null> | string | undefined | null,
  testArgument?: string,
): HttpServiceClient<TSchema> => {
  const clientSessionUuid = newUuid();

  return _.mapValues(schema, (actionSchema, actionName) => (options: any, handler?: any) => {
    const serializedParams = actionSchema.paramsSerializer?.serialize(options) ?? undefined;
    const serializedQuery = actionSchema.qsSerializer?.serialize(options) ?? undefined;
    const serializedBody = actionSchema.bodySerializer?.serialize(options) ?? undefined;

    const url = compile(actionSchema.path)(serializedParams ?? {});

    const getHeaders = async () => {
      const authHeader = getAuthHeader && (await getAuthHeader());

      const headers: Record<string, string> = {
        "Client-Session-Uuid": clientSessionUuid,
      };

      if (authHeader) headers["Authorization"] = authHeader;
      if (testArgument) headers["Test-Argument"] = testArgument;
      return headers;
    };
    const host = typeof getHost === "function" ? getHost() : getHost;

    if (!actionSchema.sse) {
      const resultPromise = new Promise<Ok<any> | Failure<any>>(async (resolve) => {
        try {
          const r = await axios.request({
            method: actionSchema.method,
            url,
            baseURL: await host,
            data: serializedBody,
            params: serializedQuery,
            headers: await getHeaders(),
          });

          if (r.data.isOk) {
            const valueDeserializationResult = actionSchema.valueSerializer.deserialize(r.data.value);
            if (valueDeserializationResult.isFailure) {
              logger.error({
                message: `Failed to deserialize value in ${actionName}`,
                serializerError: valueDeserializationResult.error,
                value: r.data.value,
              });
              resolve(Failure("ConnectionError"));
            }

            resolve(valueDeserializationResult);
          } else {
            const errorDeserializationResult = actionSchema.errorSerializer.deserialize(r.data.error);
            if (errorDeserializationResult.isFailure) {
              logger.error({
                message: `Failed to deserialize error in ${actionName}`,
                serializerError: errorDeserializationResult.error,
                error: r.data.error,
              });
              resolve(Failure("ConnectionError"));
            }

            resolve(Failure(errorDeserializationResult.value));
          }
        } catch (e) {
          if ((e as AxiosError)?.response?.status === 401) {
            logger.debug({ message: "Client request unauthenticated", action: actionName, error: sanitizeError(e) });
            resolve(Failure("Unauthorized"));
          } else if ((e as AxiosError)?.response?.status === 403) {
            logger.debug({ message: "Client request permission denied", error: sanitizeError(e) });
            resolve(Failure("Unauthorized"));
          } else {
            logger.debug({ message: "Client request failed", error: sanitizeError(e) });
            resolve(Failure("ConnectionError"));
          }
        }
      });
      const unwrap = async () => {
        const result = await resultPromise;
        if (result.isFailure) {
          const msg = "Failed to unwrap result of " + actionName;
          logger.error(msg, { error: result.error, action: actionName });
          throw new Error(msg);
        }
        return result.value;
      };

      (resultPromise as HttpServiceClientActionResult<any, any>).unwrap = unwrap;

      return resultPromise;
    }

    // SSE
    const statusSubject = new BehaviorSubject<boolean>(false);
    const subject = new Subject();
    if (handler) subject.subscribe(handler);

    const ctrl = new AbortController();

    getHeaders()
      .then(async (headers) => {
        await fetchEventSource(`${await host}${url}`, {
          headers,
          signal: ctrl.signal,
          fetch,
          onmessage: (e) => {
            if (!statusSubject.getValue()) statusSubject.next(true);
            const data = JSON.parse(e.data);
            if (data.isOk) {
              const valueDeserializationResult = actionSchema.valueSerializer.deserialize(data.value).assertOk();
              subject.next(valueDeserializationResult);
            } else {
              const errorDeserializationResult = actionSchema.errorSerializer.deserialize(data.error).assertOk();
              subject.next(Failure(errorDeserializationResult.value));
            }
          },
          async onopen(response) {
            if (!statusSubject.getValue()) statusSubject.next(true);
            if (response.ok && response.headers.get("content-type") === EventStreamContentType) {
              return;
            } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
              throw new FatalError();
            } else {
              throw new RetriableError();
            }
          },
          onclose() {
            if (statusSubject.getValue()) statusSubject.next(false);

            throw new RetriableError();
          },
          onerror(err) {
            if (statusSubject.getValue()) statusSubject.next(false);
            if (err instanceof FatalError) {
              throw err;
            } else {
            }
          },
        });
      })
      .catch((e) => {
        logger.debug(sanitizeError(e));
        subject.error(Failure("ConnectionError"));
        throw e;
      });

    return {
      stop: async () => {
        ctrl.abort();
      },
      statusObservable: statusSubject.asObservable(),
      getStatus: () => statusSubject.getValue(),
      observable: subject.asObservable(),
    };
  }) as any;
};
