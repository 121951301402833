import { Failure, Ok } from "@megaron/result";

import { SerializerExtensions, serializerExtensions } from "../extensions";
import { Serializer } from "../serializer";

export type Gtin = string & { __brand: "gtin" };

export const validateGtin = (str: string) => {
  if (str.match(/^\d+$/) === null) return Failure("InvalidGtin");
  return Ok<Gtin>(str as Gtin);
};

export const gtin: Serializer<Gtin> & SerializerExtensions<Gtin> = {
  serialize: (gtin: string) => gtin,
  deserialize: (raw: unknown) => {
    if (typeof raw !== "string") return Failure("Gtin must be a string");

    const result = validateGtin(raw);
    if (result.isFailure) {
      return Failure("InvalidGtin");
    }

    return Ok(result.value);
  },

  ...serializerExtensions(),
};
