import { useTheme } from "@emotion/react";
import { MdAdd, MdLan, MdOutline3P, MdShoppingCart } from "react-icons/md";

import { AggType, CustomerAnalyticsSortField, CustomerStatus } from "@megaron/crm-contracts";
import { BoolFilter, DocsSelectFilter, MultiSelectSimpleFilter, SelectFilter } from "@megaron/dash-filters";
import { CustomerDoc } from "@megaron/docs-contracts";
import { useClientManager } from "@megaron/react-clients";
import { ResourceId } from "@megaron/resource";

import { customerCategories } from "../addCustomer/CustomerCategorySection";
import { customerStatusList } from "../StatusField";
import { FiltersProps } from "./CustomerAnalyticsFilters";

export type CustomerAnalyticsSort = { field: CustomerAnalyticsSortField; order: "ASC" | "DESC" | undefined };

export const FiltersAndAggList: React.FC<
  FiltersProps & {
    handleAddFiltersClick: () => void;
    disableTileClick?: boolean;
  }
> = ({ filters, handlers, aggs, handleAddFiltersClick, disableTileClick }) => {
  const theme = useTheme();

  const regionsQuery = useClientManager("crm").regionsQuery().useQuery({});

  const regionsOptions = regionsQuery.data
    ? regionsQuery.data.items.map((region) => ({
        label: region.name,
        value: region.name,
      }))
    : [];

  return (
    <>
      {aggs.map((agg) => (
        <AggFilterTile key={agg.name} name={agg.name} type={agg.type} onClick={agg.onClick} />
      ))}
      <BoolFilter
        label={"Mój region?"}
        value={filters.isMyRegion}
        onChange={(value) => handlers.onIsMyRegionChange(value)}
        falseLabel="Nie"
        trueLabel="Tak"
        color="light"
        disableDelete
      />
      {filters.status && filters.status.length > 0 && (
        <MultiSelectSimpleFilter
          label={"Status"}
          values={filters.status || []}
          options={customerStatusList}
          color="light"
          onChange={(values: CustomerStatus[]) => handlers.onStatusChange(values.length > 0 ? values : undefined)}
          isDisabled={disableTileClick}
        />
      )}
      {filters.category && filters.category.length > 0 && (
        <MultiSelectSimpleFilter
          label={"Kategoria"}
          color="light"
          values={filters.category || []}
          options={customerCategories.map((category) => ({ label: category.label, value: category.value }))}
          onChange={(values) => handlers.onCategoriesChange(values.length > 0 ? values : undefined)}
          isDisabled={disableTileClick}
        />
      )}
      {filters.region && filters.region.length > 0 && (
        <div css={{ opacity: filters.isMyRegion ? 0.5 : 1 }}>
          <SelectFilter
            label="Regiony"
            initiallySelectedValues={filters.region ?? []}
            options={regionsOptions ?? []}
            onSelectedChange={(options) =>
              handlers.onRegionsChange(options.length ? options.map((option) => option.value) : undefined)
            }
            variant="multi-select"
            color="light"
            isDisabled={disableTileClick}
          />
        </div>
      )}
      {filters.customerId && filters.customerId.length > 0 && (
        <DocsSelectFilter
          docType={["customer"]}
          label="Klienci"
          initiallySelectedDocIds={filters.customerId.map((id) => ResourceId("customer", id)) ?? []}
          onSelectedChange={(options) =>
            handlers.onCustomerIdChange(
              options.length ? (options as unknown as CustomerDoc[]).map((option) => option.uuid) : undefined,
            )
          }
          variant="multi-select"
          color="light"
          isDisabled={disableTileClick}
        />
      )}
      <button
        css={{
          padding: "0.25rem 0.5rem",
          borderRadius: theme.smallBorderRadius,
          background: theme.colors.primaryLight,
          color: theme.colors.primary,
          border: "none",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          minHeight: "32px",
          alignSelf: "stretch",
        }}
        onClick={handleAddFiltersClick}
      >
        <MdAdd size={16} />
      </button>
    </>
  );
};

const AggFilterTile: React.FC<{
  name: string;
  type: AggType;
  onClick: () => void;
}> = ({ name, type, onClick }) => {
  const theme = useTheme();

  return (
    <button
      css={{
        display: "flex",
        alignItems: "center",
        gap: "0.375rem",
        borderRadius: theme.smallBorderRadius,
        padding: "5px 10px",
        background: theme.colors.primary,
        color: "white",
        cursor: "pointer",
        fontSize: "0.875rem",
        whiteSpace: "nowrap",
        border: "none",
      }}
      onClick={onClick}
    >
      <AggIcon type={type} />
      {name}
    </button>
  );
};

const AggIcon: React.FC<{ type: AggType }> = ({ type }) => {
  if (type === "interactions") {
    return <MdOutline3P size={12} />;
  }

  if (type === "sales") {
    return <MdShoppingCart size={12} />;
  }

  return <MdLan size={12} />;
};

export const sortOptions: { label: string; value: CustomerAnalyticsSortField }[] = [
  { label: "Całkowity potencjał", value: "absolutePotential" },
  { label: "Koszt", value: "cost" },
  { label: "Pierwsza interakcja", value: "firstInteractionTime" },
  { label: "Pierwsza sprzedaż", value: "firstSaleTime" },
  { label: "Ilość grup produktowych", value: "groupCount" },
  { label: "Realizacja potencjału grup", value: "groupRealization" },
  { label: "Ilość interakcji", value: "interactionCount" },
  { label: "Ilość Jednostek handlowych", value: "itemCount" },
  { label: "Ostatnia interakcja", value: "lastInteractionTime" },
  { label: "Ostatnia sprzedaż", value: "lastSaleTime" },
  { label: "Nazwa", value: "name" },
  { label: "Ilość zamówień", value: "orderCount" },
  { label: "Realizacja potencjału", value: "potentialRealization" },
  { label: "Ilość produktów", value: "productCount" },
  { label: "Marża", value: "profit" },
  { label: "Ilość grup ze zrealizowanym potencjałem", value: "realizedGroupCount" },
  { label: "Wielkość", value: "size" },
  { label: "Ilość grup z niezrealizowanym potencjałem", value: "unrealizedGroupCount" },
  { label: "Wartość", value: "value" },
];
