import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { customerCategories } from "../categories";
import { customerStatuses } from "../customers";
import { interactionTypes } from "../history";
import { relationTypes } from "../relation";
import { customColumnOptionsSerializer, customColumnValueSerializer } from "./customColumn";

export const aggTypes = ["sales", "interactions", "parentCustomers", "childCustomers"] as const;
export type AggType = (typeof aggTypes)[number];

export const salesAggOptionsSerializer = Serializers.object({
  type: Serializers.stringOneOf("sales"),
  ...Serializers.object({
    itemId: Serializers.string.array(),
    productId: Serializers.string.array(),
    productGroup: Serializers.string.array(),
    timeStart: Serializers.datetime,
    timeEnd: Serializers.datetime,
    related: Serializers.stringOneOf(...relationTypes),
  }).partial().fields,
});

export type SalesAggOptions = SerializerValue<typeof salesAggOptionsSerializer>;

export const interactionsAggOptionsSerializer = Serializers.object({
  type: Serializers.stringOneOf("interactions"),
  ...Serializers.object({
    interactionType: Serializers.stringOneOf(...interactionTypes).array(),
    timeStart: Serializers.datetime,
    timeEnd: Serializers.datetime,
  }).partial().fields,
});

export type InteractionsAggOptions = SerializerValue<typeof interactionsAggOptionsSerializer>;

export const aggOptionsSerializer = Serializers.first(salesAggOptionsSerializer, interactionsAggOptionsSerializer);

export type AggOptions = SerializerValue<typeof aggOptionsSerializer>;

const GroupSummarySerializer = Serializers.object({
  groupName: Serializers.string.nullable(),
  profit: Serializers.decimal,
  nominalPotential: Serializers.decimal,
  absolutePotential: Serializers.decimal,
  potentialRealization: Serializers.decimal,
});

export type GroupSummary = SerializerValue<typeof GroupSummarySerializer>;

export const salesAggValueSerializer = Serializers.object({
  type: Serializers.stringOneOf("sales"),
  profit: Serializers.decimal,
  orderCount: Serializers.integer,
  size: Serializers.decimal,
  value: Serializers.decimal,
  cost: Serializers.decimal,
  lastSaleTime: Serializers.datetime.nullable(),
  firstSaleTime: Serializers.datetime.nullable(),
  productCount: Serializers.integer,
  timeScale: Serializers.decimal,
  groups: Serializers.string.nullable().array(),
  itemCount: Serializers.integer,
  transactionCount: Serializers.integer,
  absolutePotential: Serializers.decimal,
  nominalPotential: Serializers.decimal,
  potentialRealization: Serializers.decimal,
  groupCount: Serializers.integer,
  potentialGroupCount: Serializers.integer,
  realizedGroupCount: Serializers.integer,
  unrealizedGroupCount: Serializers.integer,
  groupRealization: Serializers.decimal,
  groupSummary: GroupSummarySerializer.array(),
});

const interactionsAggValuesSerializer = Serializers.object({
  type: Serializers.stringOneOf("interactions"),
  interactionCount: Serializers.integer,
  firstInteractionTime: Serializers.datetime.nullable(),
  lastInteractionTime: Serializers.datetime.nullable(),
});

export const aggValueSerializer = Serializers.first(salesAggValueSerializer, interactionsAggValuesSerializer);

export type AggValue = SerializerValue<typeof aggValueSerializer>;

export const customerAnalyticsEntrySerializer = Serializers.object({
  uuid: uuidSerializer,
  name: Serializers.string,
  firstName: Serializers.string,
  lastName: Serializers.string,
  phoneNumber: Serializers.string,
  email: Serializers.string.nullable(),
  categories: Serializers.stringOneOf(...customerCategories).array(),
  status: Serializers.stringOneOf(...customerStatuses),
  region: Serializers.string.nullable(),
  aggs: Serializers.record(aggValueSerializer),
  customColumns: Serializers.record(customColumnValueSerializer),
});

export type CustomerAnalyticsEntry = SerializerValue<typeof customerAnalyticsEntrySerializer>;

export const customerAnalyticsFiltersSerializer = Serializers.object({
  aggName: Serializers.string.nullable(),
  customerId: uuidSerializer.array(),
  searchText: Serializers.string,
  region: Serializers.string.array(),
  category: Serializers.stringOneOf(...customerCategories).array(),
  status: Serializers.stringOneOf(...customerStatuses).array(),
  limit: Serializers.integer,
  offset: Serializers.integer,
}).partial();

export type CustomerAnalyticsFilterset = SerializerValue<typeof customerAnalyticsFiltersSerializer>;

export const customerAnalyticsSortFields = [
  "name",
  "profit",
  "orderCount",
  "size",
  "value",
  "cost",
  "lastSaleTime",
  "firstSaleTime",
  "productCount",
  "absolutePotential",
  "itemCount",
  "potentialRealization",
  "interactionCount",
  "firstInteractionTime",
  "lastInteractionTime",
  "groupCount",
  "realizedGroupCount",
  "potentialGroupCount",
  "unrealizedGroupCount",
  "groupRealization",
] as const;

export type CustomerAnalyticsSortField = (typeof customerAnalyticsSortFields)[number];

export const customerAnalyticsSortSerializer = Serializers.object({
  agg: Serializers.string.nullable(),
  customColumn: Serializers.string.nullable(),
  fieldName: Serializers.stringOneOf(...customerAnalyticsSortFields),
  order: Serializers.stringOneOf("ASC", "DESC"),
});

export type CustomerAnalyticsSort = SerializerValue<typeof customerAnalyticsSortSerializer>;

const customerAnalyticsOptionsSerializer = Serializers.object({
  aggs: Serializers.record(aggOptionsSerializer),
  customColumns: Serializers.record(customColumnOptionsSerializer),
  sortBy: customerAnalyticsSortSerializer,
  filters: customerAnalyticsFiltersSerializer.array(),
}).partial();

export type CustomerAnalyticsOptions = SerializerValue<typeof customerAnalyticsOptionsSerializer>;

export const customerAnalyticsActions = {
  customerAnalyticsQuery: HttpAction({
    path: "/customers/analytics",
    method: "post",
    bodySerializer: customerAnalyticsOptionsSerializer,
    valueSerializer: customerAnalyticsEntrySerializer.array(),
    requiresAuth: true,
  }),
};

export * from "./customColumn";
