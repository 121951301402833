import { SaveGroupBody } from "@megarax/auth-contracts";
import React from "react";
import { MdCheck } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom-v5-compat";

import { Button, TextField } from "@megaron/dash-form";
import { Page, PageHeader, SectionHeader } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useClientManager } from "@megaron/react-clients";
import { newUuid, Uuid } from "@megaron/uuid";

import { getGroupPath } from "./paths";

export const GroupPage = () => {
  const id = useParams<{ id: Uuid }>().id!;

  const groupQuery = useClientManager("auth").groupQuery().useQuery({ id });
  const saveMutation = useClientManager("auth")
    .saveGroup()
    .useMutation({
      onSuccess: () => {
        groupQuery.refetch();
      },
    });

  return (
    <Page>
      <QuerySkeleton query={groupQuery}>
        {(group) => (
          <>
            <PageHeader>{group.name}</PageHeader>
            <div css={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "2rem" }}>
              <GroupFrom
                css={{ flexGrow: 1, minWidth: "300px" }}
                group={group}
                onSave={(b) => saveMutation.mutate({ ...b, id })}
                isLoading={saveMutation.isLoading}
              />
              <div>
                <SectionHeader css={{ marginBottom: "0.5rem" }}>Użytkownicy</SectionHeader>
                <div css={{ display: "flex", flexDirection: "column" }}>
                  {group.accounts.map((account) => (
                    <Link to={`/iam/accounts/email/${account.email}`} key={account.email}>
                      {account.email}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </QuerySkeleton>
    </Page>
  );
};

export const NewGroupPage = () => {
  const id = newUuid();

  const navigate = useNavigate();

  const saveMutation = useClientManager("auth")
    .saveGroup()
    .useMutation({
      onSuccess: () => {
        navigate(getGroupPath(id));
      },
    });

  return (
    <Page>
      <PageHeader>Nowe stanowisko</PageHeader>
      <GroupFrom
        group={{
          id,
          isActive: true,
          name: "",
          roles: [],
        }}
        onSave={(b) => saveMutation.mutate({ ...b, id })}
        isLoading={saveMutation.isLoading}
      />
    </Page>
  );
};

export const GroupFrom: React.FunctionComponent<{
  group: SaveGroupBody & { id: Uuid };
  onSave: (group: SaveGroupBody) => void;
  isLoading: boolean;
  className?: string;
}> = (props) => {
  const [form, setForm] = React.useState<SaveGroupBody>(props.group);

  const setField =
    <TKey extends keyof SaveGroupBody>(field: TKey) =>
    (value: SaveGroupBody[TKey]) => {
      setForm({ ...form, [field]: value });
    };

  const [rolesText, setRolesText] = React.useState(form.roles.join("\n"));

  const setRoles = (value: string) => {
    const roles = value.split("\n").filter((role) => role.trim() !== "");
    setRolesText(value);
    setForm({ ...form, roles: roles });
  };

  return (
    <div css={{ maxWidth: "500px" }} className={props.className}>
      <TextField value={form.name} onChange={setField("name")} label="Nazwa" />
      <TextField
        css={{ "& textarea": { minHeight: "400px" } }}
        value={rolesText}
        onChange={setRoles}
        label="Role"
        multiline
      />
      <div css={{ marginTop: "1rem", display: "flex" }}>
        <Button
          icon={<MdCheck />}
          isLoading={props.isLoading}
          css={{ marginLeft: "auto" }}
          onClick={() => props.onSave(form)}
        >
          Zapisz
        </Button>
      </div>
    </div>
  );
};
