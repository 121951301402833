import Decimal from "decimal.js";

import { AggType, CustomerAnalyticsEntry, CustomerAnalyticsSortField } from "@megaron/crm-contracts";

import { customerStatusList } from "../StatusField";
import { CustomColumnType } from "./analyticsCustomColumns";
import { AnalyticsPresetColumn } from "./analyticsPreset";
import { GroupPotentialRealization } from "./GroupPotentialRealization";
import { PotentialRealization } from "./PotentialRealization";
import { SalesHistory } from "./SalesHistory";

export type ColumnType =
  | "profit"
  | "potential"
  | "potential-realization"
  | "groups-potential-realization"
  | "interaction-count"
  | "region"
  | "status"
  | "first-sale-time"
  | "last-sale-time"
  | "product-count"
  | "order-count"
  | "first-interaction-time"
  | "last-interaction-time"
  | "sales-history"
  | "profit-per-interaction"
  | "order-per-interaction"
  | "potential-per-interaction";

export const analyticsColumns: {
  columnType: ColumnType;
  aggType: AggType | null;
  columnName: string;
  sortField?: CustomerAnalyticsSortField;
  customColumnType?: CustomColumnType;
}[] = [
  { aggType: "sales", columnType: "profit", columnName: "M1 STD", sortField: "profit" },
  { aggType: "sales", columnType: "potential", columnName: "Potencjał", sortField: "absolutePotential" },
  {
    aggType: "sales",
    columnType: "potential-realization",
    columnName: "Realizacja potencjału",
    sortField: "potentialRealization",
  },
  { aggType: "sales", columnType: "groups-potential-realization", columnName: "Potencjał grup" },
  { aggType: "sales", columnType: "first-sale-time", columnName: "Pierwsze zamówienie", sortField: "firstSaleTime" },
  { aggType: "sales", columnType: "last-sale-time", columnName: "Ostatnie zamówienie", sortField: "lastSaleTime" },
  { aggType: "sales", columnType: "product-count", columnName: "Liczba produktów", sortField: "productCount" },
  { aggType: "sales", columnType: "order-count", columnName: "Liczba zamówień", sortField: "orderCount" },
  {
    aggType: "interactions",
    columnType: "interaction-count",
    columnName: "Liczba interakcji",
    sortField: "interactionCount",
  },
  {
    aggType: "interactions",
    columnType: "first-interaction-time",
    columnName: "Pierwsza interakcja",
    sortField: "firstInteractionTime",
  },
  {
    aggType: "interactions",
    columnType: "last-interaction-time",
    columnName: "Ostatnia interakcja",
    sortField: "lastInteractionTime",
  },
  { aggType: null, columnType: "region", columnName: "Region" },
  { aggType: null, columnType: "status", columnName: "Status" },
  { aggType: null, columnType: "sales-history", columnName: "Historia sprzedaży", customColumnType: "salesHistory" },
  {
    aggType: null,
    columnType: "profit-per-interaction",
    columnName: "Marża na interakcję",
    customColumnType: "salesPerInteraction",
    sortField: "profit",
  },
  {
    aggType: null,
    columnType: "order-per-interaction",
    columnName: "Zamówienia na interakcję",
    customColumnType: "salesPerInteraction",
    sortField: "orderCount",
  },
  {
    aggType: null,
    columnType: "potential-per-interaction",
    columnName: "Potencjał na interakcję",
    customColumnType: "salesPerInteraction",
    sortField: "absolutePotential",
  },
];

export const analyticsColumnsTypes = analyticsColumns.map((column) => column.columnType);

export const ColumnCellContent: React.FC<{
  componentType: ColumnType;
  analyticsEntry: CustomerAnalyticsEntry;
  aggName: string | null;
  column: AnalyticsPresetColumn;
  highestProfits: Record<string, Decimal>;
}> = ({ componentType, analyticsEntry, aggName, column, highestProfits }) => {
  const agg = aggName ? analyticsEntry.aggs[aggName] : null;

  if (componentType === "profit") {
    if (!agg || agg.type !== "sales") {
      return null;
    }

    return (
      <div css={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <span
          css={{
            textWrap: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            textAlign: "right",
            letterSpacing: "0.5px",
          }}
        >
          {agg.profit ? formatNumber(Number(agg.profit?.toFixed(2))) : 0} zł
        </span>
      </div>
    );
  }

  if (componentType === "potential") {
    if (!agg || agg.type !== "sales") {
      return null;
    }

    return (
      <div css={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <span css={{ textWrap: "nowrap", letterSpacing: "0.5px" }}>
          {agg.profit ? formatNumber(Number(agg.profit?.toFixed(2))) : 0} zł
        </span>
      </div>
    );
  }

  if (componentType === "potential-realization") {
    if (!agg || agg.type !== "sales") {
      return null;
    }

    const singleDotValue =
      highestProfits[aggName as string] && highestProfits[aggName as string].gt(30000)
        ? Number(highestProfits[aggName as string]?.div(30).toFixed(2))
        : 10000;

    return (
      <PotentialRealization
        potential={agg.absolutePotential}
        potentialRealization={agg.potentialRealization}
        actualRealization={agg.profit}
        singleDotValue={singleDotValue}
      />
    );
  }

  if (componentType === "groups-potential-realization") {
    if (!agg || agg.type !== "sales") {
      return null;
    }

    return <GroupPotentialRealization groupSummary={agg.groupSummary} />;
  }

  if (componentType === "interaction-count") {
    if (!agg || agg.type !== "interactions") {
      return null;
    }

    return (
      <div css={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <span>{agg.interactionCount}</span>
      </div>
    );
  }

  if (componentType === "region") {
    return <span css={{ textWrap: "nowrap" }}>{analyticsEntry.region}</span>;
  }

  if (componentType === "status") {
    return (
      <span css={{ textWrap: "nowrap" }}>
        {customerStatusList.find((status) => status.value === analyticsEntry.status)?.label}
      </span>
    );
  }

  if (componentType === "first-sale-time") {
    if (!agg || agg.type !== "sales") {
      return null;
    }

    return <span>{agg.firstSaleTime?.toLocaleDateString()}</span>;
  }

  if (componentType === "last-sale-time") {
    if (!agg || agg.type !== "sales") {
      return null;
    }

    return <span>{agg.lastSaleTime?.toLocaleDateString()}</span>;
  }

  if (componentType === "product-count") {
    if (!agg || agg.type !== "sales") {
      return null;
    }

    return (
      <div css={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <span>{agg.productCount}</span>
      </div>
    );
  }

  if (componentType === "order-count") {
    if (!agg || agg.type !== "sales") {
      return null;
    }

    return (
      <div css={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <span>{agg.orderCount}</span>
      </div>
    );
  }

  if (componentType === "first-interaction-time") {
    if (!agg || agg.type !== "interactions") {
      return null;
    }

    return <span>{agg.firstInteractionTime?.toLocaleDateString()}</span>;
  }

  if (componentType === "last-interaction-time") {
    if (!agg || agg.type !== "interactions") {
      return null;
    }

    return <span>{agg.lastInteractionTime?.toLocaleDateString()}</span>;
  }

  if (componentType === "sales-history") {
    if (!column.customColumnUuid) {
      return null;
    }

    const columnDetails = analyticsEntry.customColumns[column.customColumnUuid];

    if (!columnDetails || columnDetails.type !== "salesHistory") {
      return null;
    }

    return <SalesHistory salesHistory={columnDetails.profitHistory} />;
  }

  if (componentType === "profit-per-interaction") {
    if (!column.customColumnUuid) {
      return null;
    }

    const columnDetails = analyticsEntry.customColumns[column.customColumnUuid];

    if (!columnDetails || columnDetails.type !== "salesPerInteraction") {
      return null;
    }

    return (
      <div css={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <span css={{ textWrap: "nowrap", letterSpacing: "0.5px" }}>
          {formatNumber(Number(columnDetails.profit ? columnDetails.profit.toFixed(2) : 0))} zł
        </span>
      </div>
    );
  }

  if (componentType === "order-per-interaction") {
    if (!column.customColumnUuid) {
      return null;
    }

    const columnDetails = analyticsEntry.customColumns[column.customColumnUuid];

    if (!columnDetails || columnDetails.type !== "salesPerInteraction") {
      return null;
    }

    return (
      <div css={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <span css={{ textWrap: "nowrap" }}>{columnDetails.orderCount ? columnDetails.orderCount.toFixed(0) : 0}</span>
      </div>
    );
  }

  if (componentType === "potential-per-interaction") {
    if (!column.customColumnUuid) {
      return null;
    }

    const columnDetails = analyticsEntry.customColumns[column.customColumnUuid];

    if (!columnDetails || columnDetails.type !== "salesPerInteraction") {
      return null;
    }

    return (
      <div css={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <span css={{ textWrap: "nowrap", letterSpacing: "0.5px" }}>
          {formatNumber(Number(columnDetails.potential ? columnDetails.potential.toFixed(2) : 0))} zł
        </span>
      </div>
    );
  }

  return null;
};

export const formatNumber = (number: number) => {
  return number.toLocaleString("fr-FR", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};
