import { MyOrderDraft } from "@megarax/crm-contracts";

import { OrderDraftTile } from "./OrderDraftTile";

type Props = {
  orders: MyOrderDraft[];
};

export const OrderDraftsList: React.FC<Props> = ({ orders }) => {
  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem", width: "100%", marginBottom: "1rem" }}>
      {orders.map((order) => (
        <OrderDraftTile key={order.uuid} order={order} />
      ))}
    </div>
  );
};
